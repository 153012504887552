import React, { useEffect, useState } from "react";
import {
  List,
  Button,
  Card,
  Tag,
  Row,
  Col,
  message,
  Input,
  Modal,
  Form,
  Upload,
} from "antd";
import {
  AppstoreOutlined,
  BarsOutlined,
  DeleteOutlined,
  SearchOutlined,
  EditOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import MarketPlaceService from "../../../utils/api/marketplace";
import NewProduct from "../global_components/NewProduct";
import { useMediaQuery } from "react-responsive";
import '@ant-design/v5-patch-for-react-19';

const { Search } = Input;

const ProductList = (z) => {
  const user = JSON.parse(localStorage.getItem("user"));

  let history = useNavigate();
  const [gridColumns, setGridColumns] = useState(1);
  const [open, setOpen] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editingProduct, setEditingProduct] = useState(null);

  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState(products);
  const [loadProducts, setLoadProducts] = useState([]);
  const [vendor, setVendor] = useState({});

  const isSmallScreen = useMediaQuery({ query: "(max-width: 768px)" });

  useEffect(() => {
    setLoadProducts(true);
    MarketPlaceService.getVendorByUser(user.id).then((resp) => {
      setVendor(resp.data);
    });
    MarketPlaceService.getProductsBySupplier(user.id)
      .then((resp) => {
        setProducts(resp.data.products);
        setFilteredProducts(resp.data.products);
        setLoadProducts(false);
      })
      .catch((error) => {
        setProducts([]);
        setFilteredProducts([]);
        setLoadProducts(false);
      });
  }, []);

  const handleCardClick = (product) => {
    history(
      `/marketplace/${product.category}/${product.sub_category}/${
        product.id
      }?name=${encodeURIComponent(product.name)}`
    );
  };

  const handleNewProduct = () => {
    setOpen(true);
  };

  const handleSearch = (value) => {
    const filtered = products.filter((sup) =>
      sup.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredProducts(filtered);
  };

  const handleEdit = (product) => {
    setEditingProduct(product);
    setEditModalVisible(true);
  };

  //Add new product function
  const onSubmit = (values) => {
    console.log(values);

    const newProduct = {
      supplier_id: user.id,
      sku: values.sku,
      name: values.name,
      description: values.description,
      stock: values.stock,
      price: values.price,
      newPrice: values.newPrice,
      image1Base64: values.images[0]?.thumbUrl ?? null, // Verifica si existe el índice 0
      image2Base64: values.images[1]?.thumbUrl ?? null, // Verifica si existe el índice 1
      category: values.category,
      sub_category: values.sub_category,
    };

    console.log(newProduct);

    MarketPlaceService.newProduct(newProduct).catch((resp) => {
      console.log(resp);
    });
  };

  const handleUpdate = async (id, values) => {
    const updateProduct = {
      name: values.name,
      description: values.description,
      price: values.price,
      newPrice: values.price * 28,
      sku: values.sku || null,
      stock: values.stock,
      image1Base64:
        values.images?.fileList?.[0]?.thumbUrl ||
        editingProduct?.image1Base64 ||
        null,
      image2Base64:
        values.images?.fileList?.[1]?.thumbUrl ||
        editingProduct?.image2Base64 ||
        null,
    };

    // Mostrar loader
    message.loading({ content: "Actualizando producto...", key: "updating" });

    try {
      await MarketPlaceService.updateProduct(updateProduct, id);
      message.success({
        content: "Producto actualizado con éxito",
        key: "updating",
        duration: 2,
      });

      // Actualizar la lista de productos con la nueva información
      setProducts((prevProducts) =>
        prevProducts.map((product) =>
          product.id === id ? { ...product, ...updateProduct } : product
        )
      );

      setFilteredProducts((prevProducts) =>
        prevProducts.map((product) =>
          product.id === id ? { ...product, ...updateProduct } : product
        )
      );

      setEditModalVisible(false);
    } catch (error) {
      message.error({
        content: "Error al actualizar el producto. Inténtalo de nuevo.",
        key: "updating",
        duration: 2,
      });
    }
  };

  const handleCancelEdit = () => {
    setEditModalVisible(false);
  };

  return (
    <>
      <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
        <div style={{ flex: 3 }}>
          <Row>
            <Col xs={12} md={6}>
              <Search
                placeholder="Buscar por nombre..."
                enterButton={<SearchOutlined />}
                onSearch={handleSearch}
                style={{ marginBottom: "20px" }}
              />
            </Col>
            <Col
              xs={12}
              md={6}
              style={{ display: "flex", justifyContent: "flex-start" }}
            >
              <Button 
                onClick={() => setGridColumns(1)}
                icon={<BarsOutlined />}
                style={{ marginRight: "10px", marginLeft: "10px" }}
                className="responsive-button"
              >
                <span className="button-text">Lista</span>
              </Button>
              <Button
                onClick={() => setGridColumns(2)}
                icon={<AppstoreOutlined />}
                className="responsive-button"
              >
                <span className="button-text">Galería</span>
              </Button>
              <style jsx>{`
                @media (max-width: 768px) {
                  .responsive-button .button-text {
                    display: none;
                  }
                }
              `}</style>
            </Col>
            <Col
              xs={24}
              md={12}
              style={{ display: "flex", justifyContent: isSmallScreen ? 'center' : 'flex-end'}}
            >
              <Button type="primary" onClick={handleNewProduct}>
                + Agregar producto
              </Button>
            </Col>
          </Row>

          <List
            grid={{ gutter: 12, column: gridColumns }}
            dataSource={filteredProducts}
            loading={loadProducts}
            renderItem={(item) => (
              <List.Item
                style={{
                  width: "100%",
                  display: isSmallScreen ? "flex" : "block",
                  justifyContent: isSmallScreen ? "center" : "flex-start",
                }}
              >
                <Card
                  bordered={true}
                  style={{
                    display: "block",
                    width: "100%",
                    maxWidth: isSmallScreen ? "400px" : "100%",
                    margin: isSmallScreen ? "0 auto" : "0",
                    textAlign: isSmallScreen ? "center" : "left",
                    cursor: "pointer",
                    transition: "0.3s",
                  }}
                  onClick={() => handleCardClick(item)}
                >
                  <Row
                    gutter={[24, 24]}
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: isSmallScreen ? "column" : "row",
                      alignItems: isSmallScreen ? "center" : "flex-start",
                    }}
                  >
                    <Col xs={24} md={6} style={{ textAlign: isSmallScreen ? "center" : "left" }}>
                      <img
                        src={item.images[0]}
                        alt={item.name}
                        style={{ width: 100, height: 100 }}
                      />
                    </Col>
                    <Col
                      xs={24}
                      md={12}
                      style={{
                        textAlign: isSmallScreen ? "center" : "left",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: isSmallScreen ? "center" : "flex-start",
                      }}
                    >
                      <div>
                        <h3>{item.name}</h3>
                        <p>{item.description}</p>
                      </div>
                    </Col>
                    <Col xs={24} md={6} style={{ textAlign: isSmallScreen ? "center" : "right" }}>
                      <div>
                        <h2 style={{ marginBottom: 8 }}>${item.newPrice ?? item.price}</h2>
                        <p style={{ color: "green" }}>En stock: {item.stock}</p>
                        <Button
                          icon={<EditOutlined />}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(item);
                          }}
                        >
                          Editar
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </List.Item>
            )}
          />
        </div>
      </div>

      <Modal
        title="Editar Producto"
        visible={editModalVisible}
        onCancel={handleCancelEdit}
        footer={null}
      >
        <Form initialValues={editingProduct} onFinish={(values) => handleUpdate(editingProduct.id, values)} >
          <Form.Item name="name" label="Nombre" rules={[ { required: true, message: "Por favor ingresa el nombre del producto", },]}>
            <Input />
          </Form.Item>

          <Form.Item name="description" label="Descripción" rules={[ { required: true, message: "Por favor ingresa la descripción" },]}>
            <Input.TextArea />
          </Form.Item>

          <Form.Item name="price" label="Precio" rules={[{ required: true, message: "Por favor ingresa el precio" }]} >
            <Input type="number" />
          </Form.Item>

          <Form.Item name="sku" label="SKU" >
            <Input />
          </Form.Item>

          <Form.Item name="stock" label="Stock" rules={[{ required: true, message: "Por favor ingresa el stock" }]} >
            <Input type="number" />
          </Form.Item>

          <Form.Item name="images" label="Imágenes">
            <Upload
              listType="picture-card"
              maxCount={2}
              beforeUpload={() => false}
            >
              <Button icon={<UploadOutlined />}>Subir</Button>
            </Upload>
          </Form.Item>

          <Form.Item>
            <Button onClick={handleCancelEdit} style={{ marginRight: 8 }}>
              Cancelar
            </Button>
            <Button type="primary" htmlType="submit">
              Actualizar
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <NewProduct
        open={open}
        setOpen={setOpen}
        title={"Nuevo Producto"}
        vendor={vendor}
        onSubmit={onSubmit}
        content={
          <div>
            <Button key="back">Cancelar</Button>
            <Button key="submit" type="primary">
              Añadir
            </Button>
          </div>
        }
      />
    </>
  );
};

export default ProductList;
