import React, { useState } from "react";
import { Card, Button, Input, Rate, Collapse, message, Popconfirm } from "antd";
import { EditOutlined, DeleteOutlined, SendOutlined } from "@ant-design/icons";
import ServicesService from "../../utils/api/services";

const { Panel } = Collapse;

const ServiceReviews = ({ serviceId, userRole, reviews, setReviews, userData }) => {
  const [newRating, setNewRating] = useState(0);
  const [newComment, setNewComment] = useState("");
  const [editingReview, setEditingReview] = useState(null);
  const [editingRating, setEditingRating] = useState(0);
  const [editingComment, setEditingComment] = useState("");
  const [replyText, setReplyText] = useState({});
  const [loading, setLoading] = useState(false);

  // Agregar una nueva reseña
  const submitReview = async () => {
    if (!newRating || !newComment) {
      message.warning("Por favor, ingresa una calificación y un comentario.");
      return;
    }

    setLoading(true);
    const body = {
      clientId: userData.id,
      rating: newRating,
      comment: newComment,
    };

    try {
      const response = await ServicesService.NewReview(serviceId, body);
      if (response.data.success) {
        message.success("Reseña agregada con éxito.");
        setReviews((prev) => ({
          ...prev,
          [serviceId]: [...(prev[serviceId] || []), response.data.review],
        }));
        setNewRating(0);
        setNewComment("");
      } else {
        message.error(response.data.error || "Error al agregar la reseña.");
      }
    } catch (error) {
      console.error("Error al agregar reseña:", error);
      message.error("Error al agregar la reseña.");
    }

    setLoading(false);
  };

  // Editar una reseña (modifica comentario y puntuación)
  const editReview = async (reviewId) => {
    if (!editingRating || !editingComment) {
      message.warning("Por favor, ingresa una calificación y un comentario.");
      return;
    }

    setLoading(true);
    const body = { rating: editingRating, comment: editingComment };

    try {
      const response = await ServicesService.EditReview(reviewId, body);
      if (response.data.success) {
        message.success("Reseña actualizada.");
        setReviews((prev) => ({
          ...prev,
          [serviceId]: prev[serviceId].map((review) =>
            review.id === reviewId
              ? { ...review, rating: editingRating, comment: editingComment }
              : review
          ),
        }));
        setEditingReview(null);
      } else {
        message.error("Error al actualizar la reseña.");
      }
    } catch (error) {
      console.error("Error al editar la reseña:", error);
      message.error("Error al editar la reseña.");
    }

    setLoading(false);
  };

  // Eliminar una reseña
  const deleteReview = async (reviewId) => {
    setLoading(true);

    try {
      const response = await ServicesService.DeleteReview(reviewId);
      if (response.data.success) {
        message.success("Reseña eliminada.");
        setReviews((prev) => ({
          ...prev,
          [serviceId]: prev[serviceId].filter((review) => review.id !== reviewId),
        }));
      } else {
        message.error("Error al eliminar la reseña.");
      }
    } catch (error) {
      console.error("Error al eliminar la reseña:", error);
      message.error("Error al eliminar la reseña.");
    }

    setLoading(false);
  };

  // Responder a una reseña (solo roles 1, 3 y 5)
  const replyToReview = async (reviewId) => {
    if (!replyText[reviewId]) {
      message.warning("Por favor, ingresa una respuesta.");
      return;
    }

    setLoading(true);
    const body = {
      userId: userData.id,
      reply: replyText[reviewId],
    };

    try {
      const response = await ServicesService.ReplyReview(reviewId, body);
      if (response.data.success) {
        message.success("Respuesta agregada.");
        setReviews((prev) => ({
          ...prev,
          [serviceId]: prev[serviceId].map((review) =>
            review.id === reviewId
              ? { ...review, replies: [...(review.replies || []), response.data.reply] }
              : review
          ),
        }));
        setReplyText({ ...replyText, [reviewId]: "" });
      } else {
        message.error("Error al responder la reseña.");
      }
    } catch (error) {
      console.error("Error al responder:", error);
      message.error("Error al responder la reseña.");
    }

    setLoading(false);
  };

  const userReview = reviews[serviceId]?.find((r) => r.clientId === userData.id);

  return (
    <Card title="Reseñas del Servicio">
      {userRole === 4 ? (
        userReview ? (
          <Collapse>
            <Panel
              header={`${userReview.rating} ⭐ - ${userReview.comment}`}
              key="userReview"
              extra={
                <>
                  <EditOutlined
                    onClick={() => {
                      setEditingReview(userReview.id);
                      setEditingRating(userReview.rating);
                      setEditingComment(userReview.comment);
                    }}
                    style={{ marginRight: 10 }}
                  />
                  <Popconfirm
                    title="¿Eliminar esta reseña?"
                    onConfirm={() => deleteReview(userReview.id)}
                    okText="Sí"
                    cancelText="No"
                  >
                    <DeleteOutlined />
                  </Popconfirm>
                </>
              }
            >
              {editingReview === userReview.id ? (
                <>
                  <Rate allowHalf value={editingRating} onChange={setEditingRating} />
                  <Input.TextArea
                    rows={3}
                    value={editingComment}
                    onChange={(e) => setEditingComment(e.target.value)}
                  />
                  <Button type="primary" onClick={() => editReview(userReview.id)} loading={loading}>
                    Guardar
                  </Button>
                </>
              ) : (
                <>
                  {userReview.replies && userReview.replies.length > 0 && (
                    <Collapse>
                      {userReview.replies.map((reply, index) => (
                        <Panel key={index} header={`Respuesta: ${reply.reply}`}>
                          <p>Por: {reply.userName}</p>
                        </Panel>
                      ))}
                    </Collapse>
                  )}
                </>
              )}
            </Panel>
          </Collapse>
        ) : (
          <div>
            <Rate allowHalf value={newRating} onChange={setNewRating} />
            <Input.TextArea
              rows={3}
              placeholder="Escribe tu comentario aquí..."
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
            />
            <Button type="primary" onClick={submitReview} loading={loading} disabled={!newRating || !newComment}>
              Enviar Reseña
            </Button>
          </div>
        )
      ) : reviews[serviceId]?.length > 0 ? (
        <Collapse>
          {reviews[serviceId].map((review, index) => (
            <Panel key={index} header={`${review.rating} ⭐ - ${review.comment}`}>
              {review.replies && review.replies.length > 0 && (
                <Collapse>
                  {review.replies.map((reply, idx) => (
                    <Panel key={idx} header={`Respuesta: ${reply.reply}`}>
                      <p>Por: {reply.userName}</p>
                    </Panel>
                  ))}
                </Collapse>
              )}
              {[1, 3, 5].includes(userRole) && (
                <div>
                  <Input.TextArea
                    rows={2}
                    placeholder="Escribe una respuesta..."
                    value={replyText[review.id] || ""}
                    onChange={(e) => setReplyText({ ...replyText, [review.id]: e.target.value })}
                  />
                  <Button type="primary" icon={<SendOutlined />} onClick={() => replyToReview(review.id)} loading={loading}>
                    Responder
                  </Button>
                </div>
              )}
            </Panel>
          ))}
        </Collapse>
      ) : (
        <p>No hay reseñas aún.</p>
      )}
    </Card>
  );
};

export default ServiceReviews;
