import React from "react";
import { Layout, Typography, Button } from "antd";
import "antd/dist/reset.css";
import { useNavigate } from 'react-router-dom';

const { Content } = Layout;
const { Title, Text } = Typography;

export default function Error404() {

const navigate = useNavigate();

const navigateBack = () => {
    navigate(-1);
}
return (
    <Layout style={{ height: "100vh", display: "flex", justifyContent: "center", alignItems: "center", background: "#fff" }}>
      <Content style={{ textAlign: "center" }}>
        <Title level={1} style={{ fontSize: "3em", fontWeight: 300 }}>
          LO SENTIMOS
        </Title>
        <Text style={{ fontSize: "1.2em", color: "#595959" }}>
          no pudimos encontrar la página que buscabas
        </Text>
        <br />
        <Text style={{ fontSize: "1em", color: "#8c8c8c" }}>
          Trata de usar la barra de búsqueda o vuelve
          <a onClick={navigateBack} style={{ marginLeft: "5px", color: "#1890ff" }}>
            a la página principal.
          </a>
        </Text>
        <div style={{ marginTop: "30px" }}>
          <img
            src="../images/logo.png"
            alt="Hunter"
            style={{ width: "200px", borderRadius: "10px" }}
          />
          <Text style={{ display: "block", marginTop: "10px", color: "#595959" }}>
            EP&O
            <br />
            Nos aseguramos de brindarte el mejor servicio
          </Text>
        </div>
      </Content>
    </Layout>
)
}

