import React, { useState } from 'react';
import { Form, Input, Button, Col, Row,Typography, message } from 'antd';
import { UserOutlined, LockOutlined} from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import '../scss/login.scss'
import AuthService from '../utils/api/auth';
import '@ant-design/v5-patch-for-react-19';

export default function Login() {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const [loading, setLoading] = useState(false)
  let history = useNavigate();
  const [error, setError] = useState({
    status: false,
    msg: ''
  });

  const [inactiveAccount, setInactiveAccount] = useState(false)

  const msgError = () => {
    messageApi.open({
      type: 'error',
      content: 'Correo o contraseña incorrecta',
    });
  };

  const msgWarning = () => {
    messageApi.open({
      type: 'warning',
      content: 'Nos encontramos verificando tu cuenta',
    });
  };


  const onFinish = (values) => {
    setLoading(true);
    AuthService.LoginEpno(values)
      .then(response => {
        if (response.data.status === false) {
          setInactiveAccount(true)
          msgWarning()
          setLoading(false);
        } else if (response.status === 200) {
          
          localStorage.setItem('role', response.data.role); 
          const role = parseInt(response.data.role)
          localStorage.setItem('user', JSON.stringify(response.data));

          setLoading(false);

          if ((role === 1)) { // Admin
            history('/dashboard')
          } else if (role === 3) { // Compras
            history(`/orders/all/1`)
          } else if (role === 4) { // Industria (cliente)
            history(`/@p/${response.data.id}`)
          } else if (role === 5) { // Ventas
            history(`/orders/all/1`)
          } else if (role === 6) { // Proveedores
            history(`/@p/${response.data.id}`)
          } else if (role === 9) {
            history(`/dashboard`)
          }

        } else {
          setLoading(false);
        }
      })
      .catch(error => {
        setError({
          status: true,
          msg: 'Usuario y/o contreseña incorrecta'
        })
        msgError()
        setLoading(false);
      })
  }


  return (
    <Row className="login-container">
       {contextHolder}
      {/* Contenedor del formulario */}
      <Col sm={12} className="login-box" style={{display:'flex', alignItems: 'center', justifyContent: 'center'}}>
        <div style={{width: '70%', textAlign: 'start'}}>
          <Typography.Title level={3} style={{ color: 'white' }}>¡Bienvenido a EPNO!</Typography.Title>
          <p className="login-subtext"> Ingresa tu correo y contraseña para continuar. </p>

          <Form layout="vertical" onFinish={onFinish}>
            <Form.Item name="email" rules={[{ required: true, message: 'Por favor ingresa tu correo electronico' }]} >
              <Input prefix={<UserOutlined />} placeholder="Correo electrónico" />
            </Form.Item>

            <Form.Item name="password" rules={[{ required: true, message: 'Por favor ingresa tu contraseña' }]} >
              <Input.Password prefix={<LockOutlined />} placeholder="Contraseña" />
            </Form.Item>

            <Form.Item style={{marginTop: '64px'}}>
              <Button type="primary" htmlType="submit" loading={loading} block> Inicio de sesión </Button>
            </Form.Item>
          </Form>

          <p className="register-link"> ¿Aun no estas registrado? <Link to="/register">Unete a nosostros!</Link> </p>
        </div>
      </Col>

      <Col sm={12} style={{color: 'white', backgroundImage: `url('/img/lg-bg.jpg')`, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
        <div style={{width: '70%'}}>
          <h1 style={{color: 'white', fontSize: '52px'}}>¿Conoces la nueva forma de hacer negocios industriales?</h1>
          <h3>La plataforma de EP&O conecta a proveedores y empresas de la industria, creando un espacio digital para hacer negocios más ágiles, confiables y estratégicos.</h3>
          <ul style={{marginTop: '32px'}}>
            <li>Conexión directa y ágil con proveedores y empresas del sector industrial.</li>
            <li>Visibilidad y oportunidades para ampliar tu red de negocios.</li>
            <li>Gestión centralizada de cotizaciones, compras y servicios, todo en un solo lugar.</li>
          </ul>
        </div>
        <div style={{height: '64px', width: '1'}}></div>
      </Col>

      <img src='/img/epo_blanco.png' width={156} height={48} style={{position: 'absolute', left: '32px', top: '32px'}}/> 
    </Row>
  )
}

