import React, { useState, useEffect } from "react";
import { Row, Col, Input, Table, Button, Tag } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Link, useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import ServiceStatus from "../../ServiceStatus";

export default function AgentTable(props) {
	const { openOrders, loading } = props;
	const history = useNavigate();
	const role = localStorage.getItem("role");

	const location = useLocation();
	const isInMarketplace = location.pathname === "/marketplace/orders";

	const [tableData, setTableData] = useState([]);
	const [searchedValue, setSearchedValue] = useState("");

	useEffect(() => {
		const formattedData = openOrders.map((order, index) => {
			// Obtener la última actualización de los servicios asociados a la orden
			const latestServiceUpdate = order.services
				.map(service => moment(service.updated_at).valueOf())
				.sort((a, b) => b - a)[0]; // Obtener la última fecha de actualización

			return {
				key: index,
				order_id: order.orderId.toString(),
				order_title: order.title.toLowerCase(),
				client_name: order.client_name.toLowerCase(),
				updated_at: latestServiceUpdate, // Última actualización de los servicios
				created_at: moment(order.created_at).valueOf(),
				days_of_age: moment(order.created_at).fromNow(),
				services: order.services || [],
			};
		});

		// Ordenar las órdenes basándonos en la última actualización de los servicios (de más reciente a menos reciente)
		const sortedData = formattedData.sort((a, b) => b.updated_at - a.updated_at);

		setTableData(sortedData);
	}, [openOrders]);

	const handleSearch = (searchedText) => {
		const filteredData = openOrders
			.filter((order) =>
				order.orderId.toString().includes(searchedText) ||
				order.title.toLowerCase().includes(searchedText.toLowerCase()) ||
				order.client_name.toLowerCase().includes(searchedText.toLowerCase())
			)
			.map((order, index) => {
				// Obtener la última actualización de los servicios asociados a la orden
				const latestServiceUpdate = order.services
					.map(service => moment(service.updated_at).valueOf())
					.sort((a, b) => b - a)[0]; // Obtener la última fecha de actualización

				return {
					key: index,
					order_id: order.orderId.toString(),
					order_title: order.title.toLowerCase(),
					client_name: order.client_name.toLowerCase(),
					updated_at: latestServiceUpdate, // Última actualización de los servicios
					created_at: moment(order.created_at).valueOf(),
					days_of_age: moment(order.created_at).fromNow(),
					services: order.services || [],
				};
			});

		// Ordenar las órdenes filtradas
		const sortedData = filteredData.sort((a, b) => b.updated_at - a.updated_at);

		setTableData(sortedData);
	};

	const handleReset = () => {
		setSearchedValue("");
		const formattedData = openOrders.map((order, index) => {
			// Obtener la última actualización de los servicios asociados a la orden
			const latestServiceUpdate = order.services
				.map(service => moment(service.updated_at).valueOf())
				.sort((a, b) => b - a)[0]; // Obtener la última fecha de actualización

			return {
				key: index,
				order_id: order.orderId.toString(),
				order_title: order.title.toLowerCase(),
				client_name: order.client_name.toLowerCase(),
				updated_at: latestServiceUpdate, // Última actualización de los servicios
				created_at: moment(order.created_at).valueOf(),
				days_of_age: moment(order.created_at).fromNow(),
				services: order.services || [],
			};
		});

		// Ordenar las órdenes
		const sortedData = formattedData.sort((a, b) => b.updated_at - a.updated_at);

		setTableData(sortedData);
	};

	const handleNav = (route) => {
		history(route);
	};

	const columns = [
		{
			title: "# Orden",
			dataIndex: "order_id",
			key: "order_id",
			align: "center",
			sorter: (a, b) => a.order_id.localeCompare(b.order_id),
			render: (text, record) => (
				record.order_id ? (
					<Link to={`/orders/details/new/${record.order_id}`}>{text}</Link>
				) : (
					<Tag color="volcano">unavailable</Tag>
				)
			),
		},
		{
			title: "Título",
			dataIndex: "order_title",
			key: "order_title",
			sorter: (a, b) => a.order_title.localeCompare(b.order_title),
			render: (text) => text.toUpperCase(),
		},
		{
			title: "Cliente",
			dataIndex: "client_name",
			key: "client_name",
			align: "center",
			sorter: (a, b) => a.client_name.localeCompare(b.client_name),
			render: (text) => text.toUpperCase(),
		},
		{
			title: "Última Actualización",
			dataIndex: "updated_at",
			key: "updated_at",
			align: "center",
			render: (text) => moment(text).fromNow(),
			sorter: (a, b) => a.updated_at - b.updated_at,
		},
		{
			title: "Antigüedad",
			dataIndex: "days_of_age",
			key: "days_of_age",
			align: "center",
			sorter: (a, b) => a.created_at - b.created_at,
			render: (text) => text.charAt(0).toUpperCase() + text.slice(1),
		},
	];

	const expandedRowRender = (record) => {
		const serviceColumns = [
			{ title: "# Servicio", dataIndex: "serviceId", key: "serviceId" },
			{ title: "Título", dataIndex: "title", key: "title" },
			{ title: "Descripción", dataIndex: "description", key: "description" },
			{
				title: "Última Actualización", // Cambié de 'Status' a 'Última Actualización'
				dataIndex: "updated_at",
				key: "updated_at",
				render: (text) => moment(text).fromNow(), // Muestra el tiempo transcurrido para cada servicio
			},
			{ title: "Status", dataIndex: "status", key: "status", render: (s) => <ServiceStatus status={s} /> },
		];

		return (
			<Table
				columns={serviceColumns}
				dataSource={record.services.map((service, idx) => ({ key: idx, ...service }))}
				pagination={false}
				size="small"
			/>
		);
	};

	return (
		<>
			<Row gutter={[12, 12]} style={{ marginBottom: "16px" }}>
				<Col xs={20}>
					<Input.Search
						placeholder="Buscar por número de orden, título o cliente"
						allowClear
						enterButton="Buscar"
						size="large"
						onSearch={handleSearch}
						onChange={(e) => setSearchedValue(e.target.value)}
						value={searchedValue}
					/>
					{searchedValue && (
						<Tag color="blue" onClick={handleReset} style={{ cursor: "pointer", marginTop: "8px" }}>
							Resetear búsqueda
						</Tag>
					)}
				</Col>

				{role === "4" && !isInMarketplace && (
					<Col xs={4}>
						<Button
							type="primary"
							onClick={() => handleNav("/orders/request")}
							icon={<PlusOutlined />}
							style={{ width: "100%", height: "100%" }}
						>
							Nueva orden
						</Button>
					</Col>
				)}
			</Row>

			<Row gutter={[12, 12]}>
				<Col xs={24}>
					<Table
						columns={columns}
						dataSource={tableData}
						scroll={{ x: "100vh" }}
						loading={loading}
						pagination={{ pageSize: 10 }}
						expandable={{ expandedRowRender }}
					/>
				</Col>
			</Row>
		</>
	);
}
