import { Avatar, Card, Col, Divider, List, Modal, Row, Spin } from "antd";
import React, { useState, useEffect, useRef } from "react";
import Chat from "../../Chat";
import ChatService from "../../../utils/api/chat";
import '@ant-design/v5-patch-for-react-19';
import { VSCategoriesData } from "../../../data/vs";

export default function OrderUsers(props) {
  const { data, op, chats, service } = props;
  
  const [visible, setVisible] = useState(false);
  const [user, setUser] = useState({
    id: "",
    name: "",
    email: "",
  });
  const goBottom = useRef(null);
  const [reloadChat, setReloadChat] = useState(false);
  const [messages, setMessages] = useState([]);
  const [load, setLoad] = useState(false);
  const [chatId, setChatId] = useState('')

	const role = localStorage.getItem('role')

  const showMessages = async (chatId, type, userName, userId, serviceId ) => {
    setChatId(chatId);
    setUser({
      id: userId,
      name: userName,
      type: type,
      serviceId: serviceId,
      chatId: chatId
    });
    setVisible(true);
    setLoad(true);
  
    try {
      const resp = await ChatService.GetMessagesChat(chatId);
      let fetchedMessages = resp.data.messages || [];
  
      // 🔃 Ordena por createdAt como string con precisión de segundos
      fetchedMessages.sort((a, b) => a.createdAt.localeCompare(b.createdAt));
  
      setMessages(fetchedMessages);
      console.log("Mensajes ordenados:", fetchedMessages.map(m => m.createdAt));
  
    } catch (error) {
      console.error("Error al obtener mensajes:", error);
    } finally {
      setLoad(false);
    }
  };
  

  const getChatTitle = (chat) => {
    const currentRole = parseInt(role);
  
    if (chat.type === 1) {
      // Cliente - Ventas
      const categoryKey = chat.serviceId?.split("-")[0];  // Extraer el key de la categoría
      const category = VSCategoriesData.find(item => item.key === parseInt(categoryKey))?.name || "Categoría";
      return currentRole === 4
        ? `Tú y Ventas - ${category}`  // Incluye la categoría para el cliente
        : `Cliente y Ventas - ${category}`;  // Incluye la categoría para ventas
    }
  
    if (chat.type === 2) {
      // Compras - Ventas por categoría
      const categoryKey = chat.serviceId?.split("-")[0];  // Extraer el key de la categoría
      const category = VSCategoriesData.find(item => item.key === parseInt(categoryKey))?.name || "Categoría";
      return `Ventas y Compras - ${category}`;
    }
  
    if (chat.type === 3) {
      // Proveedor - Ventas/Compras
      const supplier = chat.participants?.[0] || "Proveedor";
      return `Proveedor y Compras/Ventas`;
    }
  
    return "Chat Desconocido";
};
  
  const getChatDescription = (chat) => {
    if (chat.type === 1) return "Comunicación Cliente - Ventas";
    if (chat.type === 2) return "Comunicación Ventas - Compras por categoría";
    if (chat.type === 3) return "Comunicación Proveedor - Ventas/Compras";
    return "Tipo desconocido";
  };
  
  const getChatUserId = (chat) => {
    if (chat.type === 1 || chat.type === 3) {
      return chat.participants?.[0] || null;
    }
    return null; // Para tipo 2 (ventas/compras) no se vincula userId
  };

  const callApi = async (chatId) => {
    try {
      const resp = await ChatService.GetMessagesChat(chatId);
      let fetchedMessages = resp.data.messages || [];

      // Ordena por fecha como string con precisión
      fetchedMessages.sort((a, b) => a.createdAt.localeCompare(b.createdAt));
      setMessages(fetchedMessages);

      // 🔽 Auto scroll al final
      setTimeout(() => {
        goBottom.current?.focus();
      }, 200);

    } catch (error) {
      console.error("Error al refrescar mensajes:", error);
    }
  };

  return (
    <Card title="Chats" style={{ height: 260, overflow: "auto", }} >
      {/* TODO: validar usuarios. Si agente; todos. Si cliente; agente. Si proveedor; agente */}
      <List
        dataSource={chats}
        renderItem={(chat) => (
          <List.Item key={chat.id}>
            <List.Item.Meta
              title={
                <a onClick={() => showMessages(chat.id, chat.type, getChatTitle(chat), getChatUserId(chat), chat.serviceId)}>
                  {getChatTitle(chat)}
                </a>
              }
              description={getChatDescription(chat)}
            />
          </List.Item>
        )}
      />

      <Modal
        open={visible}
        footer=""
        closable
        width={600}
        style={{
          top: 30,
        }}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
      >
        <Row gutter={[12, 12]} justify="center">
          {load ? (
            <Col style={{ marginTop: 30, marginButton: 30 }}>
              <Spin size="large" tip="Cargando mensajes" />
            </Col>
          ) : (
            <Col xs={24}>
              <Chat
								chat = {chats}
                data={data}
                service= {service}
                conversation={messages}
                user={user}
                reloadChat={reloadChat}
                setReloadChat={setReloadChat}
                goBottom={goBottom}
                op={op}
                callApi={callApi}
              />
            </Col>
          )}
        </Row>
      </Modal>
    </Card>
  );
}
