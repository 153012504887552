import React, { useState } from "react";
import { 
  Card, Button, Input, Collapse, message, Upload, Popconfirm, Typography, Divider, Row, Col 
} from "antd";
import { 
  EditOutlined, DeleteOutlined, SendOutlined, UploadOutlined, CheckCircleOutlined, FileTextOutlined
} from "@ant-design/icons";
import ServicesService from "../../utils/api/services";

const { Panel } = Collapse;
const { Title, Text, Link } = Typography;

const ServiceComplaints = ({ serviceId, userRole, complaints, setComplaints, userData, status }) => {
  const [showComplaintForm, setShowComplaintForm] = useState(false);
  const [complaintTitle, setComplaintTitle] = useState("");
  const [complaintDescription, setComplaintDescription] = useState("");
  const [complaintFile, setComplaintFile] = useState(null);
  const [loadingComplaint, setLoadingComplaint] = useState(false);
  const [replyText, setReplyText] = useState({});

  // Función para convertir archivo a base64
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  // Función para levantar una queja
  const handleRaiseComplaint = async () => {
    if (!complaintTitle || !complaintDescription) {
      message.warning("Por favor, completa el título y la descripción.");
      return;
    }

    setLoadingComplaint(true);
    let fileBase64 = null;

    if (complaintFile) {
      try {
        fileBase64 = await getBase64(complaintFile);
      } catch (error) {
        message.error("Error al procesar el archivo.");
        setLoadingComplaint(false);
        return;
      }
    }

    const formData = {
      serviceId,
      clientId: userData.id,
      title: complaintTitle,
      description: complaintDescription,
      file: fileBase64, // Archivo en base64
    };

    try {
      const response = await ServicesService.NewComplaint(formData);
      if (response.data.success) {
        message.success("Queja levantada con éxito.");
        setComplaints((prev) => ({
          ...prev,
          [serviceId]: [...(prev[serviceId] || []), response.data.complaint],
        }));
        setShowComplaintForm(false);
        setComplaintTitle("");
        setComplaintDescription("");
        setComplaintFile(null);
      } else {
        message.error("Error al levantar la queja.");
      }
    } catch (error) {
      console.error("Error al levantar la queja:", error);
      message.error("Hubo un error al levantar la queja.");
    }
    setLoadingComplaint(false);
  };

  // Función para responder una queja
  const handleReplyComplaint = async (complaintId) => {
    if (!replyText[complaintId]) {
      message.warning("Por favor, ingresa una respuesta.");
      return;
    }

    setLoadingComplaint(true);
    const replyData = {
      userId: userData.id,
      reply: replyText[complaintId],
    };

    try {
      const response = await ServicesService.ReplyComplaint(complaintId, replyData);
      if (response.data.success) {
        message.success("Respuesta agregada.");
        window.location.reload()
        /*setComplaints((prev) => ({
          ...prev,
          [serviceId]: prev[serviceId].map((complaint) =>
            complaint.id === complaintId
              ? { ...complaint, replies: [...(complaint.replies || []), response.data.reply] }
              : complaint
          ),
        }));*/
        //setReplyText({ ...replyText, [complaintId]: "" });
      } else {
        message.error("Error al responder la queja.");
      }
    } catch (error) {
      console.error("Error al responder la queja:", error);
      message.error("Hubo un error al responder la queja.");
    }
    setLoadingComplaint(false);
  };

  // Función para marcar la queja como resuelta
  const handleResolveComplaint = async (complaintId) => {
    setLoadingComplaint(true);
    try {
      const response = await ServicesService.SolveComplaint(complaintId);
      if (response.data.success) {
        message.success("Queja resuelta correctamente.");
        window.location.reload()
        /*setComplaints((prev) => ({
          ...prev,
          [serviceId]: prev[serviceId].filter((complaint) => complaint.id !== complaintId),
        }));*/
      } else {
        message.error("Error al resolver la queja.");
      }
    } catch (error) {
      console.error("Error al resolver la queja:", error);
      message.error("Hubo un error al resolver la queja.");
    }
    setLoadingComplaint(false);
  };

  return (
    <Card title="Quejas del Servicio" bordered={false}>
      {/** Botón para levantar una queja */}
      {userRole === 4 && status === 7 && (
        <Button type="primary" danger onClick={() => setShowComplaintForm(!showComplaintForm)}>
          Levantar Queja
        </Button>
      )}

      {/** Formulario para levantar una queja */}
      {showComplaintForm && (
        <Card title="Nueva Queja" bordered={false} style={{ marginTop: 10 }}>
          <Input placeholder="Título" value={complaintTitle} onChange={(e) => setComplaintTitle(e.target.value)} />
          <Input.TextArea
            rows={3}
            placeholder="Descripción de la queja..."
            value={complaintDescription}
            onChange={(e) => setComplaintDescription(e.target.value)}
          />
          <Upload
            beforeUpload={(file) => {
              setComplaintFile(file);
              return false;
            }}
          >
            <Button icon={<UploadOutlined />}>Subir archivo</Button>
          </Upload>
          <Button type="primary" onClick={handleRaiseComplaint} loading={loadingComplaint} style={{ marginTop: 10 }}>
            Enviar Queja
          </Button>
        </Card>
      )}

      {/** Listado de quejas activas */}
      {complaints[serviceId]?.length > 0 ? (
        <Collapse style={{ marginTop: 15 }}>
          {complaints[serviceId].map((complaint, index) => (
            <Panel key={index} header={`${complaint.title}`}>
              <Text>{complaint.description}</Text>
              <Divider />
              {complaint.fileUrl && (
                <p>
                  <Link href={complaint.fileUrl} target="_blank" rel="noopener noreferrer">
                    <FileTextOutlined /> Ver Archivo Adjunto
                  </Link>
                </p>
              )}

              {complaint.replies && complaint.replies.length > 0 && (
                <Collapse>
                  {complaint.replies.map((reply, idx) => (
                    <Panel key={idx} header={`Respuesta de ${reply.userName}`}>
                      <Text>{reply.reply}</Text>
                    </Panel>
                  ))}
                </Collapse>
              )}

              {[1, 3, 4, 5].includes(userRole) && (
                <>
                  <Input.TextArea
                    rows={2}
                    placeholder="Escribe una respuesta..."
                    value={replyText[complaint.id] || ""}
                    onChange={(e) => setReplyText({ ...replyText, [complaint.id]: e.target.value })}
                  />
                  <Button type="primary" icon={<SendOutlined />} onClick={() => handleReplyComplaint(complaint.id)} loading={loadingComplaint}>
                    Responder
                  </Button>
                </>
              )}

              {userRole === 4 && (
                <Popconfirm title="¿Marcar esta queja como resuelta?" onConfirm={() => handleResolveComplaint(complaint.id)} okText="Sí" cancelText="No">
                  <Button type="primary" icon={<CheckCircleOutlined />} loading={loadingComplaint} style={{ marginTop: 10 }}>
                    Resolver Queja
                  </Button>
                </Popconfirm>
              )}
            </Panel>
          ))}
        </Collapse>
      ) : (
        <Text type="secondary">No hay quejas activas.</Text>
      )}
    </Card>
  );
};

export default ServiceComplaints;
