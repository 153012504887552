import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Avatar, Table } from 'antd';
import { BarChartOutlined, FileDoneOutlined, MoneyCollectOutlined, PieChartFilled, ProjectOutlined, UserOutlined } from '@ant-design/icons';
import Layout from "../layouts/ControlPanelLayout";
import SummaryOrders from "../components/Agent/SummaryOrders";
import OrderService from '../utils/api/orders';
import UserService from '../utils/api/users';
import AnaliticsService from '../utils/api/analitics';
import { BarChart } from '@mui/x-charts/BarChart';
import { PieChart } from '@mui/x-charts';
import Meta from 'antd/es/card/Meta';
import ServiceStatus from '../components/Generals/ServiceStatus';

export default function Dashboard() {
  const [analyticsData, setAnalyticsData] = useState({
    openOrders: [],
    totalUsers: 0,
    bySales: [],
    byRevenue: [],
    byCategory: [],
    perMonth: [],
    topProjects: [],
    ordersByStatus: [],
  });

  const role = localStorage.getItem('role');

  useEffect(() => {
    if (role === '1') {
      OrderService.All()
        .then(response => setAnalyticsData(prev => ({ ...prev, openOrders: response.data.orders })))
        .catch(() => {});
    }

    AnaliticsService.Sales()
      .then(resp => setAnalyticsData(prev => ({
        ...prev,
        byCategory: resp.data.sales_by_category,
        bySales: resp.data.top_customers_by_sales,
        byRevenue: resp.data.top_customers_by_revenue
      })))
      .catch(() => {});

    AnaliticsService.SalesPerMonth()
      .then(resp => setAnalyticsData(prev => ({ ...prev, perMonth: resp.data.sales_by_month })))
      .catch(() => {});

    AnaliticsService.ProjectsSales()
      .then(resp => setAnalyticsData(prev => ({
        ...prev,
        topProjects: resp.data.top_services_by_revenue,
        ordersByStatus: resp.data.services_by_status
      })))
      .catch(() => {});

    UserService.GetAll()
      .then(response => setAnalyticsData(prev => ({ ...prev, totalUsers: response.data.length })))
      .catch(() => {});
  }, [role]);

  // Columnas de las tablas
  const columnsConfig = {
    sales: [
      { title: "Cliente", dataIndex: "name", key: "name" },
      { title: "Órdenes Finalizadas", dataIndex: "count", key: "count" },
    ],
    revenue: [
      { title: "Cliente", dataIndex: "name", key: "name" },
      { title: "Ingresos Totales", dataIndex: "revenue", key: "revenue" },
    ],
    projects: [
      { title: "Proyecto", dataIndex: "name", key: "name" },
      { title: "Ingresos Totales", dataIndex: "revenue", key: "revenue" },
    ],
    status: [
      { 
        title: "Estatus", 
        dataIndex: "status", 
        key: "status",
        render: (status) => <ServiceStatus status={status} />
      },
      { title: "Cantidad de Órdenes", dataIndex: "count", key: "count" },
    ]
  };

  const renderCard = (title, description, icon, data, columns) => (
    <Col md={12} xs={24} lg={12}>
      <Card style={{ marginTop: 16, boxShadow: "0px 2px 8px rgba(0,0,0,0.12)" }}>
        <Meta 
          avatar={<Avatar style={{ boxShadow: "2px 2px 5px rgba(0,0,0,0.75)", backgroundColor: '#5f5fff' }} icon={icon} size={60} xs={0} />}
          title={title}
          description={description}
        />
        <Table columns={columns} dataSource={data.slice(0, 5)} pagination={false} style={{ marginTop: 16 }} />
      </Card>
    </Col>
  );

  return (
    <Layout>
      <Row gutter={24}>

        {/* Tarjetas de resumen */}
        <Col md={12} xs={24} lg={12}>
          <SummaryOrders 
            title={`Total: ${analyticsData.openOrders.length}`} 
            text="Ordenes en transito" 
            description="Revisa las ordenes actuales." 
            avatarColor={{ backgroundColor: 'rgb(175 223 155)' }} 
            icon={<FileDoneOutlined />} 
          />
        </Col>

        <Col md={12} xs={24} lg={12}>
          <SummaryOrders 
            title={`Total: ${analyticsData.totalUsers}`} 
            text="Total de usuarios" 
            description="Revisa los usuarios de tu app." 
            avatarColor={{ backgroundColor: '#a9abd9' }} 
            icon={<UserOutlined />} 
          />
        </Col>

        {/* Gráfica de Ventas Mensuales */}
        <Col md={12} xs={24} lg={12}>
          <Card style={{ marginTop: 16, boxShadow: "0px 2px 8px rgba(0,0,0,0.12)" }}>
            <Meta 
              avatar={<Avatar style={{ boxShadow: "2px 2px 5px rgba(0,0,0,0.75)", backgroundColor: '#ff5f5f' }} icon={<BarChartOutlined />} size={60} xs={0} />}
              title={'Ventas mensuales por categorías'}
              description={'Ganancias totales de órdenes finalizadas mensuales por categoría'} 
            />
            <div style={{ width: "100%", height: "350px" }}>
              <BarChart
                dataset={analyticsData.perMonth}
                xAxis={[{ scaleType: 'band', dataKey: 'month' }]}
                series={[
                  { dataKey: 'MRO', label: 'MRO' },
                  { dataKey: 'Servicios', label: 'Servicios' },
                  { dataKey: 'Maquinados', label: 'Maquinados' },
                  { dataKey: 'Empaque', label: 'Empaque' },
                  { dataKey: 'MRP', label: 'MRP' },
                  { dataKey: 'Tecnología', label: 'Tecnología' },
                ]}
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </Card>
        </Col>

        {/* Gráfica de Ventas por Categoría */}
        <Col md={12} xs={24} lg={12}>
          <Card style={{ marginTop: 16, boxShadow: "0px 2px 8px rgba(0,0,0,0.12)" }}>
            <Meta 
              avatar={<Avatar style={{ boxShadow: "2px 2px 5px 0px rgba(0,0,0,0.75)", backgroundColor: '#5f5fff' }} icon={<PieChartFilled />} size={60} xs={0} />}
              title={'Ventas por categorías'}
              description={'Ganancias totales de órdenes finalizadas'} 
            />
            <div style={{ width: "100%", height: "350px" }}>
              <PieChart series={[ { data: analyticsData.byCategory }, ]} style={{ width:'100%', height:'100%'}} />
            </div>
          </Card>
        </Col>

        {/* Tablas de datos */}
        {renderCard('Top 5 Clientes por Órdenes Finalizadas', 'Clientes con más órdenes completadas', <UserOutlined />, analyticsData.bySales, columnsConfig.sales)}
        {renderCard('Top 5 Clientes por Ingresos', 'Clientes con mayor volumen de compra', <MoneyCollectOutlined />, analyticsData.byRevenue, columnsConfig.revenue)}
        {renderCard('Top 5 Proyectos por Ventas', 'Proyectos con mayores ingresos', <ProjectOutlined />, analyticsData.topProjects, columnsConfig.projects)}
        {renderCard('Órdenes por Estatus', 'Cantidad de órdenes agrupadas por estado', <FileDoneOutlined />, analyticsData.ordersByStatus, columnsConfig.status)}
      </Row>
    </Layout>
  );
}
