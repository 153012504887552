import React, { useEffect, useState } from 'react';
import {useParams} from "react-router-dom";
import { Row, Col, Typography, Button, Breadcrumb, Skeleton, Modal, Form, Input, Upload, message } from 'antd';
import {UploadOutlined } from "@ant-design/icons";

import LayoutPage from '../../layouts/LayoutPage';
import SupplierLayout from '../../layouts/SupplierLayout';
import CPLayout from '../../layouts/ControlPanelLayout';
import ProductGallery from './Gallery';
import './styles.scss'; // Archivo CSS para los estilos
import { useNavigate } from 'react-router-dom';
import AppBarMarketPlace from './global_components/AppBar';
import MarketPlaceService from '../../utils/api/marketplace';
import SupplierService from '../../utils/api/suppliers';
import '@ant-design/v5-patch-for-react-19';

const { Title } = Typography;


export default function Supplier(props) {
  const user = JSON.parse(localStorage.getItem("user"));

  const { supplier_id } = useParams();
  let role = localStorage.getItem('role');

  let history = useNavigate();

  const [vendor, setVendor] = useState([])
  const [products, setProducts] = useState([])
  const [loadingVendor, setLoadingVendor] = useState(true)
  const [editModalVisible, setEditModalVisible] = useState(false);


  const handleUpdateSupplier = async (values) => {
    console.log(values)
      const updatedSupplier = {
        userId: supplier_id,
        banner_img: values.banner_img.fileList[0].thumbUrl ,
        description: values.description,
        mision: values.mision,
        vision: values.vision,
      };

      console.log(updatedSupplier)
      SupplierService.Update(updatedSupplier)
      .then(resp => {
        message.success("Proveedor actualizado con éxito", 2);
        setEditModalVisible(false);
      })
      .catch(error => {
        message.error("Error al actualizar el proveedor. Inténtalo de nuevo.", 2);
        console.error("Error:", error);
      });

  };

  var Layout = "";

  if (role == 4) {
    Layout = LayoutPage;
  } else if (role == 6) {
    Layout = SupplierLayout;
  } else if (
    role == 1 ||
    role == 3 ||
    role == 5 ||
    role == 2 ||
    role == 10
  ) {
    Layout = CPLayout;
  }

  const handleBreadcrumbClick = (link) => {
    history(link);
  };

  const handleClick = (product) => {
    console.log(product);
    if (product === 'view-all'){
      history(`/marketplace/suppliers/${supplier_id}/all-products`);
    } else {
      history(`/marketplace/${product.category}/${product.sub_category}/${product.id}?name=${encodeURIComponent(product.name)}`);
    }
  };


  useEffect(() => {
    MarketPlaceService.getVendorByUser(supplier_id).then(resp => {
      setVendor(resp.data)
      setLoadingVendor(false)
      console.log(resp.data)
    })

    MarketPlaceService.getProductsBySupplier(supplier_id).then(resp => {
      setProducts(resp.data.products)
    }).catch(error => {
      setProducts([])
    })
  }, [])
  


  return <>
    <Layout>
      <AppBarMarketPlace/>
      {loadingVendor ? (
      <div>
        {/* Skeleton para la imagen del perfil */}
        <div style={{ marginLeft: '20px' }}>
          <Skeleton.Avatar active size={150} shape="circle" />
        </div>
        {/* Skeleton para texto */}
        <Skeleton active title={false} paragraph={{ rows: 4 }} />
      </div>
    ) : (
      <>
        {/* Below Breadcrumb Component */}
        <div style={{ position: 'relative', marginBottom: '40px', }}>
        {/* Banner Image */}
        <img
            src={vendor.role_data.banner_img || "https://placehold.co/400"} // Dynamic Banner Image
            alt="Supplier Banner"
            style={{
            width: '100%',
            height: '300px', // Adjust height as needed
            objectFit: 'cover',
            borderRadius: '8px'
            }}
        />

        {/* Profile Picture (Overlapping the Banner) */}
        <img
            src={vendor.logo}
            alt="Supplier Profile"
            style={{
            position: 'absolute',
            bottom: '-40px',
            left: '20px',
            width: '150px',
            height: '150px',
            borderRadius: '10%',
            border: '3px solid white',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
            }}
        />
        </div>

        {/* BreadCrumb */}
      <div style={{marginTop:'70px'}}>
        <Breadcrumb>
          <Breadcrumb.Item className='breadcrumb-item' onClick={() => handleBreadcrumbClick('/marketplace')}>Inicio</Breadcrumb.Item>
          { role == 1 ? 
          <Breadcrumb.Item className='breadcrumb-item' onClick={() => handleBreadcrumbClick('/marketplace/suppliers')}>Proveedores</Breadcrumb.Item>
          : <></>}
          <Breadcrumb.Item className='breadcrumb-item'>{vendor.bussiness}</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <Row gutter={[24, 24]} style={{ marginBottom: '24px' }}>
        <Col xs={24} md={24} style={{color: 'black', fontSize: '20px'}}>
          
          <Title level={2}>{vendor.bussiness}</Title>
          { user.id == supplier_id ? 
          <Button type="primary" onClick={() => setEditModalVisible(true)}> Modificar datos </Button>
          : <></>}
          <Row style={{margin:'0px', padding:'0px'}}>
            <p style={{lineHeight: '1.6', color:'black'}}>
              {vendor.role_data.description}
            </p>
          </Row>
          </Col>
          <Col xs={24} md={10} style={{color: 'black', fontSize: '20px'}}>
          <Title level={5}>Categoria</Title>
          <a style={{color:'GrayText', fontSize:'16px'}}>{vendor.vendor.category}</a>
          <Title level={5}>Sub Categoria(s)</Title>
          <a style={{color:'GrayText', fontSize:'16px'}}>{vendor.vendor.sub_category}</a>
        </Col>
        <Col xs={24} md={14}>
          
          <Row>
          <Col xs={24} md={10} offset={1}>
            {/* Visión */}
            <div style={{textAlign:'center'}}>
              <Title level={4}>Visión</Title>
              <p style={{lineHeight: '1.6', color:'black' }}>
                {vendor.role_data.vision}
              </p>
            </div>
          </Col>
          <Col xs={24} md={10} offset={2}>
            {/* Misión */}
            <div style={{textAlign:'center'}}>
              <Title level={4}>Misión</Title>
              <p style={{lineHeight: '1.6', color:'black' }}>
                {vendor.role_data.mision}
              </p>
            </div>
          </Col>
          </Row>
        </Col>
      </Row>
  
      <Row gutter={[24, 24]}>
        <Col xs={24} md={8} offset={2}>
          
        </Col>
        <Col xs={24} md={8} offset={2}>
          
        </Col>
      </Row>
      <ProductGallery products={products} handleClick={handleClick}/>
      </>
    )}
    {/* Modal para editar proveedor */}
    <Modal
  title="Editar Proveedor"
  visible={editModalVisible}
  onCancel={() => setEditModalVisible(false)}
  footer={null}
>
  <Form
    initialValues={{
      banner_img: vendor.banner_img,
      logo: vendor.logo,
      description: vendor.role_data?.description,
      mision: vendor.role_data?.mision,
      vision: vendor.role_data?.vision,
    }}
    onFinish={handleUpdateSupplier}
  >
    <Form.Item name="banner_img" label="Imagen del Banner">
      <Upload
        listType="picture-card"
        maxCount={1}
        beforeUpload={() => false}
      >
        <Button icon={<UploadOutlined />}>Subir Banner</Button>
      </Upload>
    </Form.Item>

    <Form.Item name="description" label="Descripción" rules={[{ required: true, message: "Ingresa la descripción" }]}>
      <Input.TextArea />
    </Form.Item>

    <Form.Item name="mision" label="Misión" rules={[{ required: true, message: "Ingresa la misión" }]}>
      <Input.TextArea />
    </Form.Item>

    <Form.Item name="vision" label="Visión" rules={[{ required: true, message: "Ingresa la visión" }]}>
      <Input.TextArea />
    </Form.Item>

    <Form.Item>
      <Button onClick={() => setEditModalVisible(false)} style={{ marginRight: 8 }}>
        Cancelar
      </Button>
      <Button type="primary" htmlType="submit">
        Actualizar
      </Button>
    </Form.Item>
  </Form>
</Modal>

    </Layout>
    </>
}
