export const VSCategoriesData = [
  {
    key: 1,
    name: 'MRO',  
  },
  {
    key: 2,
    name: 'Empaque',  
  },
  {
    key: 3,
    name: 'MRP',  
  },
  {
    key: 4,
    name: 'Servicios',  
  },
  {
    key: 5,
    name: 'Maquinado',  
  },
  {
    key: 6,
    name: 'Tecnología',  
  },
];