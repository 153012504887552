import React, { useState, useEffect } from 'react';
import {useParams, useNavigate, useLocation} from "react-router-dom";
import { Row, Col, Typography, Table, InputNumber, Button, Breadcrumb, notification, Skeleton } from 'antd';
import LayoutPage from '../../layouts/LayoutPage';
import SupplierLayout from '../../layouts/SupplierLayout';
import CPLayout from '../../layouts/ControlPanelLayout';
import './styles.scss'; // Archivo CSS para los estilos
import { ShoppingCartOutlined } from '@ant-design/icons';
import { useMediaQuery } from "react-responsive";

import AppBarMarketPlace from './global_components/AppBar';
import MarketPlaceService from '../../utils/api/marketplace';
import '@ant-design/v5-patch-for-react-19';

const { Title } = Typography;


export default function Products(props) {
  const { category, catalog, productId } = useParams();
  const location = useLocation();
  const isSmallScreen = useMediaQuery({ query: "(max-width: 768px)" });
  const queryParams = new URLSearchParams(location.search);
  const product = queryParams.get("name");

  const user = JSON.parse(localStorage.getItem('user'))
  let role = localStorage.getItem('role');

  let history = useNavigate();

  const [item, setItem] = useState([])
  const [loadItem, setLoadItem] = useState(false)
  const [cartTotal, setCartTotal] = useState(0);

  useEffect(() => {
    MarketPlaceService.getProductById(productId).then(resp => {
      console.log(resp.data)
      setItem(resp.data.item)
      setLoadItem(true)
    }).catch(e => { 
      console.log(e)
    })
  }, [])
  
  const formatText = (text) => {
    return text
      .split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const formattedProduct = formatText(product);
  const formattedCatalog = formatText(catalog);
  const formattedCategory = formatText(category);

  var Layout = "";

  if (role == 4) {
    Layout = LayoutPage;
  } else if (role == 6) {
    Layout = SupplierLayout;
  } else if (
    role == 1 ||
    role == 3 ||
    role == 5 ||
    role == 2 ||
    role == 10
  ) {
    Layout = CPLayout;
  }

  const handleBreadcrumbClick = (link) => {
    history(link);
  };

  const AddToMarketPlace = (record, quantity = 1) => {
    const totalPrice = record.price * quantity;

    const body = {
      client_id: user.id,
      products: [
        {
          id: record.id,
          supplier_id: record.supplier_id,
          name: record.name,
          category: record.category,
          sub_category: record.sub_category,
          quantity: quantity,
          price: record.price,
          imgs: record.images,

        }
      ],
      change_type: 'MX',
      status: 1
    }

    MarketPlaceService.addProductToCart(body).then(resp => {
      console.log(resp)
      setCartTotal((prevTotal) => prevTotal + resp.data.total_price);

      notification.open({
        message: 'Artículo Agregado al Carrito',
        description: (
          <div>
            <p>{record.name} - {record.id}</p>
            <p>Cantidad: {quantity}</p>
            <p>Precio Unitario: ${record.price}</p>
            <p>Total: ${totalPrice}</p>
          </div>
        ),
        icon: <ShoppingCartOutlined style={{ color: '#108ee9' }} />,
        placement: 'topRight',
        duration: 3,
      });
    })
  };
  
  const firstColumns = [
    {
      title: "MODELO NO.",
      dataIndex: "model",
      key: "model",
    },
    {
      title: "DESCRIPCIÓN",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "CATEGORÍA",
      dataIndex: "category",
      key: "category",
    },
  ];

  const secondColumns = [
    {
      title: "SUB-CATEGORÍA",
      dataIndex: "subCategory",
      key: "subCategory",
    },
    {
      title: "PRECIO UNITARIO (MXN)",
      dataIndex: "price",
      key: "price",
    },
    ...(role == 4
      ? [
          {
            title: 'AGREGAR AL CARRITO',
            dataIndex: 'add',
            key: 'add',
            width: 200,
            render: (_, record) => {
              let inputValue = 1;
  
              return (
                <Row align="middle" gutter={8}>
                  <Col>
                    <InputNumber
                      min={1}
                      max={item[0].product.stock}
                      defaultValue={1}
                      onChange={(value) => {
                        inputValue = value || 1;
                      }}
                    />
                  </Col>
                  <Col>
                    <Button
                      type="primary"
                      onClick={() => AddToMarketPlace(item[0].product, inputValue)}
                    >
                      Agregar
                    </Button>
                  </Col>
                </Row>
              );
            },
          },
        ]
      : []),
  ];


  const data = item.map((entry, index) => ({
    key: index + 1,
    model: entry.product.name,
    description: entry.product.description,
    category: entry.product.category,
    subCategory: entry.product.sub_category,
    price: entry.product.price
  }));

  return (
    <Layout>
      <AppBarMarketPlace/>
      {/* Imagen principal y descripción */}
      <div style={{marginBotton:'40px'}}>
      <Breadcrumb style={{marginBottom:'20px'}}>
        <Breadcrumb.Item className='breadcrumb-item' onClick={() => handleBreadcrumbClick('/marketplace')}>Inicio</Breadcrumb.Item>
        <Breadcrumb.Item className='breadcrumb-item' onClick={() => history(`/marketplace/${category}`)}>
          {formattedCategory}
        </Breadcrumb.Item>
        <Breadcrumb.Item className='breadcrumb-item' onClick={() => history(`/marketplace/${category}/${catalog}`)}>
          {formattedCatalog}
        </Breadcrumb.Item>
        <Breadcrumb.Item className='breadcrumb-item'>{formattedProduct}</Breadcrumb.Item>
      </Breadcrumb>
    </div>

    {loadItem ? (<>
    <Row gutter={[24, 24]} style={{ marginBottom: '24px' }}>
      <Col xs={24} md={8}>
        <img
          src={item[0].product.images[0]}
          alt={item[0].product.name}
          style={{ width: '100%', borderRadius: '8px' }}
        />
      </Col>
      <Col xs={24} md={16} style={{ color: 'black', fontSize: '20px' }}>
        <Title level={2}>{formattedProduct}</Title>
        {role == 1 ? 
        <a
        onClick={() => {
          history(`/marketplace/suppliers/${item[0].supplier.id}`);
        }}
        >
          {item[0].supplier.bussiness}
        </a>
        : <p>{item[0].supplier.bussiness}</p>}
        <br/>
        <b>Stock:</b> <span>{item[0].product.stock}</span>
        <p style={{ color: 'black' }}> {item[0].product.description} </p>
      </Col>
    </Row>

    {/* Tabla de productos */}
    <Row gutter={[24, 24]} style={{ marginBottom: "12px" }}>
      <Col span={24} style={{ color: "black" }}>
        <p>No incluye envío ni impuestos.</p>
      </Col>
      {isSmallScreen ? (
        <>
          
          <Col span={24}>
            <Table
              columns={firstColumns}
              dataSource={data}
              pagination={false}
              bordered
              size="small"
            />
          </Col>
          
          <Col span={24}>
            <Table
              columns={secondColumns}
              dataSource={data}
              pagination={false}
              bordered
              size="small"
            />
          </Col>
        </>
      ) : (
        
        <Col span={24}>
          <Table
            columns={[...firstColumns, ...secondColumns]}
            dataSource={data}
            pagination={false}
            bordered
          />
        </Col>
      )}
    </Row>
  </> ) : (<>
    <Row gutter={[24, 24]} style={{ marginBottom: '24px' }}>
      <Col xs={24} md={8}>
        <Skeleton.Image active style={{ width: '100%', height: '200px', borderRadius: '8px' }} />
      </Col>
      <Col xs={24} md={16}>
        <Skeleton active title={{ width: '50%' }} paragraph={{ rows: 2, width: ['80%', '60%'] }} />
      </Col>
    </Row>

    <Row gutter={[24, 24]} style={{ marginBottom: '24px' }}>
      <Col span={24}>
        <Skeleton active paragraph={{ rows: 1, width: '30%' }} />
      </Col>
      <Col span={24}>
        <Skeleton active title={{ width: '100%' }} paragraph={{ rows: 3, width: '100%' }} />
      </Col>
    </Row>
    </>)}

    </Layout>
  );
}
