import { Tag } from 'antd';

export default function CategoryTag({ sub, vsCategories }) {
    // Definir colores por categoría
    const categoryColors = {
      1: '#FFB6A1', // MRO - Coral Suave
      2: '#A3E4DB', // Empaque - Verde Agua
      3: '#A1C4FD', // MRP - Azul Cielo
      4: '#FDCB8D', // Servicios - Durazno
      5: '#D4A5A5', // Maquinado - Rosa Suave
      6: '#C8A2C8', // Tecnología - Lavanda
    };

    // Buscar la categoría correspondiente
    const category = vsCategories.find(category => category.key === sub.cat);
    
    return (
        <Tag color={category ? categoryColors[category.key] || '#775dd9' : '#775dd9'}>
            {category ? category.name : 'Categoría no encontrada'}
        </Tag>
    );
}
