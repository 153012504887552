import React, { useEffect, useState } from "react";
import { List, Button, Card, Tag, Row, Col, message, Input } from "antd";
import { AppstoreOutlined, BarsOutlined, DeleteOutlined, SearchOutlined, CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import PurchaseSummaryCard from "./PurchaseSummaryCard";
import MarketPlaceService from "../../../utils/api/marketplace";
import { useMediaQuery } from "react-responsive";
import '@ant-design/v5-patch-for-react-19';

const { Search } = Input;

const ShoppingList = ({ product, catalog, category, qty, setQty, cartProducts, cart}) => {
  const user = JSON.parse(localStorage.getItem('user'));

  let history = useNavigate();
  const [gridColumns, setGridColumns] = useState(1);
  const [isExpanded, setIsExpanded] = useState(false);
  const isSmallScreen = useMediaQuery({ query: "(max-width: 768px)" });

  const handleCardClick = (product) => {
    history(`/marketplace/${product.category}/${catalog}/${product.id}?name=${encodeURIComponent(product.name)}`);
  };

  const location = useLocation();
  const isShoppingCart = location.pathname === "/marketplace/shopping-cart";

  const freeShipping = true;

  const [open, setOpen] = useState(false);
  const [loadProducts, setLoadProducts] = useState([]);
  const [refProducts, setRefProducts] = useState([])
  const [products, setProducts] = useState([]);

  useEffect(() => {
    setLoadProducts(true);
    setProducts(cartProducts);
    setLoadProducts(false);

    loadRefProducts(cartProducts)
  }, [cartProducts]);

  const loadRefProducts = async (products) => {
    await products.forEach(pro => {
      MarketPlaceService.getProductById(pro.id).then(resp => {
        console.log(refProducts)
        // Agregar el nuevo producto al estado sin sobrescribir los anteriores
        setRefProducts(prevProducts =>  [...prevProducts, resp.data.item[0].product]);
      });
    });
  };

  const subtotal = isShoppingCart
    ? products.reduce((acc, item) => acc + item.price * item.quantity, 0)
    : 0;

    const handleChange = (option, productId) => {
      // Obtener el producto de referencia para verificar el stock
      const refProduct = refProducts.find(p => p.id === productId);
    
      if (!refProduct) {
        message.error("Información de stock no disponible.");
        return;
      }
    
      const updatedProducts = products.map((item) => {
        if (item.id === productId) {
          if (option === 1 && item.quantity >= refProduct.stock) {
            message.warning("No puedes agregar más productos de los disponibles en stock.");
            return item;  // No modificar cantidad si supera el stock
          }
          
          return {
            ...item,
            quantity: option === 1 
              ? item.quantity + 1 
              : Math.max(1, item.quantity - 1),
          };
        }
        return item;
      });
    
      setProducts(updatedProducts);
    
      // Actualizar el carrito en el backend
      MarketPlaceService.addProductToCart({
        client_id: user.id,
        products: updatedProducts,
      })
        .then(() => {
        })
        .catch((error) => {
          console.error("Error actualizando carrito:", error);
          message.error("Error actualizando carrito.");
        });
    };
    

  const handleDeleteProduct = (product_id) => {
    MarketPlaceService.deleteCartProduct(user.id, product_id)
      .then(() => {
        // Filtrar productos localmente
        const updatedProducts = products.filter((item) => item.id !== product_id);
        setProducts(updatedProducts);
  
        // Recalcular subtotal
        const newSubtotal = updatedProducts.reduce(
          (acc, item) => acc + item.price * item.quantity,
          0
        );
        
        // Actualizar el subtotal y el carrito en la UI
        cart.price = newSubtotal;
  
        message.success("Producto eliminado del carrito.");
      })
      .catch((error) => {
        console.error("Error eliminando producto del carrito:", error);
        message.error("Error eliminando producto del carrito.");
      });
  };
  
  return <>
    <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
      <div style={{ flex: 3 }}>
        <List
          grid={{ gutter: 12, column: gridColumns }}
          dataSource={products}
          loading={loadProducts}
          renderItem={(item) => (
            <List.Item style={{ width: "100%" }}>
              <Card
                bordered={true}
                style={{
                  display: "block",
                  width: "100%",
                  cursor: "pointer",
                  transition: "0.3s",
                }}
                onClick={() => {}}
              >
                <Row gutter={[24, 24]} style={{ width: "100%" }}>
                <Col xs={24} md={6} style={{ textAlign: "left" }}>
                  <img
                    src={item.imgs[0]}
                    alt={item.name}
                    style={{ width: 100, height: 100 }}
                  />
                  {gridColumns === 1 ? null : (
                    <>
                      <Tag color="blue">{item.category}</Tag>
                      <Tag color="green">{item.sub_category}</Tag>
                    </>
                  )}
                </Col>
                <Col xs={24} md={12} style={{ textAlign: "left", alignContent: "flex-start" }}>
                  <div>
                    <h3>{item.name}</h3>
                    <p>{item.description}</p>
                    {gridColumns === 2 ? null : (
                      <>
                        <Tag color="blue">{item.category}</Tag>
                        <Tag color="green">{item.sub_category}</Tag>
                      </>
                    )}
                  </div>
                </Col>
                <Col xs={24} md={6} style={{ textAlign: "right" }}>
                  <div>
                    <h2 style={{ marginBottom: 8 }}>
                      ${item.price * item.quantity}
                    </h2>
                    <p style={{ color: "green" }}>Sin costo de envío</p>
                      <div
                        style={{
                          marginTop: 12,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          onClick={() => handleChange(0, item.id)}
                          disabled={item.quantity <= 1}
                        >
                          -
                        </Button>
                        <div style={{ padding: "0 12px", fontSize: "16px" }}>{item.quantity}</div>
                        <Button
                        onClick={() => handleChange(1, item.id)}
                        disabled={item.quantity >= (refProducts.find(p => p.id === item.id)?.stock || 0)}
                      >
                        +
                      </Button>
                        <Button
                          type="primary"
                          danger
                          icon={<DeleteOutlined />}
                          onClick={() => handleDeleteProduct(item.id)}
                          style={{ marginLeft: "10px" }}
                        >
                          Eliminar
                        </Button>
                      </div>
                  </div>
                </Col>
                </Row>
              </Card>
            </List.Item>
          )}
        />
      </div>
      <div className={`purchase-summary-container ${isExpanded ? "expanded" : "collapsed"}`}>
      {isSmallScreen ? <div className="summary-header" onClick={() => setIsExpanded(!isExpanded)}>
        <p style={{fontSize:'16px'}}>{isExpanded ? 'Resumen de la compra' : `Total: ${subtotal.toFixed(2)}`}</p>
        <button className="toggle-btn" style={{marginRight:'20px', fontSize:'18px'}}>{isExpanded ? <CaretDownOutlined/> : <CaretUpOutlined/>}</button>
      </div> : null}
      <div className="summary-content" style={{ maxHeight: isExpanded || !isSmallScreen ? "300px" : "0px", opacity: isExpanded || !isSmallScreen ? 1 : 0 }}>
      {isExpanded || !isSmallScreen ? (
        <PurchaseSummaryCard
        products={products}
        productsCount={products.length}
        subtotal={subtotal}
        freeShipping={freeShipping}
        checkout={false}
      />
      ) : null}
      </div>
      <style jsx>{`
      @media (max-width: 768px) {
        .purchase-summary-container {
          position: fixed;
          bottom: 0;
          left: 0;
          width: 100%;
          z-index: 10;
          background: white;
          box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
        }
        .summary-content {
          overflow: hidden;
          transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
        }
        .purchase-summary-container :global(.ant-card) {
          padding: 4px;
          font-size: 10px;
        }
        .purchase-summary-container h3 {
          font-size: 12px;
          margin-bottom: 0px;
        }
        .purchase-summary-container div {
          margin-bottom: 0px;
        }
        .purchase-summary-container button {
          height: 36px;
          font-size: 12px;
        }
        .summary-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          padding-top: 10px;
          padding-bottom: 10px;
          padding-left:20px;
          font-weight: bold;
          color: black;
          background: #ffffff;
        }
        .toggle-btn {
          background: none;
          border: none;
          font-size: 14px;
          cursor: pointer;
        }
      }
      @media (min-width: 769px) {
        .purchase-summary-container {
          position: relative;
          flex: 1;
        }
      }
    `}</style>
    </div>
    </div>
    </>
};

export default ShoppingList;