import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Col,
  Alert,
  Select,
  Row,
  Checkbox,
  Upload,
  AutoComplete,
  message,
  InputNumber,
  Modal,
} from "antd";
import '@ant-design/v5-patch-for-react-19';
import Layout from "../layouts/NavBar";
// import RegisterCustomer from '../components/RegisterCustomer'
// import RegisterSupplier from '../components/RegisterSupplier'
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../utils/api/auth";
import { ArrowLeftOutlined, PlusOutlined } from "@ant-design/icons";
import "../scss/register.scss";
import RegisterService from "../utils/api/register";
import { VSCategoriesData } from "../data/vs";

export default function RegisterX() {
  const { Option } = Select;
  const [form] = Form.useForm();

  const [error, setError] = useState(false);
  const [autoCompleteResult, setAutoCompleteResult] = useState([]);

  const [load, setLoad] = useState(false);
  const [logo, setLogo] = useState({
    logoList: [],
  });

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  const handleCheckboxChange = (e) => {
    if (e.target.checked) {
      setIsModalVisible(true);
    } else {
      setTermsAccepted(false);
    }
  };
  
  const handleOk = () => {
    setTermsAccepted(true);
    setIsModalVisible(false);
  };
  
  const handleCancel = () => {
    setTermsAccepted(false);
    setIsModalVisible(false);
  };


  const [disabled, setDisabled] = useState({
    stateOrg: false,
    cityOrg: false,
    pcOrg: false,
    colonyOrg: false,
    statePlanta: false,
    cityPlanta: false,
    pcPlanta: false,
    colonyPlanta: false,
  });

  const [country, setCountry] = useState({ countries: [] });
  const [state, setState] = useState({ states: [] });
  const [city, setCity] = useState({ cities: [] });
  const [pc, setPC] = useState({ pcodes: [] });
  const [colony, setColony] = useState({ colonies: [] });
	const categories = VSCategoriesData

  let history = useNavigate();

  useEffect(() => {

    //cuntry
		setCountry({
			countries: [
				{
					id: 1,
					name: "México",
					prefix: "es-mx",
					status: 1,
					created_at: "2023-02-27T18:13:15.000000Z",
					updated_at: "2023-02-27T18:13:15.000000Z",
				},
			],
		});

		setState({
      states: [
        { id: 1, name: 'Aguascalientes' },
        { id: 2, name: 'Baja California' },
        { id: 3, name: 'Baja California Sur' },
        { id: 4, name: 'Campeche' },
        { id: 5, name: 'Chiapas' },
        { id: 6, name: 'Chihuahua' },
        { id: 7, name: 'Coahuila' },
        { id: 8, name: 'Colima' },
        { id: 9, name: 'Durango' },
        { id: 10, name: 'Guanajuato' },
        { id: 11, name: 'Guerrero' },
        { id: 12, name: 'Hidalgo' },
        { id: 13, name: 'Jalisco' },
        { id: 14, name: 'México' },
        { id: 15, name: 'Michoacán' },
        { id: 16, name: 'Morelos' },
        { id: 17, name: 'Nayarit' },
        { id: 18, name: 'Nuevo León' },
        { id: 19, name: 'Oaxaca' },
        { id: 20, name: 'Puebla' },
        { id: 21, name: 'Querétaro' },
        { id: 22, name: 'Quintana Roo' },
        { id: 23, name: 'San Luis Potosí' },
        { id: 24, name: 'Sinaloa' },
        { id: 25, name: 'Sonora' },
        { id: 26, name: 'Tabasco' },
        { id: 27, name: 'Tamaulipas' },
        { id: 28, name: 'Tlaxcala' },
        { id: 29, name: 'Veracruz' },
        { id: 30, name: 'Yucatán' },
        { id: 31, name: 'Zacatecas' },
        { id: 32, name: 'Ciudad de México' },
      ],
    }); 
  }, []);

  const onemailChange = (value) => {
    if (!value) {
      setAutoCompleteResult([]);
    } else {
      setAutoCompleteResult(
        [
          "@live.com.mx",
          "@gmail.com",
          "@outlook.com",
          "@hotmail.com",
          "@yahoo.com",
          "@epno.com.mx",
        ].map((domain) => `${value}${domain}`)
      );
    }
  };
  const emailOptions = autoCompleteResult.map((email) => ({
    label: email,
    value: email,
  }));

  const { logoList } = logo;

  const propsLogo = {
    onRemove: (file) => {
      setLogo((state) => {
        const index = state.logoList.indexOf(file);
        const newFileList = state.logoList.slice();
        newFileList.splice(index, 1);
        return {
          logoList: newFileList,
        };
      });
    },
    beforeUpload: (file) => {
      setLogo((state) => ({
        logoList: [...state.logoList, file],
      }));
      return false;
    },
    logoList,
  };

  const onFinish = async (values) => {
    setLoad(true);
    let base64Logo = values.logo.fileList[0].thumbUrl;
  
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("email", values.email);
    formData.append("phone", values.phone);
    formData.append("password", values.password);
    formData.append("bussiness", values.bussiness);
    formData.append("rfc", values.rfc);
    formData.append("country", values.country);
    formData.append("state", values.state);
    formData.append("city", values.city);
    formData.append("postal_code", values.postal_code);
    formData.append("colony", values.colony);
    formData.append("street", values.street);
    formData.append("ext_num", values.ext_num);
    formData.append("int_num", values.int_num);
    formData.append("logo", base64Logo);
    formData.append("role", Number(values.role));
  
    let role_data = {};
    console.log(values)
  
    if (values.role == 6) {
      role_data = {
        url: values.url,
        cat1: values.categories,
        iva: values.iva,
        terms: values.terms,
      };
    } else if (values.role == 4) {
      role_data = {
        pay_days: values.pay_days,
      };
    }
  
    const formDataObject = {};
    formData.forEach((value, key) => {
      formDataObject[key] = value;
    });
  
    formDataObject.role_data = role_data; // Aquí se asegura de que role_data se agregue correctamente
    AuthService.Register(formDataObject)
      .then((response) => {
        if (response.data.success == true) {
          message.success("Registro exitoso, redirigiendo al login...", 2);
          setTimeout(() => {
            history('/');
          }, 2000);
        } else {
          setError(true);
          setLoad(false);
        }
      })
      .catch((error) => {
        setError(true);
        setLoad(false);
        message.error("Hubo un error en el registro, inténtalo nuevamente.");
      });
  };
  
  return (

    /*<Row style={{backgroundColor: '#112240', minHeight: '100vh', color: 'white'}}>
      <Col sm={8} style={{color: 'white', backgroundImage: `url('/img/lg-bg.jpg')`, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}></Col>

      <Col sm={16} style={{color: 'white', backgroundColor: '#112240', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
        <div style={{width: '90%'}}>
          <h1>Registro</h1>

          <Form form={form} name="register" onFinish={onFinish} layout="vertical" style={{ margin: 16 }} >
            <Row gutter={[12, 12]}>

              <Col xs={24} md={12} lg={12} xl={12}> {/* Nombre completo }
                <Form.Item
                  name="name"
                  label="Nombre completo"
                  labelAlign="top"
                  layout="vertical"
                  className="register"
                  rules={[ { required: true, message: "Ingresa el nombre de usuario!" }, ]} >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              </Col>

              <Col xs={24} md={12} lg={12} xl={12}> {/* Tipo de usuario }
                <Form.Item
                  name="role"
                  label="Tipo"
                  className="register"
                  rules={[ { required: true, message: "Selecciona un tipo de usuario.", }, ]} >
                  <Select placeholder="Selecciona un tipo de usuario">
                    <Option value={4}>Soy comprador</Option>
                    <Option value={6}>Soy proveedor</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.role !== currentValues.role } >
              { ({ getFieldValue }) => (getFieldValue("role") === 6 || getFieldValue("role") === 4) && (<>

                <Col xs={24} md={12} lg={8}> {/* Correo electronico }
                  <Form.Item
                    name="email"
                    label="E-mail"
                    className="register"
                    rules={[
                      {
                        type: "email",
                        message: "El correo agregado no es valido.",
                      },
                      {
                        required: true,
                        message: "Favor de ingresar un correo.",
                      },
                    ]}
                  >
                    <AutoComplete options={emailOptions} onChange={onemailChange}>
                      <Input
                        className="login-input"
                        placeholder="Correo electronico"
                      />
                    </AutoComplete>
                  </Form.Item>
                </Col>
              </>)}
              </Form.Item>

              <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.role !== currentValues.role } >
              {({ getFieldValue }) => getFieldValue("role") === 6 ? (<>
                <h1>adasdsa</h1>
              </>) : ( getFieldValue("role") === 4 && (<>
                <h1>saasdsaaddsaasd</h1>
              </>))}
              </Form.Item>

          
          </Row>
          </Form>
          

        </div>
      </Col>
      <img src='/img/epo_blanco.png' width={156} height={48} style={{position: 'absolute', marginLeft: '32px', marginTop: '32px'}}/> 
    </Row>
    */
    <div style={{backgroundColor: '#112240', minHeight: '100vh', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
      <Row style={{alignItems: 'start', width: '100%'}}>
        <img src='/img/epo_blanco.png' width={156} height={48} style={{ marginLeft: '32px', marginTop: '32px'}}/> 
      </Row>
      <div style={{width: '90%'}}>
      <div style={{display: 'flex', alignItems: 'center', marginLeft: '16px', marginTop: '32px', marginBottom: '32px'}}>
        <ArrowLeftOutlined style={{cursor: 'pointer', fontSize: '32px'}} onClick={() => {history('/')}}/>
        <h2 style={{margin:0,  textAlign: "start", marginLeft: '16px', color: 'white' }}>Registro</h2>
      </div>
      {error && (
        <Col
          xs={24}
          style={{ marginTop: 16, marginBottom: 16, textAlign: "center" }}
        >
          <Alert
            message="Hay un error en los datos"
            type="error"
            closable
          />
        </Col>
      )}
      <Form
        form={form}
        name="register"
        onFinish={onFinish}
        layout="vertical"
        style={{ margin: 16 }}
        // scrollToFirstError
      >
        <Row gutter={[12, 12]}  justify="center" align="middle">
          <Col xs={24} md={12} lg={8} xl={6}>
            <Form.Item
              name="name"
              label="Nombre"
              className="register"
              rules={[
                { required: true, message: "Ingresa el nombre de usuario!" },
              ]}
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8} xl={6}>
            <Form.Item
              name="email"
              label="E-mail"
              className="register"
              rules={[
                {
                  type: "email",
                  message: "El correo agregado no es valido.",
                },
                {
                  required: true,
                  message: "Favor de ingresar un correo.",
                },
              ]}
            >
              <AutoComplete options={emailOptions} onChange={onemailChange}>
                <Input
                  className="login-input"
                  placeholder="Correo electronico"
                />
              </AutoComplete>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8} xl={6}>
            <Form.Item
              name="phone"
              label="Telefono"
              className="register"
              rules={[
                {
                  required: true,
                  message: "Favor de agregar un numero de telefono.",
                },
                {
                  pattern: /^(?:\d*)$/,
                  message: "Favor de agregar un numero valido",
                },
                {
                  pattern: /^[\d]{10,13}$/,
                  message:
                    "El numero de telefono debe tener al menos 10 caracteres y no mayor a 13.",
                },
              ]}
            >
              <Input style={{ width: "100%", }} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8} xl={6}>
            <Form.Item
              name="password"
              label="Contraseña"
              className="register"
              rules={[
                {
                  required: true,
                  message: "Favor de agregar su contraseña.",
                },
                {
                  min: 8,
                  message: "La contraseña debe contener al menos 8 caracteres",
                },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8} xl={6}>
            <Form.Item
              name="confirm"
              label="Confirmar Contraseña"
              className="register"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Favor de confirmar la contraseña.",
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject("Las contraseñas no coinciden.");
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8} xl={6}>
            <Form.Item
              label="Nombre de la organización"
              name="bussiness"
              className="register"
              rules={[
                {
                  required: true,
                  message: "Debes ingresar el nombre de la organización",
                },
              ]}
            >
              <Input placeholder="Añadir nombre" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8} xl={6}>
            <Form.Item
              label="RFC"
              name="rfc"
              className="register"
              rules={[
                {
                  required: true,
                  message: "Debes ingresar el RFC de la organización",
                },
              ]}
            >
              <Input maxLength={13} minLength={12} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8} xl={6}>
            <Form.Item
              label="País"
              name="country"
              className="register"
              rules={[{ required: true, message: "Debes elegir un país" }]}
            >
              <Select
                showSearch
                name="pais"
                placeholder="Seleccionar país"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {country.countries.map((ct, index) => (
                  <Option key={index} value={ct.name}>
                    {ct.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8} xl={6}>
            <Form.Item
              label="Estado"
              className="register"
              name="state"
              rules={[{ required: true, message: "Debes elegir un estado" }]}
            >
              <Select
                showSearch
                name="estado"
                placeholder="Seleccionar estado"
                optionFilterProp="children"
                disabled={disabled.stateOrg}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {state.states.map((st, index) => (
                  <Option key={index} value={st.name}>
                    {st.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8} xl={6}>
            <Form.Item
              label="Ciudad"
              className="register"
              name="city"
              rules={[{ required: true, message: "Debes ingresar una ciudad" }]}
            >
								<Input style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8} xl={6}>
            <Form.Item
              label="Código postal"
              name="postal_code"
              className="register"
              rules={[
                { required: true, message: "Debes ingresar un codigo postal" },
              ]}
            >
							<Input style={{ width: "100%" }} />
							
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8} xl={6}>
            <Form.Item
              label="Colonia"
              name="colony"
              className="register"
              rules={[{ required: true, message: "Debes ingresar una colonia" }]}
            >
							<Input style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8} xl={6}>
            <Form.Item
              label="Calle"
              name="street"
              className="register"
              rules={[{ required: true, message: "Debes ingresar una calle" }]}
            >
              <Input placeholder="Ingresar calle" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8} xl={6}>
            <Form.Item
              label="Número exterior"
              name="ext_num"
              className="register"
              rules={[
                {
                  required: true,
                  message: "Debes ingresar el número exterior",
                },
              ]}
            >
              <Input placeholder="Ingresar núm. ext" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8} xl={6}>
            <Form.Item
              label="Número interior"
              name="int_num"
              className="register"
              rules={[
                {
                  required: false,
                  message: "Debes ingresar el número interior",
                },
              ]}
            >
              <Input placeholder="Ingresar núm. int" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8} xl={6}>
            <Form.Item
              label="Mi logo"
              className="register"
              name="logo"
              rules={[{ required: true, message: "Debes agregar tu logo" }]}
            >
              <Upload
                name="logo"
                maxCount={1}
                {...propsLogo}
                beforeUpload={() => false}
                listType="picture-card"
              >
                <div>
                  <PlusOutlined style={{color: 'white'}}/>
                  <div style={{ marginTop: 8, color: 'white' }}>Subir imagen</div>
                </div>
              </Upload>
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8} xl={6}>
            <Form.Item
              name="role"
              label="Tipo"
              className="register"
              rules={[
                {
                  required: true,
                  message: "Selecciona un tipo de usuario.",
                },
              ]}
            >
              <Select placeholder="Selecciona un tipo de usuario">
                <Option value={4}>Soy comprador</Option>
                <Option value={6}>Soy proveedor</Option>
              </Select>
            </Form.Item>
          </Col>

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.role !== currentValues.role
            }
          >
            {({ getFieldValue }) =>
              getFieldValue("role") === 6 ? (
                <>
                  <Col xs={24} md={12} lg={8} xl={6}>
                    <Form.Item label="Página web" name="url" className="register">
                      <Input placeholder="Ingresar página web" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12} lg={8} xl={6}>
                    <Form.Item 
											label="Categoria" 
                      className="register"
											name="categories"
                    	rules={[{ required: true, message: 'Debes elegir almenos una categoria' }]}
                  	>
                      <Select
                          maxCount={1}
                          maxLength={1}
                          allowClear
                          placeholder="Seleccionar la(s) categoria(s)"
                          name="categories"
                          rules={[{ required: true }]}
                      >
                      { categories.map((category, index) => (
                        <Option key={index} value={category.key}>{category.name}</Option>
                      ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12} lg={8} xl={6}>
                    <Form.Item
                      label="Iva"
                      name="iva"
                      className="register"
                      rules={[{
                        required: true,
                         message: "Debes seleccionar el iva al que facturas.",
											},]}
                    >
                      <Select
                        allowClear
                        placeholder="Seleccionar iva al que facturas"
                        name="iva"
                        rules={[{ required: true }]}
                      >
                        <Option value={0.16}>16%</Option>
                        <Option value={0.08}>8%</Option>
                      </Select>
                    </Form.Item>
                  </Col>
									
                </>
              ) : (
                getFieldValue("role") === 4 && (
                  <Col xs={24} md={12} lg={8} xl={6}>
                    <Form.Item 
                      label="¿A cuántos días se paga?"
                      className="register"
                      name="pay_days"
                      rules={[
                        {
                          required: true,
                          message:
                            "Debes mencionar en cuantos días realizas los pagos.",
                        },
                      ]}
                    >
                      <InputNumber placeholder="Ingresar los días" min={1} style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                )
              )
            }
          </Form.Item>
          <Col xs={24} md={12} lg={8} xl={6}>
  <Form.Item
    name="terms"
    valuePropName="checked"
    rules={[{ validator: (_, value) => value ? Promise.resolve() : Promise.reject(new Error("Debe aceptar los términos")) }]}
  >
    <Checkbox 
      style={{ color: "white" }} 
      onChange={handleCheckboxChange} 
      checked={termsAccepted}
    >
      Acepto los términos y condiciones
    </Checkbox>
  </Form.Item>
</Col>

        </Row>
        <Row justify="center">
          <Col xs={24} md={12} lg={8}>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={load} style={{ width: "100%" }}>
                Registrarme
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      </div>
      <Modal
  title="Términos y Condiciones"
  open={isModalVisible}
  onOk={handleOk}
  closeIcon={false}
  onCancel={handleCancel}
  maskClosable={false}
  footer={[
    <Button key="submit" type="primary" onClick={handleOk}>
      Aceptar
    </Button>,
  ]}
>
  <div
    style={{
      maxHeight: "300px",
      overflowY: "scroll",
      padding: "10px",
    }}
  >
    <p>
      <strong>Fecha de última actualización: 01/Abr/2025</strong>
    </p>
    <h4>CAPÍTULO I. ACEPTACIÓN Y DEFINICIONES</h4>
    <p><strong>1. Aceptación de los Términos</strong><br />
    Al acceder, registrarse o utilizar los servicios de la plataforma digital EP&O Marketplace (en adelante, "la Plataforma"), usted acepta quedar legalmente obligado por estos Términos y Condiciones Generales de Uso (en adelante, "los Términos"), así como por las disposiciones legales aplicables en los Estados Unidos Mexicanos, incluyendo pero no limitado a la Ley Federal de Protección al Consumidor, el Código de Comercio, la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, la Ley de Propiedad Industrial, la Ley Federal del Derecho de Autor y el Código Civil Federal.</p>

    <p>Si el Usuario no está de acuerdo con los Términos, deberá abstenerse de utilizar la Plataforma.</p>

    <p><strong>2. Definiciones</strong><br />
    Para efectos del presente documento, se entenderá por:</p>
    <p>2.1 EP&O: EPNO Corporation S. de R.L. de C.V., con domicilio en Cd. Juárez, Chihuahua, México, propietaria, titular y operadora de la Plataforma.<br />
    2.2 Plataforma: Sitio web y/o aplicación móvil que opera bajo el dominio www.epno.com.mx y otros subdominios vinculados.<br />
    2.3 Usuario: Toda persona física o moral que accede, navega, utiliza o se registra en la Plataforma, sea en calidad de Cliente o Proveedor.<br />
    2.4 Cliente: Usuario que utiliza la Plataforma con el objeto de adquirir productos o servicios ofrecidos por los Proveedores.<br />
    2.5 Proveedor: Usuario que ofrece productos o servicios industriales a través de la Plataforma para ser adquiridos por Clientes.<br />
    2.6 Orden: Transacción iniciada mediante una solicitud de cotización y/o compra dentro de la Plataforma.<br />
    2.7 Contenido: Toda información, texto, imagen, dato, archivo o documento publicado por cualquier Usuario.</p>

    <h4>CAPÍTULO II. OBJETO DEL SERVICIO Y ALCANCE LEGAL</h4>
    <p><strong>3. Objeto del Servicio</strong><br />
    La Plataforma tiene como objeto principal facilitar la conexión entre Clientes industriales y Proveedores especializados, permitiendo la solicitud de cotizaciones, la comparación de precios, condiciones comerciales, y eventualmente la colocación de órdenes de compra. EP&O actúa como intermediario tecnológico y comercial, sin asumir responsabilidad directa sobre los productos, salvo que se indique expresamente lo contrario.</p>

    <p><strong>4. Relación contractual</strong><br />
    4.1 Las relaciones comerciales entre Clientes y Proveedores se regirán por contratos civiles o mercantiles entre dichos Usuarios.<br />
    4.2 EP&O no garantiza la solvencia, capacidad técnica, cumplimiento o veracidad de la información publicada por los Usuarios.<br />
    4.3 EP&O podrá intervenir como tercero neutral para la resolución de disputas cuando ambas partes lo autoricen expresamente.</p>

    <h4>CAPÍTULO III. REGISTRO, OBLIGACIONES Y CONDUCTA DE LOS USUARIOS</h4>
    <p><strong>5. Registro y autenticación</strong><br />
    5.1 Para acceder a funcionalidades clave, los Usuarios deberán registrarse mediante formularios en línea y aceptar el Aviso de Privacidad.<br />
    5.2 La información proporcionada deberá ser veraz, fidedigna y actualizada.<br />
    5.3 EP&O se reserva el derecho de verificar la identidad y actividad de los Usuarios mediante mecanismos automatizados y humanos.</p>

    <h4>CAPÍTULO IV. TRANSACCIONES, PAGOS Y LOGÍSTICA</h4>
    <p><strong>8. Precios y Cotizaciones</strong><br />
    8.1 Los Proveedores deberán publicar precios claros, condiciones de entrega, tiempo de fabricación y garantías aplicables.<br />
    8.2 Toda cotización enviada a través de la Plataforma constituye una oferta vinculante por el plazo que indique el Proveedor.</p>

    <p><strong>9. Pago y Facturación</strong><br />
    9.1 El Cliente se obliga a cumplir con los pagos según las condiciones pactadas.<br />
    9.2 En caso de que EP&O actúe como intermediario financiero, podrá retener comisiones de servicio.</p>

    <h4>CAPÍTULO V. CANCELACIONES, RECLAMACIONES Y RESPONSABILIDAD</h4>
    <p><strong>11. Cancelaciones y Devoluciones</strong><br />
    11.1 Cada Proveedor deberá establecer y publicar su propia política de cancelación.<br />
    11.2 Los Clientes podrán activar un procedimiento de reclamación mediante el sistema interno de la Plataforma dentro de los 5 días hábiles posteriores a la entrega.</p>

    <h4>CAPÍTULO VI. PROPIEDAD INTELECTUAL Y CONFIDENCIALIDAD</h4>
    <p><strong>13. Derechos de Propiedad Intelectual.</strong><br />
    13.1 Todos los derechos de diseño, software, marcas, logotipos, código fuente y base de datos son propiedad de EP&O.<br />
    13.2 Se prohíbe la reproducción, distribución o uso comercial sin autorización expresa.</p>

    <h4>CAPÍTULO VII. DISPOSICIONES FINALES</h4>
    <p><strong>16. Modificaciones</strong><br />
    16.1 EP&O podrá modificar unilateralmente los Términos, los cuales serán publicados en www.epno.com.mx.</p>

    <p><strong>17. Ley Aplicable y Jurisdicción</strong><br />
    17.1 Este acuerdo se rige por las leyes de los Estados Unidos Mexicanos.<br />
    17.2 Para cualquier controversia, las partes se someten a los tribunales competentes de Ciudad Juárez, Chihuahua, renunciando expresamente a cualquier otro fuero que pudiera corresponderles.</p>

    <p><strong>18. Contacto</strong><br />
    Cualquier duda o notificación deberá dirigirse a contacto@epno.com.mx</p>
  </div>
</Modal>

    </div>
    
  );
}
