import request from "../services/HTTPClient";
import { requestMethods, API } from "../constants";

function UpdateStatus(service_id, status) {
  return request({
    url: API.SERVICES.UPDATE.concat(service_id),
    method: requestMethods.PUT,
    data: {status: status}
  });
}

function Update(service_id, data) {
  return request({
    url: API.SERVICES.UPDATE.concat(service_id),
    method: requestMethods.PUT,
    data: data
  });
}

// QUOTES ------------------------------------------------------
function NewQuote(data) {
  return request({
    url: API.SERVICES.QUOTES.APPLY,
    method: requestMethods.POST,
    data: data
  });
}

function NewInternalQuote(data) {
  return request({
    url: API.SERVICES.QUOTES.NEW_INTERNAL,
    method: requestMethods.POST,
    data: data
  });
}

function UpdateQuote(proposal_id, data) {
  return request({
    url: API.SERVICES.QUOTES.UPDATE.concat(proposal_id),
    method: requestMethods.PUT,
    data: data
  });
}

function GetQuotesByOrder(order_id) {
  return request({
    url: API.SERVICES.QUOTES.GET_BY_ORDER.concat(order_id),
    method: requestMethods.GET,
  });
}

function ChooseQuote(proposal_id, data) {
  return request({
    url: API.SERVICES.QUOTES.CHOOSE_PROPOSAL.concat(proposal_id),
    method: requestMethods.PUT,
    data: data
  });
}

// REVIEWS -----------------------------------------------------
function NewReview(service_id, data) {
  return request({
    url: API.SERVICES.REVIEWS.ADD.concat(service_id),
    method: requestMethods.POST,
    data: data
  });
}

function EditReview(review_id, data) {
  return request({
    url: API.SERVICES.REVIEWS.UPDATE.concat(review_id),
    method: requestMethods.PUT,
    data: data
  });
}

function DeleteReview(review_id) {
  return request({
    url: API.SERVICES.REVIEWS.DELETE.concat(review_id),
    method: requestMethods.DELETE,
  });
}

function ReplyReview(review_id, data) {
  return request({
    url: API.SERVICES.REVIEWS.REPLY.concat(review_id),
    method: requestMethods.POST,
    data: data
  });
}

function GetReviewByService(service_id) {
  return request({
    url: API.SERVICES.REVIEWS.GET.concat(service_id),
    method: requestMethods.GET,
  });
}

// COMPLAINTS -----------------------------------------------
function NewComplaint(data) {
  return request({
    url: API.SERVICES.COMPLAINTS.NEW,
    method: requestMethods.POST,
    data: data
  });
}

function EditComplaint(complaint_id, data) {
  return request({
    url: API.SERVICES.COMPLAINTS.UPDATE.concat(complaint_id),
    method: requestMethods.PUT,
    data: data
  });
}

function DeleteComplaint(complaint_id) {
  return request({
    url: API.SERVICES.COMPLAINTS.DELETE.concat(complaint_id),
    method: requestMethods.DELETE,
  });
}

function ReplyComplaint(complaint_id, data) {
  return request({
    url: API.SERVICES.COMPLAINTS.REPLY.concat(complaint_id + '/reply'),
    method: requestMethods.POST,
    data: data
  });
}

function GetComplaintByService(service_id) {
  return request({
    url: API.SERVICES.COMPLAINTS.GET.concat(service_id),
    method: requestMethods.GET,
  });
}

function SolveComplaint(complaint_id) {
  return request({
    url: API.SERVICES.COMPLAINTS.SOLVE.concat(complaint_id + '/resolve'),
    method: requestMethods.PUT,
  });
}

const ServicesService = {
  UpdateStatus,

  // QUOTES
  NewQuote,
  NewInternalQuote,
  Update,
  UpdateQuote,
  GetQuotesByOrder,
  ChooseQuote,

  // REVIEWS
  NewReview,
  EditReview,
  DeleteReview,
  ReplyReview,
  GetReviewByService,

  // COMPLAINTS
  NewComplaint,
  EditComplaint,
  DeleteComplaint,
  ReplyComplaint,
  GetComplaintByService,
  SolveComplaint
};

export default ServicesService;
