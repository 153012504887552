import React, { useState, useEffect } from 'react';
import { Row, Typography, Breadcrumb } from 'antd';
import LayoutPage from '../../../layouts/LayoutPage';
import SupplierLayout from '../../../layouts/SupplierLayout';
import CPLayout from '../../../layouts/ControlPanelLayout';
import '../styles.scss'; 
import { useNavigate, useLocation } from 'react-router-dom';
import ShoppingList from './ShoppingList';
import AppBarMarketPlace from './AppBar';
import MarketPlaceService from '../../../utils/api/marketplace';

export default function ShoppingCart(props) {
  const user = JSON.parse(localStorage.getItem('user'));
  const role = localStorage.getItem('role');
  const navigate = useNavigate();
  const location = useLocation();

  const [cart, setCart] = useState([]);
  const [products, setProducts] = useState([]);
  const [qty, setQty] = useState(1);

  // 📌 Función para extraer parámetros de la URL
  const getQueryParams = (url) => {
    let queryParams = {};
    let queryString = url.split("?")[1]?.split("#")[0]; 
    if (queryString) {
      queryString.split("&").forEach((param) => {
        let [key, value] = param.split("=");
        queryParams[key] = decodeURIComponent(value);
      });
    }
    return queryParams;
  };

  // Extraer parámetros desde la URL
  const { checkout_id, order_id, payment_status } = getQueryParams(window.location.href);

  // Fetch cart products
  useEffect(() => {
    MarketPlaceService.getCartByClient(user.id)
      .then((resp) => {
        console.log('Cart response:', resp);
        
        if (resp.data) {
          setCart(resp.data);
          setProducts(resp.data.products || []);

          if (payment_status === 'paid') {
            const newOrder = {
              client_id: resp.data.client_id,
              client_name: resp.data.client_info.name,
              client_bussiness: resp.data.client_info.bussiness,
              client_fcm: resp.data.client_info.fcmToken,
              products: resp.data.products,
              price: resp.data.price,
              change_type: resp.data.change_type,
              status: 1
            };
            console.log(newOrder);

            MarketPlaceService.newOrder(newOrder).then(resp => {
              setTimeout(() => {
                window.history.replaceState(null, '', window.location.pathname);
                navigate('/marketplace/orders', { replace: true });
              }, 3000);
            });
          }
        } else {
          console.warn('No cart data found');
          setCart([]);
          setProducts([]);
        }
      }).catch((error) => {
        console.error('Error fetching cart:', error);
        setCart([]);
        setProducts([]);
      });
  }, [qty, checkout_id, order_id, payment_status, navigate]);

  let Layout = "";
  if (role == 4) {
    Layout = LayoutPage;
  } else if (role == 6) {
    Layout = SupplierLayout;
  } else if ([1, 3, 5, 2, 10].includes(parseInt(role))) {
    Layout = CPLayout;
  }

  return (
    <Layout>
      <AppBarMarketPlace/>

      <div style={{ marginBottom: '40px' }}>
        <Breadcrumb style={{ marginBottom: '20px' }}>
          <Breadcrumb.Item className='breadcrumb-item' onClick={() => navigate('/marketplace')}>
            Inicio
          </Breadcrumb.Item>
          <Breadcrumb.Item className='breadcrumb-item'>Carrito de compras</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <Typography style={{ fontWeight: 'bold', fontSize: '28px' }}>Carrito de compras</Typography>

      {/* 📌 Mostrar los datos del pago */}
      {checkout_id && order_id && payment_status && (
        <div style={{ marginBottom: '20px', padding: '10px', background: 'rgb(198, 241, 198)', border: '1px solid rgb(145, 255, 163)' }}>
          <Typography.Text strong>Información del pago:</Typography.Text>
          <p><strong>Checkout ID:</strong> {checkout_id}</p>
          <p><strong>Order ID:</strong> {order_id}</p>
          <p><strong>Estado del pago:</strong> {payment_status}</p>
        </div>
      )}

      <ShoppingList
        cart={cart}
        cartProducts={products}
        catalog={'catalogo'}
        category={'categoria'}
        qty={qty}
        setQty={setQty}
      />
    </Layout>
  );
}
