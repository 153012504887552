import React, { useState } from 'react';
import Layout from '../layouts/LayoutPage';
import '../scss/SolicitudSoftware.scss';
import { Link } from 'react-router-dom';
import { VSCategoriesData } from "../data/vs";
import { PlusOutlined, DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import { Row, Col, Modal, Result, Form, Typography, Input, DatePicker, Button, Divider, Upload, Select, message, InputNumber } from "antd";
import dayjs from "dayjs"; // Importar dayjs
import OrderService from '../utils/api/orders';
import ChatService from '../utils/api/chat';
const { Option } = Select;

export default function GeneralServices() {
	const user = JSON.parse(localStorage.getItem('user'))
	const userFcm = localStorage.getItem('fcm')

	const categories = VSCategoriesData

	const [loading, setLoading] = useState(false);
	const [numService, setNumService] = useState(1);
	const [services, setServices] = useState([{ title: '', description: '', file: null, fileName: '', quantity: 1  }]);
	const [formValue, setFormValue] = useState({
		title: "",
		time: "",
	});
	const [modal, setModal] = useState({
		open: false,
		type: '',
		title: '',
		description: '',
		req_id: '',
	});

	/** Verifica si todos los campos estan completos en el formulario para habilitar el boton */
	function isFormIncomplete() {
    if (!formValue.title || !formValue.time ) { return true; }

    for (let service of services) {
			console.log(service)
      if (!service.title || !service.description || !service.file || !service.cat || !service.quantity) { return true; }
    }

    return false;
	}

	/**  Logica para guardar los cambios de la orden*/
	const onChange = (index, targetInput) => {
		const updatedServices = [...services];
		updatedServices[index][targetInput.target.name] = targetInput.target.value;
		setServices(updatedServices);

		// Actualiza también el valor global del formulario si es necesario
		setFormValue((state) => ({
			...state,
			services: updatedServices,
		}));
	};

	/** Guarda la cantidad del servicio en el formulario */
	const onQuantityChange = (index, value) => {
		const updatedServices = [...services];
		updatedServices[index].quantity = value || 1;
		setServices(updatedServices);

		setFormValue((state) => ({
			...state,
			services: updatedServices,
		}));
	};

	/** Guarda la fecha de entrega esperada por el cliente y verifica que la fecha no sea menor o igual a la actual*/
	const onDateChange = (date, dateString) => {
    if (dayjs(date).isBefore(dayjs(), "day") || dayjs(date).isSame(dayjs(), "day")) {
        message.error("No puedes seleccionar la fecha actual o una pasada.");
        setFormValue((state) => ({ ...state, time: "" }));
    } else {
        setFormValue((state) => ({ ...state, time: dateString }));
    }
	};

	/** Deshabilitar fechas pasadas y la actual */
	const disabledDate = (current) => {
		return current && (current.isBefore(dayjs(), "day") || current.isSame(dayjs(), "day")) ;
	};

	/** Función para convertir archivo a base64 */
	const getBase64 = (file, callback) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => callback(reader.result);
		reader.onerror = (error) => console.log('Error: ', error);
	};

	/** Manejar la carga de archivos (solo permite un archivo por servicio) */
	const handleFileChange = (index, file) => {
		getBase64(file, (base64) => {
			const updatedServices = [...services];
			updatedServices[index].file = base64;
			updatedServices[index].fileName = file.name; // Guardar el nombre del archivo
			setServices(updatedServices);

			// Actualiza el valor global del formulario si es necesario
			setFormValue((state) => ({
				...state,
				services: updatedServices,
			}));
		});
	};

	/** Eliminar el archivo cargado */
	const removeFile = (index) => {
		const updatedServices = [...services];
		updatedServices[index].file = null;
		updatedServices[index].fileName = ''; // Eliminar el nombre del archivo
		setServices(updatedServices);

		// Actualiza el valor global del formulario si es necesario
		setFormValue((state) => ({
			...state,
			services: updatedServices,
		}));
	};

	/** Agrega un nuevo servicio */
	const addService = () => {
		setNumService(numService + 1);
		setServices([...services, { title: "", description: "", file: null, fileName: "", quantity: 1, cat: null }]);
	};

	/** Elimina un servicio solo si hay más de uno */
	const removeService = (index) => {
		if (services.length > 1) {
			const updatedServices = services.filter((_, i) => i !== index);
			setNumService(numService - 1);
			setServices(updatedServices);

			// Actualiza el valor global del formulario si es necesario
			setFormValue((state) => ({
				...state,
				services: updatedServices,
			}));
		}
	};

	/** Sube los datos para la llamada del api */
	const onFinish = () => {
		setLoading(true);
		console.log(formValue);
		console.log(user);
	
		let order = {
			client_id: user.id,
			client_name: user.name,
			client_bussiness: user.bussiness,
			client_fcm: userFcm,
			title: formValue.title,
			client_due_date: formValue.time,
			services: services,
		};
	
		console.log(order);
	
		OrderService.NewOrder(order)
			.then(response => {
				if (response.data.success === true) {
					const order_id = response.data.order_id;
	
					// ✅ Preparar los datos para la creación de chats
					const chatData = {
						clientId: user.id,
						services: services.map(service => ({
							category: service.cat
						})),
					};
	
					// ✅ Crear los chats
					ChatService.NewOrder(order_id, chatData)
						.then(chatResponse => {
							console.log('✅ Chats creados correctamente:', chatResponse.data);
	
							// ✅ Mostrar el modal solo si todo salió bien (orden y chats)
							setModal({
								open: true,
								type: 'success',
								title: '¡Solicitud enviada con éxito!',
								description:
									'Tu número de orden es: ' +
									order_id +
									', podrás ver tu pedido en el apartado de ORDENES.',
								req_id: order_id,
							});
	
							setFormValue({
								title: '',
								description: '',
								time: '',
								fileList: [],
							});
							setServices([
								{ title: '', description: '', file: null, fileName: '', quantity: 1, category: '' },
							]);
							setLoading(false);
						})
						.catch(err => {
							console.error('❌ Error al crear los chats:', err);
							setModal({
								open: true,
								type: 'error',
								description: 'No se pudieron crear los chats. Intenta nuevamente.',
								title: 'Error al crear los chats',
								req_id: order_id,
							});
							setLoading(false);
						});
	
				} else {
					setLoading(false);
				}
			})
			.catch(error => {
				setModal({
					open: true,
					type: 'error',
					description: 'Revisa tus datos e inténtalo de nuevo',
					title: 'Algo salió mal',
					req_id: error,
				});
				setLoading(false);
			});
	};	
	
	/** Cancela las ordenes */
	const onCancel = (target) => {
		setModal((state) => ({
			...modal,
			open: false,
		}));
		setLoading(false);
	}

	return (
		<>
			<Layout>
				<Row>
					<Col xs={24}>
					{/* Main Form for OrderDetails */}
					<h1>Nueva orden de compra</h1>
					<Row gutter={12}>
						<Col xs={24} xl={14} style={{ marginLeft: "auto", marginRight: "auto", marginTop: 16 }}>

							<Row>
								<Col xs={24}>
									<Form.Item label="Título de la orden" rules={[{ required: true, message: "Favor de ingresar un título para su proyecto", }]}>
										<Input name="title" onChange={(e) => setFormValue((state) => ({ ...state, title: e.target.value }))} />
									</Form.Item>
								</Col>
							</Row>
							
							<Row gutter={[12, 12]}>
								<Col xs={24} md={18}>
									<Form.Item label="¿Cuándo espera recibir el servicio?">
										<DatePicker
											style={{ width: "100%" }}
											placeholder="Seleccionar fecha"
											className="login-input"
											onChange={onDateChange}
											disabledDate={disabledDate} />
									</Form.Item>
								</Col>
							</Row>

					{/* Dinámicamente agregamos los servicios */}
					<Divider>Servicios</Divider>
					{services.map((service, index) => (
						<Row key={index} gutter={16}>
							<Col xs={24} md={22}>
								<Typography style={{fontWeight: 'bold', textAlign: 'start'}}>Servicio {index + 1}</Typography>
								<Form.Item label="Título del servicio" rules={[{ required: true, message: "Favor de ingresar un título para el servicio", }]}>
									<Input name="title" value={service.title} onChange={(e) => onChange(index, e)} />
								</Form.Item>
								<Form.Item label="Categoría" name="categories" rules={[{ required: true, message: 'Debes elegir al menos una categoría' }]}>
								<Select
									mode="multiple"
									allowClear
									onChange={(value) => {
										const updatedServices = [...services];
										updatedServices[index].cat = value[0]; // Usa el nombre correcto de la propiedad
										setServices(updatedServices);

										setFormValue((state) => ({
											...state,
											services: updatedServices,
										}));
									}}
									value={service.categoria} // Asegúrate de que esta propiedad exista en tu objeto service
									placeholder="Seleccionar la(s) categoría(s)"
									maxCount={1}
									maxTagCount={1}
								>

										{categories.map((category, index) => (
											<Option key={index} value={category.key}>{category.name}</Option>
										))} 
									</Select>
								</Form.Item>
								<Form.Item label="Descripción de lo deseado" rules={[{ required: true, message: "Favor de ingresar una pequeña descripción", }]}>
									<Input.TextArea name="description" value={service.description} onChange={(e) => onChange(index, e)} />
								</Form.Item>
								<Form.Item label="Cantidad">
									<InputNumber min={1} value={service.quantity} defaultValue={service.quantity} onChange={(value) => onQuantityChange(index, value)} style={{ width: "100%" }} />
								</Form.Item>

								{/* Componente Upload para subir archivos */}
								<Form.Item label="Subir archivo">
									{!service.file ? (
										<Upload
											beforeUpload={(file) => {
												handleFileChange(index, file);
												// Return false to prevent automatic upload
												return false;
											}}
											showUploadList={false}
										>
											<Button icon={<UploadOutlined />}>Seleccionar archivo</Button>
										</Upload>
									) : (
										<div>
											<Typography.Text>{service.fileName}</Typography.Text>
											<Button type="link" onClick={() => removeFile(index)}>
												Eliminar archivo
											</Button>
										</div>
									)}
								</Form.Item>
							</Col>
							<Col xs={24} md={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
								{/* Botón de eliminar servicio circular */}
								{index !== 0 && (
									<Button
										shape="circle"
										type="danger"
										icon={<DeleteOutlined style={{ color: 'white' }} />}
										onClick={() => removeService(index)}
										disabled={services.length === 1} // Deshabilitar si solo hay un servicio
										style={{ backgroundColor: 'red', border: 'none' }}
									/>
								)}
							</Col>
						</Row>
					))}

						<Row style={{ marginBottom: '16px' }} gutter={[12, 12]} justify="center" align="vertical">
							<Col md={12} xs={24}>
								<Button
									type="primary"
									icon={<PlusOutlined />}
									onClick={addService}
									style={{
										marginTop: '16px',
										backgroundColor: '#1890ff',
										borderColor: '#1890ff',
									}}
								>
									Agregar servicio
								</Button>
							</Col>
						</Row>
				</Col>
			</Row>
						<Button
							type='primary'
							onClick={onFinish}
							loading={loading}
							disabled={isFormIncomplete()}
						>
							Subir y Finalizar
						</Button>
					</Col>
				</Row>
			</Layout>
			<Modal
				title='Notificación de solicitud'
				closable={true}
				open={modal.open}
				onCancel={onCancel}
				footer={[
					<Button key='submit' type='primary'>
						<Link to={`/orders/all/1`}>Aceptar</Link>
					</Button>,
				]}
			>
				<Result
					title={modal.title}
					status={modal.type}
					subTitle={modal.description}
				/>
			</Modal>
		</>
	);
}
