import request from "../services/HTTPClient";
import { requestMethods, API } from "../constants";

function NewOrder(order_id, data) {
  return request({
    url: API.CHAT.NEW_ORDER.concat(order_id),
    method: requestMethods.POST,
    data: data
  });
}

function NewQuote(service_id, data) {
  return request({
    url: API.CHAT.NEW_QUOTE.concat(service_id),
    method: requestMethods.POST,
    data: data
  });
}

function Disable(service_id, data) {
  return request({
    url: API.CHAT.DESABLE_CHAT.concat(service_id + '/disable'),
    method: requestMethods.PUT,
    data: data
  });
}

function GetByUser(userId, params = {}) {
  const query = new URLSearchParams(params).toString();
  return request({
    url: `${API.CHAT.GET_BY_USER}${userId}?${query}`,
    method: requestMethods.GET,
  });
}

function GetAll() {
  return request({
    url: API.CHAT.GET_ALL,
    method: requestMethods.GET,
  });
}

function Messages(data) {
  return request({
    url: API.CHAT.MESSAGE,
    method: requestMethods.POST,
    data: data
  });
}

function GetMessagesChat(chat_id) {
  return request({
    url: API.CHAT.GET_MESSAGES_BY_CHAT.concat(chat_id),
    method: requestMethods.GET,
  });
}


const ChatService = { 
	NewOrder,
  NewQuote,
  Disable,
  GetByUser,
  GetAll,
  Messages,
  GetMessagesChat
 };

export default ChatService;
