const clientStatusLogs = [
  {
    status: 1,
    text: 'Nuestro equipo esta verificando que la orden sea valida para su elaboracion'
  },
  {
    status: 2,
    text: 'Tu orden se encuentra en cotizacion'
  },
  {
    status: 3,
    text: 'Verifica que la cotizacion enviada se adecue a tus necesesidades.',
  },
  {
    status: 4,
    text: 'Tu orden de compra se encuentra en elaboracion.'
  },
  {
    status: 5,
    text: 'Estamos inspeccionando tu pedido para aseguranos que sea lo que solicitaste'
  },
  {
    status: 6,
    text: 'Tu pedido se encuentra en camino o listo para recoger. Por favor confirmanos cuando lo tengas en tu posesion.',
  },
  {
    status: 7,
    text: 'Gracias por confiar en EPNO! Cualquier duda o aclaracion, comunicate con nuestro equipo'
  },
  {
    status: 8,
    text: "Tu orden de compra ha sido rechazada por: "
  },
  {
    status: 9,
    text: "Tu orden de compra ha sido cancelada debido a: "
  },
  {
    status: 11,
    text: "Tu solicitud de cancelacion ha sido enviada. Motivo: "
  }
]


const adminStatusLogs = [
  {
    status: 1,
    text: 'El vendedor se encuentra verificando que lo datos de la orden sean correctos.',
  },
  {
    status: 2,
    text: 'Compras se encuentra cotizando la orden con diferentes proveedores.',
  },
  {
    status: 3,
    text: 'El cliente se encuentra seleccionando la cotizacion que se adecue mas a su necesidad.',
  },
  {
    status: 4,
    text: 'El proveedor se encuentra elaborando la orden',
  },
  {
    status: 5,
    text: 'Ventas esta verificando la orden realizada por el proveedor.',
  },
  {
    status: 6,
    text: 'La orden terminada se encuentra en camino o listo para recoger. Espera a que el cliente confirme que el pedido ya ha sido entregado.',
  },
  {
    status: 7,
    text: 'La orden a concluido.'
  },
  {
    status: 8,
    text: "La orden de compra ha sido rechazada debido a: "
  },
  {
    status: 9,
    text: "La orden de compra fue cancelada debido a: "
  },
  {
    status: 11,
    text: "El cliente ha solicitada una cancelacion. Motivo: "
  }
]

const vendorStatusLogs = [
  {
    status: 1, 
    text: "Verifica que el cliente haya subido correctamente los datos de cotizacion. Si fue asi, envia a cotizacion el servicio." + 
          " Si no fue asi, devuelve la orden de compra al cliente para que la pueda editar"
  },
  {
    status: 2,
    text: "El comprador se encuentra cotizando la orden de compra con diferentes proveedores. Mantengan contacto para llegar a" +
          " un acuerdo exitoso con el cliente."
  },
  {
    status: 3,
    text: "El cliente se encuentra eligiendo la cotizacion que se ajuste mas a sus necesidades. Favor de mantener contacto con el cliente " +
          "en dado caso de requerir una recotizacion."
  },
  {
    status: 4,
    text: "La cotizacion ha sido aceptada. El proveedor se encuentra elaborando la orden."
  },
  {
    status: 5,
    text: "El proveedor ha terminado la orden. Favor de inspeccionar la orden para confirmar que cumpla con los estandares de calidad."
  },
  {
    status: 6,
    text: "La orden se encuentra en proceso de ser entregada. El cliente debe de confirmar de que el producto sea entregado. " +
          "Para finalizar la entrega, favor de subir la factura sellada y firmada."
  },
  {
    status: 7,
    text: "La orden de compra ha finalizado. En caso de recibir un reclamo, contactese con el cliente para asistirlo."
  },
  {
    status: 8,
    text: "Rechazaste la orden de compra debido a: "
  },
  {
    status: 9,
    text: "La orden de compra fue cancelada debido a: "
  },
  {
    status: 11,
    text: "El cliente ha solicitado una cancelacion: Motivo: "
  }
]

const buyerStatusLogs = [
  {
    status: 2, 
    text: "Cotiza con diferentes provedores la orden de compra. Puedes agregar una orden de compra externa en el boton de " +
          " agregar cotizacion externa."
  },
  {
    status: 3,
    text: "Espera a que el cliente eliga la cotizacion que se ajuste a su necesidad. Espera la orden de compra del cliente para " +
          "subir la orden de compra para el proveedor. Si el cliente no sube la orden de compra, puedes solicitarla y subirla por el. " + 
          "En dado caso de no aceptar ninguna, solicita una recotizacion."
  },
  {
    status: 4,
    text: "La cotizacion ha sido aceptada. El proveedor se encuentra elaborando la orden de compra."
  },
  {
    status: 5, 
    text: "Ventas se encuentra verificando que la orden cumpla con los estandares de calidad."
  },
  {
    status: 6,
    text: "La orden se encuentra en proceso de ser entregada. El cliente y ventas deben de confirmar que la orden ha sido finalizada." 
  },
  {
    status: 7,
    text: "La orden de compra ha finalizado. En dado caso de un reclamo, notificar al encargado de la venta."
  },
  {
    status: 8,
    text: "La orden de compra ha sido rechazada debido a: "
  },
  {
    status: 9,
    text: "La orden de compra ha sido cancelada deibido a: "
  },
  {
    status: 11,
    text: "El cliente ha solicitado una cacelacion. Motivo: "
  }
]

const supplierStatusLogs = [
  {
    status: 2,
    text: 'Envia tu cotizacion con los datos requeridos.',
  },
  {
    status: 3, 
    text: 'El cliente se encuentra seleccionando la cotizacion.',
  },
  {
    status: 4, 
    text: 'Felicidades, tu cotizacion ha sido aceptada! Elabora el servicio solicitado. Una vez terminado, actualiza el estatus para que EPNO inspeccione el producto.',
  },
  {
    status: 5, 
    text: 'EPNO se encuentra inspeccionando el servicio.',
  },
  {
    status: 6, 
    text: 'Puedes entregar al servicio a tu cliente.',
  },
  {
    status: 7,
    text: 'El servicio a concluido. Cualquier duda o aclaracion, contacta a EPNO.'
  },
  {
    status: 9,
    text: 'La orden de compra ha sido cancelada debido a: '
  },
  {
    status: 11,
    text: "El cliente ha solicitado una cancelacion. Motivo: "
  }
]

export const OrderLogs = {
  clientStatusLogs,
  adminStatusLogs,
  supplierStatusLogs,
  vendorStatusLogs, 
  buyerStatusLogs
}