import React, { useState, useEffect } from "react";
import {
  Layout,
  Menu,
  Button,
  Badge,
  Dropdown,
  notification,
  Row,
  Col,
  Empty,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import {
  UsergroupAddOutlined,
  InboxOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  SwapOutlined,
  ShopOutlined,
  NotificationTwoTone,
  BellOutlined,
} from "@ant-design/icons";
import '@ant-design/v5-patch-for-react-19';
import NotificationService from "../../utils/api/notifications";
import useVisibilityChange from "../../utils/hooks/useVisibilityChange";
import { setupNotifications } from "../../utils/services/firebase";
import {
  sendNativeNotification,
  toastNotification,
} from "../../actions/notificationHelpers";

const { Header, Sider, Content, Footer } = Layout;

export default function CPLayout(props) {
  const { children } = props;
  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem("user"));
  let role = localStorage.getItem("role");

  const [isNotificationEnabled, setNotificationEnabled] = useState(
    Notification.permission === "granted"
  );
  const [notifications, setNotifications] = useState({
    notify: [],
    total: 0,
    user_id: "",
  });
  const isForeground = useVisibilityChange();

  useEffect(() => {
    NotificationService.GetNotifications(user.id).then((resp) => {
      setNotifications({
        notify: resp.data || [],
        total: resp.data.length || 0,
        user_id: user.id,
      });
    });

    setupNotifications((message) => {
      if (isForeground) {
        toastNotification({
          title: message.data.title || "Notification",
          description: message.data.body || "You have a new notification",
          status: "info",
        });
      } else {
        sendNativeNotification({
          title: message.data.title || "Notification",
          body: message.data.body || "You have a new notification",
        });
      }
    });
  }, [isForeground]);

  const handleEnableNotifications = () => {
    if (Notification.permission === "denied") {
      notification.error({
        message: "Notificaciones bloqueadas",
        description:
          "Por favor, habilítalas en la configuración del navegador.",
        placement: "topRight",
      });
      return;
    }

    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        setNotificationEnabled(true);
        notification.success({
          message: "Notificaciones habilitadas",
          description: "Las notificaciones han sido habilitadas exitosamente.",
          placement: "topRight",
        });
      }
    });
  };

  const handleNav = (route) => {
    navigate(route);
  };

  const logout = () => {
    navigate("/");
    localStorage.clear();
  };

  // Refactorización de notificaciones usando `items`
  const allNotifications = {
    key: "notificaciones",
    label: "Notificaciones",
    type: "group",
    children:
      notifications.total === 0
        ? [
            {
              key: "empty",
              label: (
                <Empty
                  description="Sin notificaciones"
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              ),
            },
          ]
        : notifications.notify.map((ntf) => ({
            key: ntf.id.toString(),
            label: (
              <a onClick={() => {}}>
                {ntf.title} - {ntf.description}
              </a>
            ),
            icon:
              ntf.seen === 0 ? (
                <EyeInvisibleOutlined style={{ fontSize: 20 }} />
              ) : (
                <EyeOutlined style={{ fontSize: 20 }} />
              ),
            style: {
              backgroundColor: ntf.seen === 0 ? "#F7F7F7" : "",
              fontWeight: ntf.seen === 0 ? "bold" : "normal",
            },
          })),
  };

  // Menú principal refactorizado con `items`
  const menuItems = [
    ...(role == 1
      ? [
          {
            key: "sub1",
            icon: <UsergroupAddOutlined />,
            label: "Usuarios",
            children: [
              { key: "2", label: <Link to="/@u">Lista de Usuarios</Link> },
              { key: "21", label: <Link to="/@a-@u">Agregar</Link> },
            ],
          },
          {
            key: "sub2",
            icon: <ShopOutlined />,
            label: "Marketplace",
            children: [
              {
                key: "30",
                label: (
                  <Link to={`/marketplace/`}>
                    Inicio
                  </Link>
                ),
              },
              {
                key: "31",
                label: (
                  <Link to={`/marketplace/${user.id}/orders`}>
                    Órdenes de compra
                  </Link>
                ),
              },
              {
                key: "32",
                label: <Link to="/marketplace/suppliers">Proveedores</Link>,
              },
              {
                key: "33",
                label: (
                  <Link to={`/marketplace/${user.id}/analytics`}>
                    Análiticas
                  </Link>
                ),
              },
            ],
          },
        ]
      : []),
    {
      key: "6",
      icon: <InboxOutlined />,
      label: "Órdenes",
      onClick: () => handleNav("/orders/all/1"),
    },
  ];

  const menuNavbar = [
    role == 1 && { key: "1", label: <Link to={"/dashboard"}>Inicio</Link> },
    {
      key: "17",
      label: (
        <Dropdown
          menu={{
            items: [
              {
                key: "notificaciones",
                label: "Notificaciones",
                type: "group",
                children:
                  notifications.total === 0
                    ? [
                        {
                          key: "empty",
                          label: (
                            <Empty
                              description="Sin notificaciones"
                              image={Empty.PRESENTED_IMAGE_SIMPLE}
                            />
                          ),
                        },
                      ]
                    : notifications.notify.map((ntf) => ({
                        key: ntf.id.toString(),
                        label: (
                          <a onClick={() => {}}>
                            {ntf.title} - {ntf.description}
                          </a>
                        ),
                        icon:
                          ntf.seen === 0 ? (
                            <EyeInvisibleOutlined style={{ fontSize: 20 }} />
                          ) : (
                            <EyeOutlined style={{ fontSize: 20 }} />
                          ),
                        style: {
                          backgroundColor: ntf.seen === 0 ? "#F7F7F7" : "",
                          fontWeight: ntf.seen === 0 ? "bold" : "normal",
                        },
                      })),
              },
            ],
          }}
          placement="bottom"
        >
          <Badge
            count={notifications.total}
            overflowCount={999}
            style={{ backgroundColor: "#95de64" }}
          >
            <NotificationTwoTone twoToneColor="#ff4d4f" />
          </Badge>
        </Dropdown>
      ),
    },
    {
      key: "19",
      label: (
        <Button
          onClick={handleEnableNotifications}
          type="text"
          icon={
            <BellOutlined
              style={{ color: !isNotificationEnabled ? "red" : "green" }}
            />
          }
        />
      ),
    },
    {
      key: "21",
      label: (
        <Button onClick={logout} type="text">
          Salir
        </Button>
      ),
    },
  ];

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        trigger={<SwapOutlined />}
        collapsible
        breakpoint="lg"
        collapsedWidth="0"
      >
        <div className="logo" />
        <Menu theme="dark" mode="inline" items={menuItems} />
      </Sider>
      <Layout>
        <Header className="header">
          <Row>
            <Col sm={6} md={23}>
              <Menu
                mode="horizontal"
                className="site-layout-sub-header-background"
                items={menuNavbar}
              />
            </Col>
          </Row>
        </Header>
        <Content className="site-layout-background" style={{ margin: "24px 16px", padding: 24, minHeight: 280 }}>
          {children}
        </Content>
        <Footer style={{ textAlign: "center" }}>
          Copyright ©2020 Created by EP&O
        </Footer>
      </Layout>
    </Layout>
  );
}
