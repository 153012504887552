import request from "../services/HTTPClient";
import { requestMethods, API } from "../constants";


function Sales() {
  return request({
    url: API.ANALITICS.SALES,
    method: requestMethods.GET,
  });
}

function SalesPerMonth() {
  return request({
    url: API.ANALITICS.SALES_PER_MONTH,
    method: requestMethods.GET,
  });
}

function ProjectsSales(){
  return request({
    url: API.ANALITICS.PROJECT_SALES,
    method: requestMethods.GET,
  });
}

const AnaliticsService = {
  Sales,
  SalesPerMonth,
  ProjectsSales
};

export default AnaliticsService;
