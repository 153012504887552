import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Button, Card, List, Typography, Row, Col, Tag, Collapse, Avatar, DatePicker, message } from 'antd';
import LayoutPage from '../../../layouts/LayoutPage';
import SupplierLayout from "../../../layouts/SupplierLayout";
import CPLayout from "../../../layouts/ControlPanelLayout";

// Services
import MarketPlaceService from '../../../utils/api/marketplace';
import UserService from '../../../utils/api/users';
import MarketplaceStatus from '../MarketplaceStatus';
 
const { Title, Text } = Typography;
const { Panel } = Collapse;

const OrdersDetails = () => {
  const role = Number(localStorage.getItem("role"));
  const { id } = useParams();
  const [order, setOrder] = useState([]);
  const [orderLogs, setOrderLogs] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [deliveryDate, setDeliveryDate] = useState(null); // 📌 Estado para la fecha de entrega

  let Layout = role === 4 ? LayoutPage : role === 6 ? SupplierLayout : CPLayout;

  useEffect(() => {
    const fetchOrderAndSuppliers = async () => {
      try {
        const orderResponse = await MarketPlaceService.getOrderById(id);
        const orderData = orderResponse.data;
        setOrder(orderData);
        
        const orderLogsResponse = await MarketPlaceService.getLogsByOrder(id);
        setOrderLogs(orderLogsResponse.data);

        const uniqueSuppliers = [];
        const suppliersSet = new Set();
  
        for (const product of orderData.products) {
          if (!suppliersSet.has(product.supplier_id)) {
            const supplierResponse = await UserService.GetUserById({ id: product.supplier_id });
            uniqueSuppliers.push(supplierResponse.data);
            suppliersSet.add(product.supplier_id);
          }
        }
        setSuppliers(uniqueSuppliers);
      } catch (error) {
        console.error("Error fetching order or suppliers:", error);
      }
    };

    fetchOrderAndSuppliers();

    return () => {
      setOrderLogs([]);
      setOrder([]);
      setSuppliers([]);
    };
  }, [id]);

  const updateOrderStatus = (status) => {
    if (!deliveryDate) {
      message.warning("Selecciona una fecha de entrega antes de cancelar la orden.");
      return;
    }

    const body = { status };
    MarketPlaceService.updateOrder(body, order.id).then((resp) => {
      console.log(resp);
    });
  };

  const handleDateChange = (date) => {
    const today = new Date().setHours(0, 0, 0, 0);
    const selectedDate = date ? date.toDate().setHours(0, 0, 0, 0) : null;

    if (selectedDate && selectedDate <= today) {
      message.error("La fecha de entrega debe ser mayor a la fecha actual.");
      setDeliveryDate(null);
    } else {
      setDeliveryDate(date);
    }
  };

  return (
    <Layout>
      <Row gutter={[16, 16]}>
        {/* Left Column */}
        <Col xs={24} md={16}>
          {/* Title and Status */}
          <Card style={{ marginBottom: "16px", backgroundColor: "#fff" }}>
            <Row justify="space-between" align="middle">
              <Col>
                <Title level={4}>Referencia: {order.id}</Title>
              </Col>
              <Col>
                {(role === 1 || role === 3 || role === 5) && (
                  <Button 
                    onClick={() => updateOrderStatus(4)} 
                    style={{ marginRight: '16px' }} 
                    disabled={!deliveryDate} 
                  >
                    Cancelar orden
                  </Button>
                )}
                {role === 6 && order.status === 1 && (
                  <Button disabled={!deliveryDate}  onClick={() => updateOrderStatus(2)} style={{ marginRight: '16px' }}>
                    Enviar orden
                  </Button>
                )}
                {role === 4 && order.status === 2 && (
                  <Button onClick={() => updateOrderStatus(3)} style={{ marginRight: '16px' }}>
                    Confirmar entrega
                  </Button>
                )}
              </Col>
            </Row>
            <Text>{order.updated_at} - </Text> <MarketplaceStatus status={1} />
            <br />
            
            <Text>Tu compra se encuentra en proceso</Text>
          </Card>

          {/* Service Details */}
          <Card title="Detalle de la compra" style={{ marginTop: 16, backgroundColor: "#F4F6F6", marginBottom: "16px" }}>
            <List>
              <List.Item>
                <Collapse bordered={false} style={{ width: "100%", backgroundColor: "#F4F6F6" }}>
                  <Panel header="Productos en la orden" key="1" style={{ width: "100%", padding: 0 }}>
                    <List
                      dataSource={order.products}
                      renderItem={(product) => (
                        <List.Item key={product.id} style={{ padding: "8px 0", display: "flex", alignItems: "center" }}>
                          <Avatar src={product.imgs[0]} alt={product.name} size={64} style={{ marginRight: "16px", borderRadius: '8px' }} />
                          <div style={{ flex: 1 }}>
                            <Text strong>{product.name}</Text>
                            <br />
                            <Text>Cantidad: {product.quantity} u.</Text>
                            <br />
                            <Text>Proveedor: {suppliers.find(s => s.id === product.supplier_id)?.name || 'No disponible'}</Text>
                          </div>
                          <Text strong style={{ marginLeft: "auto" }}>
                            {product.price} $ {order.change_type}
                          </Text>
                        </List.Item>
                      )}
                    />
                  </Panel>
                </Collapse>
              </List.Item>
              <List.Item>
                <Text>Envío</Text>
                <Text>Gratis</Text>
              </List.Item>
              <List.Item>
                <Text strong>Total</Text>
                <Text strong>{order.price} $ {order.change_type}</Text>
              </List.Item>
              <List.Item>
                <Row style={{ marginTop: "16px" }} gutter={[16, 16]}>
                  <Col span={12}>
                    <Text strong style={{ display: "block", marginBottom: "8px" }}>Fecha de entrega esperada:</Text>
                    <Text>30/10/2024</Text>
                  </Col>
                  <Col span={12}>
                    <Text strong style={{ display: "block", marginBottom: "8px" }}>Fecha de entrega:</Text>
                    {role !== 6 
                      ? <p>El proveedor esta confirmando la fecha de entrega.</p> 
                      : <DatePicker onChange={handleDateChange} />}
                  </Col>
                </Row>
              </List.Item>
            </List>
          </Card>
        </Col>

        {/* Right Column */}
        <Col xs={24} md={8}>
          {/* Order Tracking */}
          <Card style={{ backgroundColor: "#F4F6F6", borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
            <Title level={5}>Seguimiento de la orden</Title>
          </Card>
          <Card style={{ display: "flex", alignItems: "center", justifyContent: 'center', marginBottom: "16px", backgroundColor: "#fff", borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
            {orderLogs.map((log, index) => (
              <p key={index}>
                {log.updatedAt} - <MarketplaceStatus status={log.status} />
              </p>
            ))}
          </Card>
        </Col>
      </Row>
    </Layout>
  );
};

export default OrdersDetails;
