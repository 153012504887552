import React from "react";
import { Card, Button, Input } from "antd";
import { useNavigate} from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import ConektaService from "../../../utils/api/conekta";

const PurchaseSummaryCard = ({ productsCount, subtotal, freeShipping, checkout, products }) => {
  const total = subtotal;
  let history = useNavigate();
  const isSmallScreen = useMediaQuery({ query: "(max-width: 768px)" });
  const user = JSON.parse(localStorage.getItem('user'))


  const purchaseItems = () => {
    const body = {
      name: user.name,
      email: user.email,
      phone: user.phone,
      total: parseFloat(total.toFixed(2)),
      description: "EPNO",
      success_url: "http://localhost:3000/#/marketplace/shopping-cart",
      failure_url: "http://localhost:3000/#/marketplace/shopping-cart",
      line_items: products.map(product => ({
        name: product.name,
        unit_price: Math.round(product.price) * 100,
        quantity: product.quantity
      }))
    };

    ConektaService.requestPayment(body).then(resp => {
      window.open(resp.data.checkout_url, "_blank");
    })

    console.log(body);
};


  return (
    <Card bordered={true} style={{ width: "100%"}}>
      {isSmallScreen ? null : <h3 style={{ marginBottom: "16px" }}>Resumen de compra</h3>}
      
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "4px" }}>
        <span>Productos ({productsCount})</span>
        <span>${subtotal.toFixed(2)}</span>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "4px" }}>
        <span>Envíos ({productsCount})</span>
        <span style={{ color: freeShipping ? "green" : "inherit" }}>
          {freeShipping ? "Gratis" : `$${(subtotal * 0.1).toFixed(2)}`}
        </span>
      </div>
      {/*
      <div style={{ marginBottom: "16px", textAlign: "center" }}>
        <Input
          placeholder="Ingresar código de cupón"
          style={{ textAlign: "center", fontSize: "14px" }}
        />
      </div>
      */}
      
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "16px",
          fontWeight: "bold",
          fontSize: "16px",
        }}
      >
        <span>Total</span>
        <span>${total.toFixed(2)}</span>
      </div>
      {!checkout && <Button type="primary" block style={{ backgroundColor: "#007bff", borderColor: "#007bff" }} onClick={() => purchaseItems()}>
        Continuar compra
      </Button>}
    </Card>
  );
};

export default PurchaseSummaryCard;
