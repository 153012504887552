export const BASE_URL = 'https://us-central1-app-epno.cloudfunctions.net/app/api/';
//export const BASE_URL = "http://localhost:5000/app-epno/us-central1/app/api/";

//export const BASE_URL = "https://us-central1-app-epno-testing.cloudfunctions.net/app/api/";
//export const BASE_URL = "http://localhost:5000/app-epno-testing/us-central1/app/api/";

//export const BASE_URL = 'https://dev.gw.client.epno.app';

export const FIREBASE_URL =
  "https://us-central1-app-epno.cloudfunctions.net/app/api/";

export const requestMethods = {
  GET: "GET",
  POST: "POST",
  HEAD: "HEAD",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE",
  CONNECT: "CONNECT",
};

export const API = {
  requestMethods: {
    GET: "GET",
    POST: "POST",
    HEAD: "HEAD",
    PUT: "PUT",
    PATCH: "PATCH",
    DELETE: "DELETE",
    CONNECT: "CONNECT",
  },
  CONEKTA: {
    REQUEST_PAYMENT:    "payment/new"
  },
  ANALITICS: {
    SALES: "analitics/sales",
    SALES_PER_MONTH:  "analitics/sales-by-category",
    PROJECT_SALES:    "analitics/services-sales"
  },
  AUTH: {
    LOGIN_EPNO: "auth/login",
    LOGIN: "/api/self-service/sign-in",
    REGISTER: "auth/register",
    CHECK_SESSION: "/check-session",
    PASSWORD_RESET: "/password/reset",
    PASSWORD_EMAIL: "/password/email",

    // EPNO ------------------------------------------------------------
    REGISTER_ADMIN: "auth/registerAdmin",
  },
  ORDERS: {
    OPEN:               '/api/self-service/get_all_orders/1',
    ADD_REQUEST:        '/api/self-service/add_request',
    DETAILS:            '/api/self-service/get_order/',

    NEW:                'orders/new/3',
    GET_BY_USER:        'orders/user/',
    GET_BY_ID:          'order/',
    GET_BY_CATEGORY:    'orders/by-category?cat=',
    ALL:                'orders/all',
    UPDATE:             'orders/update/',

    APPLY: {
        NEW:            'order/apply',
        UPDATE:         'order-proposals/',
        DELETE:         'order-proposals/',
        GET_ALL:        'order-proposals',
        GET_BY_ORDER:   'order-proposals/order/',
        GET_BY_SUP:     'order-proposals/supplier/:supplier_id'
    },

    LOGS: {
      GET_BY_ORDER_ID:    "order-logs/"
    },

  },
  SERVICES: {
    UPDATE: 'services/update/',

    QUOTES: {
      APPLY: 'service/apply',
      NEW_INTERNAL: 'service/internal-apply',
      UPDATE: 'service/proposal/',
      GET_BY_ORDER: 'service/proposals/',
      CHOOSE_PROPOSAL: '/service/proposal/select/'
    },

    REVIEWS: {
      ADD: 'services/review/',
      UPDATE: 'services/review/',
      DELETE: 'services/review/',
      REPLY: 'services/review/reply/',
      GET: 'services/reviews/'
    },

    COMPLAINTS: {
      NEW: 'services/complaint',
      UPDATE: 'services/complaint/',
      DELETE: 'services/complaint/',
      REPLY: '/services/complaint/',
      GET: '/services/complaints/',
      SOLVE: '/services/complaint/'
    }
  },
  USER: {
    SUPPLIER: {
      GET_ALL: "users/suppliers",
    },
    CHANGE_USER: "/api/self-service/change_user_vs",
    NEW_USER: "/api/self-service/create_new_user",
    NEW_USER_REQUEST: "/api/self-service/response_new_user_request",
    GET_USER_REQUEST: "/api/self-service/get_new_user_request",
    UPDOWN_USER: "/api/self-service/updown_user",
    USERS: "/api/self-service/get_all_users",
    ROLE: "/api/self-service/user_role",
    SOLICITUD_SOFTWARE: "/api/self-service/solicitudSoftware",

    // EPNO --------------------------------------------------------------
    GET_USER_BY_ID: "users/getById",
    ALL: "users/",
    UPDATE_STATUS: "users/updateStatus",
    SAVE_TOKEN: "users/save-token",
    CHANGE_LOGO: "users/change-logo",
    GET_VS:       "users/vs"
  },
  NOTIFICATIONS: {
    CHANGE_STATUS: "/api/self-service/change_notification_status",
    MARK_AS_READ: "/api/self-service/marcar_como_leido",
    GET_NOTIFICATION: "/api/self-service/get_notifications/1",
    GET_NOTIFICATIONS: "notifications/",
  },
  CHAT: {
    NEW_ORDER: 'chats/order/',
    NEW_QUOTE: 'chats/service/',
    DESABLE_CHAT: 'chats/service/',
    GET_BY_USER: 'chats/user/',
    GET_ALL: 'chats/admin/all',
    MESSAGE: 'messages/',
    GET_MESSAGES_BY_CHAT: 'messages/'
  },
  CUSTOMER: {
    ADD: "/api/self-service/perfilCustomer",
    CLIENT_CONSUME: "/api/self-service/gastos_perfil",
    PROFILE_CONSUME: "/api/self-service/gastos_perfilAdmin_client",
    CONSUME: "/api/self-service/consumo_cliente",
    EARNINGS: "/api/self-service/ganancias_client",
    EARNINGS_SUMMARY: "/api/self-service/ganancias_resumen_client",
  },
  VALUESTREAM: {
    VALUESTREAMS: "/api/self-service/get_vs",
  },
  MARKETPLACE: {
    PRODUCTS: {
      NEW:                "product/new",
      UPDATE:             "product/update/",
      DELETE:             "product/delete/",
      GET_ALL:            "products",
      GET_BY_ID:          "product/",
      GET_BY_CATEGORY:    "products/category/",
      GET_BY_SUPPLIER:    "products/supplier/",
    },
    CART: {
      ADD_PRODUCT:        "cart/add-product",
      UPDATE:             "cart/",
      DELETE:             "cart/",
      DELETE_PRODUCT:     "cart/product/",
      GET_BY_ID:          "cart/",
      GET_BY_CLIENT:      "cart/client/",
      GET_ALL:            "cart",
    },
    ORDERS: {
      NEW:              "marketplace/orders/new",
      DELETE:           "marketplace/orders/",
      UPDATE:           "marketplace/orders/",
      GET_BY_ID:        "marketplace/orders/",
      GET_BY_CLIENT:    "marketplace/orders/client/",
      GET_ALL:          "marketplace/orders",
      GET_BY_SUPPLIER:  "marketplace/orders/supplier/",
    },
    LOGS: {
      GET_ALL:        "marketplace/logs",
      GET_BY_ORDER:   "marketplace/logs/",
    },
    VENDOR: {
      NEW:              "marketplace/vendor",
      UPDATE:           "marketplace/vendor/",
      ARCHIVE:          "marketplace/vendor/archive/", //:user_id
      UNARCHIVE:        "marketplace/vendor/unarchive/", //:user_id
      DELETE:           "marketplace/vendor/",
      GET_ALL:          "marketplace/vendors",
      GET_BY_USER:      "marketplace/vendor/user/",
      GET_ALL_BY_USER:  "marketplace/vendors/user/",
    },
  },
}
