import React, { useState, useEffect } from "react";
import { Row, Col, BackTop, Empty, Skeleton } from "antd";

import StepsGeneral from "../components/StepsLogsGeneral";
import LayoutPage from "../layouts/LayoutPage";
import SupplierLayout from "../layouts/SupplierLayout";
import CPLayout from "../layouts/ControlPanelLayout";
import { useParams } from "react-router-dom";
import OrderUsers from "../components/Generals/OrderUsers/OrderUsers";
import OrderService from '../utils/api/orders';
import NewOrderInfo from "../components/OrderInfo/NewOrderInfo";
import ChatService from "../utils/api/chat";

export default function NewOrderDetails() {
  const { id } = useParams();
  const role = Number(localStorage.getItem("role"));


  const [categories, setCategories] = useState([]);
  const [units, setUnits] = useState([]);
  const [loading, setLoading] = useState(true);

  const [order, setOrder] = useState([]);

  const [service, setService] = useState({});
  const [orderLogs, setOrderLogs] = useState([])

  const [reload, setReload] = useState(false);

  const [chats, setChats] = useState([]);

  let token = localStorage.getItem("token");
  var type = role;
  var Layout = "";

  if (type === 4) {
    Layout = LayoutPage;
  } else if (type === 6) {
    Layout = SupplierLayout;
  } else if (type == 1 || type == 3 || type == 5 || type == 2 || type == 10) {
    Layout = CPLayout;
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resp = await OrderService.GetOrderById(id);
        setOrder(resp.data.order);
        console.log(resp.data.order)
        const user = JSON.parse(localStorage.getItem("user"));
  
        // 🧠 Obtener categoría del usuario según su rol
        const role = user.role;
        const category =
          user.cat ||
          user.role_data?.cat1 ||
          user.vs ||
          null;
  
        // 🔍 Obtener los chats filtrando por orderId, rol y categoría
        const chatResp = await ChatService.GetByUser(user.id, {
          role,
          category,
          orderId: id, // Muy importante para filtrar por orden
        });
  
        if (chatResp.data?.success) {
          setChats(chatResp.data.chats || []);
        }
  
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  
    // Cargar logs de la orden
    OrderService.OrderLogs(id).then(resp => {
      setOrderLogs(resp.data.logs);
    });
  
  }, [id]);
  
  
    return (
      <Layout>
        {loading === true ? (
          <>
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
          </>
        ) : order === "" ? (
          <Empty description="No hay informacion sobre esta orden." />
        ) : (
          <Row gutter={[12, 12]}>
            <Col xs={24} sm={24} md={24} lg={16} xl={16} >
              <NewOrderInfo
                serviceData = {order}
                client = {order.client}
                reload={reload}
                role={role}
                token={token}
                setReload={setReload}
                categorias={categories}
                unidades={units}
              />
            </Col>
            {/* TODO: ADD THIS FUNCTIONS*/}
            {
            <Col xs={24} lg={8} xl={8}>
              <Row gutter={[12, 12]}>
                {
                <Col xs={24}>
                  <OrderUsers chats={chats} service={service} token={token} op={1} />
                </Col>
                }
                <Col xs={24}>
                  <StepsGeneral steps={orderLogs} order={order}/>
                </Col>

                {/* 
                <Col xs={24}>
                  {role === 4 &&
                    order.service.step_id >= 7 &&
                    order.service.step_id < 11 && (
                      <Rate
                        rate={order.rate}
                        order={order.service}
                        id={order.service.id}
                        reload={reload}
                        setReload={setReload}
                        step={order.service.step_id}
                        title="Calificar orden"
                      />
                    )}
                </Col>
                */}
              </Row>
            </Col>
            }
          </Row>
        )}
        <BackTop />
      </Layout>
    );
}

