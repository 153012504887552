import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Input,
  Select,
  Row,
  Col,
  Alert,
  AutoComplete,
  Typography,
} from "antd";
import Layout from "../layouts/ControlPanelLayout";
import "../scss/steps.scss";
import { VSCategoriesData } from "../data/vs";
import UserService from "../utils/api/users";
import '@ant-design/v5-patch-for-react-19';
const { Text } = Typography;

export default function AddUsers(props) {
  let role = localStorage.getItem("role");
  const [error, setError] = useState({
    msjSuccess: false,
    msjError: false,
    msg: "",
  });
  const [load, setLoad] = useState(false);
  const [form] = Form.useForm();
  const [autoCompleteResult, setAutoCompleteResult] = useState([]);
  const [filteredVsCategories, setFilteredVsCategories] = useState(VSCategoriesData);
  const [occupiedVs, setOccupiedVs] = useState({ vendors_vs: [], buyers_vs: [] });

	const userData = JSON.parse(localStorage.getItem('user'))
  const vsCategoriesData = VSCategoriesData

  const onemailChange = (value) => {
    if (!value) {
      setAutoCompleteResult([]);
    } else {
      setAutoCompleteResult(
        [
          "@live.com.mx",
          "@gmail.com",
          "@outlook.com",
          "@hotmail.com",
          "@yahoo.com",
          "@epno.com.mx",
        ].map((domain) => `${value}${domain}`)
      );
    }
  };

  const emailOptions = autoCompleteResult.map((email) => ({
    label: email,
    value: email,
  }));

  useEffect(() => {
    UserService.GetVs().then((resp) => {
      if (resp && resp.data) {
        setOccupiedVs(resp.data); // Guarda los VS ocupados en el estado
      }
    }).catch((err) => {
      console.error("Error obteniendo los VS ocupados:", err);
    });
  }, []);

  const onRoleChange = (value) => {
    // Filtrar `vs` dependiendo del `role_id`
    let availableVs = VSCategoriesData.filter((v) => {
      if (value == 5) return !occupiedVs.vendors_vs.includes(v.key); // Filtra los `vs` ocupados por vendedores
      if (value == 3) return !occupiedVs.buyers_vs.includes(v.key);  // Filtra los `vs` ocupados por compradores
      return true;
    });
    setFilteredVsCategories(availableVs);
  };
  

  const onFinish = (values) => {
    setLoad(true);
		const user = {
			name: values.name,
			email: values.email,
			phone: values.phone,
			password: values.password,
			bussiness: userData.bussiness,
			role: values.role_id,
      vs: values.category,
			active: true
		}

    if (user.role === 5){

    }

		console.log(values)

		UserService.RegisterAdmin(user)
      .then((response) => {
        if (response.data.success == true) {
          setLoad(false);
          setError({
            msjSuccess: true,
            msg: response.data.message,
          });
          form.resetFields();
          window.location.reload()
        } else {
          setLoad(false);
          setError({
            msjError: true,
            msg: response.data.message,
          });
        }
    }).catch((error) => {
        setError({
          msjError: true,
          msg: "Hubo un error al crear el nuevo usuario",
        });
        setLoad(false);
    });
  };

  return (
    <Layout>
      <div className="steps-content">
        {role != 1 ? (
          <Text mark>
            NOTA: El usuario agregado, quedará asignado a tu misma organización
            y valuestream.
          </Text>
        ) : (
          <Text mark>
            NOTA: El usuario agregado, quedará asignado a tu misma organización.
          </Text>
        )}
        <Form
          onFinish={onFinish}
          form={form}
          name="register"
          layout="vertical"
          style={{ marginTop: 15 }}
          scrollToFirstError
        >
          {error.msjError && (
            <Col
              style={{ marginTop: 16, marginBottom: 16, textAlign: "center" }}
            >
              <Alert message={error.msg} type="error" closable />
            </Col>
          )}
          {error.msjSuccess && (
            <Col
              style={{ marginTop: 16, marginBottom: 16, textAlign: "center" }}
            >
              <Alert message={error.msg} type="success" closable />
            </Col>
          )}
          <Row justify="center" gutter={[12, 12]} align="middle">
            <Col xs={24} md={12} lg={8} xl={6}>
              <Form.Item
                name="name"
                label="Nombre"
                rules={[
                  { required: true, message: "Ingresa el nombre de usuario!" },
                ]}
              >
                <Input name="name" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={8} xl={6}>
              <Form.Item
                name="email"
                label="E-mail"
                rules={[
                  {
                    type: "email",
                    message: "El correo agregado no es valido.",
                  },
                  {
                    required: true,
                    message: "Favor de ingresar un correo.",
                  },
                ]}
              >
                <AutoComplete options={emailOptions} onChange={onemailChange}>
                  <Input
                    className="login-input"
                    placeholder="Correo electronico"
                  />
                </AutoComplete>
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={8} xl={6}>
              <Form.Item
                name="phone"
                label="Telefono"
                rules={[
                  {
                    required: true,
                    message: "Favor de agregar un numero de telefono.",
                  },

                  {
                    pattern: /^(?:\d*)$/,
                    message: "Favor de agregar un numero valido",
                  },
                  {
                    pattern: /^[\d]{10,13}$/,
                    message:
                      "El numero de telefono debe tener al menos 10 caracteres y no mayor a 13.",
                  },
                ]}
              >
                <Input name="phone" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={8} xl={6}>
              <Form.Item
                name="password"
                label="Contraseña"
                rules={[
                  {
                    required: true,
                    message: "Favor de agregar su contraseña.",
                  },
                  {
                    min: 8,
                    message:
                      "La contraseña debe contener al menos 8 caracteres",
                  },
                ]}
                hasFeedback
              >
                <Input.Password name="password" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={8} xl={6}>
              <Form.Item
                name="confirm"
                label="Confirmar Contraseña"
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Favor de confirmar la contraseña.",
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject("Las contraseñas no coinciden.");
                    },
                  }),
                ]}
              >
                <Input.Password name="confirm" /> 
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={8} xl={6}>
              <Form.Item name="role_id" label="Tipo" rules={[{ required: true, message: "Selecciona un tipo de usuario." }]}>
                <Select placeholder="Selecciona un tipo de usuario" onChange={onRoleChange}>
                  <Select.Option value={3}>Comprador</Select.Option>
                  <Select.Option value={5}>Vendedor</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Form.Item noStyle shouldUpdate={(prev, current) => prev.role_id !== current.role_id}>
              {({ getFieldValue }) =>
                getFieldValue("role_id") === 3 || getFieldValue("role_id") === 5 ? (
                  <Col xs={24} md={12} lg={8} xl={6}>
                    <Form.Item name="category" label="Categoría" rules={[{ required: true, message: "Debes seleccionar una categoría." }]}>
                      <Select placeholder="Selecciona una categoría">
                        {filteredVsCategories.map((v) => (
                          <Select.Option key={v.key} value={v.key}>
                            {v.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                ) : null
              }
            </Form.Item>
            <Col xs={24} md={12} lg={8} xl={6}>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  shape="round"
                  danger
                  loading={load}
                >
                  Registrar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Layout>
  );
}
