import React, { useState, useEffect } from "react";
import '@ant-design/v5-patch-for-react-19';
import {
  Card,
  Row,
  Col,
  Button,
  Tag,
  Upload,
  Form,
  Input,
  Popconfirm,
  Collapse,
  Avatar,
  message,
  Select,
  Descriptions,
  DatePicker,
  Divider,
  Rate,
  Modal
} from "antd";

import "./OrderInfo.scss";
import {
  DownloadOutlined,
  CheckCircleOutlined,
  UploadOutlined,
  WarningFilled,
  PlusOutlined,
} from "@ant-design/icons";
import moment from "moment";
import OrderService from '../../utils/api/orders';
import ServiceStatus from "../Generals/ServiceStatus";
import { VSCategoriesData } from "../../data/vs";
import { OrderLogs } from "../../data/orderStatusLogs";
import CategoryTag from "../Tags";
import ServicesService from "../../utils/api/services";
import ServiceReviews from "./ServiceReviews";
import ServiceComplaints from "./ServiceComplaints";
import ChatService from "../../utils/api/chat";

const { Panel } = Collapse;


export default function NewOrderInfo(props) {
  const {serviceData, client, reload, setReload, role } = props;
  // Maypretec
  const vsCategories = VSCategoriesData
  const userData = JSON.parse(localStorage.getItem('user'))
  const [order, setService] = useState(serviceData);
  const [loadCancelar, setLoadCancelar] = useState(false);
  const [loadServiceChangeStep, setLoadServiceChangeStep] = useState(false);


  // MAYPRETEC ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const [filteredServices, setFilteredServices] = useState([]);
  let usercategory = 0;
  const [proposals, setProposals] = useState([])
  const [positions, setPositions] = useState([])
  const [proposalPrice, setProposalPrice] = useState([])
  const [proposalFiles, setProposalFiles] = useState([])
  const [proposalFilesUrl, setProposalFilesUrl] = useState([])
  const [application, setApplication] = useState({
    title: '',
		description: '',
		price: 0,
		dueDate: '',
    type: '',
		fileList: [],
	});

  const [loadingQuote, setLoadingQuote] = useState(false);
  const [newQuote, setNewQuote] = useState({
    order_id: order.orderId,
    service_id: '',
    supplier_id: userData.id,
    supplier_name: "",
    supplier_bussiness: "",
    supplier_fcm: "n/a",
    title: "",
    description: "",
    price: 0,
    epno_price: 0,
    change_type: '',
    due_date: null,
    proposal_file: null,
    epno_file: null
  });

  const [newSupplierQuote, setNewSupplierQuote] = useState({
    order_id: order.orderId,
    service_id: '',
    supplier_id: role == 6 && userData.id,
    supplier_name: role == 6 && userData.name ,
    supplier_bussiness: role == 6 && userData.bussiness,
    supplier_fcm: role == 6 && userData.fcm || '',
    title: "",
    description: "",
    price: 0,
    epno_price: 0,
    change_type: '',
    due_date: null,
    proposal_file: null,
    epno_file: null
  })

  const [industryPO, setIndustryPO] = useState("")
  const [supplierPO, setSupplierPO] = useState("")

  // Reviews variables
  const [reviews, setReviews] = useState({});
  const [reviewRating, setReviewRating] = useState({});
  const [reviewComment, setReviewComment] = useState({});
  const [loadingReview, setLoadingReview] = useState(false);

  // Complaints variables
  const [showComplaintForm, setShowComplaintForm] = useState({}); // Muestra el formulario por servicio
  const [complaints, setComplaints] = useState({}); // Almacena las quejas por servicio
  const [complaintData, setComplaintData] = useState({}); // Almacena los datos de la queja actual
  const [loadingComplaint, setLoadingComplaint] = useState(false);
  const [replyText, setReplyText] = useState({}); // Almacena respuestas a quejas

  // Cancel order 
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [cancelReason, setCancelReason] = useState("");


/* USER DATA API CALL */
useEffect(() => {
  const filterVisibleServices = () => {
    let category = null;

    if (role === 6) category = parseInt(userData.role_data.cat1);
    else if (role === 3 || role === 5) category = parseInt(userData.vs);

    if ([1, 4].includes(role)) return serviceData.services; // Admin y Cliente ven todo
    if ([3, 5, 6].includes(role)) return serviceData.services.filter(s => s.cat === category);
    
    return []; // Otros roles no ven servicios
  };

  const fetchQuotes = async (visibleServices) => {
    try {
      const response = await ServicesService.GetQuotesByOrder(order.orderId);
      if (!response.data.success) return {};

      let quotes = response.data.proposals || {};
      const serviceIds = visibleServices.map(s => s.serviceId);

      switch (role) {
        case 6: // Proveedor ve solo sus cotizaciones
          Object.keys(quotes).forEach(serviceId => {
            quotes[serviceId] = quotes[serviceId].filter(q => q.supplier_id === userData.id);
          });
          break;
        case 3: case 5: // Compras/Ventas ven solo su categoría
          Object.keys(quotes).forEach(serviceId => {
            if (!serviceIds.includes(serviceId)) delete quotes[serviceId];
          });
          break;
        case 4: // Cliente ve cotizaciones si el servicio tiene status >= 3
          Object.keys(quotes).forEach(serviceId => {
            const service = visibleServices.find(s => s.serviceId == serviceId);
            if (!service || service.status < 3) {
              delete quotes[serviceId];
            } else if (service.status >= 3 && service.supplier_id) {
              quotes[serviceId] = quotes[serviceId].filter(q => q.status === true);
            }
          });
          break;
        // Admin (role == 1) ve todo, no se filtra nada
      }

      return quotes;
    } catch (error) {
      return {};
    }
  };

  const fetchReviews = async (serviceIds) => {
    try {
      const reviewsData = {};
      await Promise.all(serviceIds.map(async (serviceId) => {
        try {
          const response = await ServicesService.GetReviewByService(serviceId);
          reviewsData[serviceId] = response.data.success ? response.data.reviews : [];
        } catch (error) {
          reviewsData[serviceId] = [];
        }
      }));
      return reviewsData;
    } catch (error) {
      return {};
    }
  };

  // 🔹 Nueva función para obtener quejas por servicio
  const fetchComplaints = async (serviceIds) => {
    try {
      const complaintsData = {};
      await Promise.all(serviceIds.map(async (serviceId) => {
        try {
          const response = await ServicesService.GetComplaintByService(serviceId);
          complaintsData[serviceId] = response.data.success ? response.data.complaints : [];
        } catch (error) {
          complaintsData[serviceId] = [];
        }
      }));
      return complaintsData;
    } catch (error) {
      return {};
    }
  };

  const fetchData = async () => {
    const visibleServices = filterVisibleServices();
    setFilteredServices(visibleServices);

    const serviceIds = visibleServices.map(s => s.serviceId);
    const [quotes, reviews, complaints] = await Promise.all([
      fetchQuotes(visibleServices),
      fetchReviews(serviceIds),
      fetchComplaints(serviceIds) // Agregar quejas al proceso
    ]);

    setProposals(quotes);
    setReviews(reviews);
    setComplaints(complaints); // Guardar quejas en el estado
  };

  fetchData();
}, [order.userId, order.id, role, userData.id, serviceData, role]);



  /** Actualiza el status de cada servicio individualmente */
  const updateService = (service_id, status) => {
    setLoadServiceChangeStep(true);
    ServicesService.UpdateStatus(service_id, status).then(resp => {
      if (resp.data.success === true) {
        setReload(!reload);
        message.success("Actualizacion hecha correctamente").then(() => {
          window.location.reload();
        });
      } else {
        message.error(resp.data.message);
      }
      setLoadServiceChangeStep(false);
    }).catch(e => {
      message.error("Hubo un error al enviar los datos.");
      setLoadServiceChangeStep(false);
    })
  }

  // Rejects purchase order
  const rejectOrder = (id, body) => {
    setLoadServiceChangeStep(true);
    OrderService.Update(id, body).then(resp => {
      
      if (resp.data.success === true) {
        setReload(!reload);
        message.success("La orden de compra ha sido rechazada").then(() => {
          window.location.reload();
        });
      } else {
        message.error(resp.data.message);
      }
      setLoadServiceChangeStep(false);
    }).catch(e => {
      message.error("Hubo un error al enviar los datos.");
      setLoadServiceChangeStep(false);
    })
  }

  const uploadIndustryPO = (service_id) => {
    ServicesService.Update(service_id, {client_po_file: industryPO}).then(resp => {
      if (resp.data.success === true) {
        message.success("Actualizacion de datos exitoso!", 0.5).then(() => {
          window.location.reload();
        });
      } else {
        message.error(resp.data.message);
      }
    }).catch((error) => {
      message.error("Hubo un error al enviar los datos.");
    });
  }

  const uploadSupplierPO = (service_id) => {
    ServicesService.Update(service_id, {
      epno_po_file: supplierPO,
      status: 4
    }).then(resp => {
      if (resp.data.success === true) {
        message.success("Actualizacion de datos exitoso!", 0.5).then(() => {
          window.location.reload();
        });
      } else {
        message.error(resp.data.message);
      }
    }).catch((error) => {
      message.error("Hubo un error al enviar los datos.");
    });
  }


  // Upload file -------------------------------------------
  const getBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => {
				const base64String = reader.result;
				resolve(base64String);
			};
			reader.onerror = (error) => reject(error);
		});
	};


  const propsPOIndustry = {
    beforeUpload: async (file) => {
      const base64 = await getBase64(file);
      setIndustryPO(base64)
      return false;
    },
    application
  };

  const propsPOSupplier = {
    beforeUpload: async (file) => {
      const base64 = await getBase64(file);
      setSupplierPO(base64)
      return false;
    },
    application
  };

  /* COTIZACIONES POR PARTE DE EPNO ----------------------------------------------------------*/
  const handleInputChange = (e) => {
    setNewQuote({ ...newQuote, [e.target.name]: e.target.value });
  };

  const handleDateChange = (date, dateString) => {
    setNewQuote({ ...newQuote, due_date: dateString });
  };

  const handleFileUpload = async (file) => {
    const base64 = await getBase64(file);
    setNewQuote({ ...newQuote, proposal_file: base64 });
    return false;
  };

  const handleEpnoFileUpload = async (file) => {
    const base64 = await getBase64(file);
    setNewQuote({ ...newQuote, epno_file: base64 });
    return false;
  };

  const handleSubmit = async (service_id) => {
    if (loadingQuote) return;
  
    setLoadingQuote(true); 
  
    const updatedQuote = {
      ...newQuote,
      supplier_bussiness: newQuote.supplier_name,
      service_id: service_id,
    };
  
    try {
      const resp = await ServicesService.NewInternalQuote(updatedQuote);
      if (resp.data.success) {
        message.success("Cotización guardada exitosamente");
        setReload(!reload); // Recarga la página si es necesario
      } else {
        message.error("Error al guardar la cotización: " + resp.data.message);
      }
    } catch (error) {
      console.error("Error al enviar cotización:", error);
      message.error("Hubo un error al enviar la cotización.");
    }
  
    setLoadingQuote(false); // Detiene el loader
  };

  /* COTIZACION POR PARTE DEL PROVEEDOR -------------------------------------------------*/
  const handleInputSupplierChange = (e) => {
    setNewSupplierQuote({ ...newSupplierQuote, [e.target.name]: e.target.value });
  };

  const handleDateSupplierChange = (date, dateString) => {
    setNewSupplierQuote({ ...newSupplierQuote, due_date: dateString });
  };

  const handleSupplierFileUpload = async (file) => {
    const base64 = await getBase64(file);
    setNewSupplierQuote({ ...newSupplierQuote, proposal_file: base64 });
    return false;
  };

  const handleSupplierSubmit = async (service_id) => {
    if (loadingQuote) return;
  
    setLoadingQuote(true); 
  
    const updatedQuote = {
      ...newSupplierQuote,
      service_id: service_id,
    };
  
    try {
      const resp = await ServicesService.NewQuote(updatedQuote);
  
      if (resp.data.success) {
        message.success("Cotización guardada exitosamente");
  
        // ✅ Crear chat después de cotizar
        const user = JSON.parse(localStorage.getItem("user"));
        const orderId = updatedQuote.order_id || updatedQuote.orderId; // asegúrate que venga el orderId
  
        if (orderId && user?.id) {
          await ChatService.NewQuote(service_id, {
            orderId,
            supplierId: user.id,
          });
          console.log("Chat creado correctamente");
        }
  
        setReload(!reload); // Recarga si es necesario
      } else {
        message.error("Error al guardar la cotización: " + resp.data.message);
      }
    } catch (error) {
      console.error("Error al enviar cotización:", error);
      message.error("Hubo un error al enviar la cotización.");
    }
  
    setLoadingQuote(false);
  };

  const updateServiceProposal = async (proposalId) => {
    setLoadingQuote(true);
    const updatedData = {
      epno_price: proposalPrice[proposalId] || 0,
      place: positions[proposalId] || 0,
      epno_file: proposalFiles[proposalId] || null
    };
  
    try {
      const resp = await ServicesService.UpdateQuote(proposalId, updatedData);
      if (resp.data.success) {
        message.success("Cotización actualizada correctamente");
        setReload(!reload); // Recargar datos
      } else {
        message.error("Error al actualizar: " + resp.data.message);
      }
    } catch (error) {
      console.error("Error al actualizar cotización:", error);
      message.error("Hubo un error al actualizar la cotización.");
    }
  
    setLoadingQuote(false);
  };

  // Escoge una porpuesta para la orden
  const chooseServiceProposal = (proposal) => {
    setLoadServiceChangeStep(true);
    const body ={
      proposal_id:        proposal.id,
      supplier_id:        proposal.supplier_id,
      supplier_name:      proposal.supplier_name,
      supplier_bussiness: proposal.supplier_bussiness,
      supplier_fcm:       proposal.supplier_fcm,
      supplier_due_date:  proposal.supplier_due_date,
      supplier_price:     proposal.price,
      epno_file:          proposal.epno_file,
      epno_price:         proposal.epno_price,
      change_type:        proposal.change_type,
    }

    ServicesService.ChooseQuote(proposal.id, body).then((resp) => {
      if (resp.data.success === true) {
        setReload(!reload);
        setLoadServiceChangeStep(false);
        message.success("Eleccion exitosa", 1).then(() => {
          window.location.reload();
        });
      } else {
        message.error(resp.data.message);
      }
    }).catch((error) => {
      message.error("Hubo un error al enviar los datos.");
      setLoadServiceChangeStep(false);
    });
  }

  // ------------------------------------------------------------
  return (
    <Row gutter={[12, 12]} justify="center" align="middle">
      
      {/* HEADER ------------------------------------------------------------------------- */}
      <Col xs={24}>
        <Row gutter={[12, 12]} justify="center" align="middle">
          {/* TITULO */ }
          <Col xs={24} md={12} xl={12}>
            <Row gutter={[12, 12]} justify="center">
              <Col xs={24}>
                <label style={{ fontWeight: 600, fontSize: 18, color: '#000000' }}>
                  {order.title} - {order.orderId}
                </label>
              </Col>
              <Col xs={24}>
                <label style={{ fontWeight: 600, color: "#888888" }}>
                  {moment(order.createdAt).format("DD/MM/YYYY H:ss")}
                </label>
              </Col>
            </Row>
            
          </Col>

          {/* BOTON DE SEGUIMIENTO DE PROCESOS */}    
          {/* TODO: Terminar seguimiento de los procesos */}    
          <Col xs={24} md={12} xl={12} style={{ textAlign: "center" }}>  
            
          
          </Col>

        </Row>
      </Col>

      <Col xs={24}>
        <Card className="background-gris">
          <Row gutter={[12, 12]} align="middle">
            <Col xs={24}>
              <Row gutter={[12, 12]}>
                <Col xs={20}>
                  {order.status == 8 && 
                    <p><b>La orden a sido cancelada debido a: </b>{order.rejectData}</p>
                  }
                </Col>
                { // TODO: Cancelar
                (order.status <= 3 && (role == 4 || role == 1)) &&
                <Col xs={4} style={{margin: 'auto'}}>
                  <Button
                    danger
                    loading={loadServiceChangeStep}
                    onClick={() => setShowCancelModal(true)}
                  >
                    Cancelar orden
                  </Button>
                </Col>
                }
              </Row>
            </Col>
          </Row>
        </Card>
      </Col>

      {/* PROFILE INFO DIV --------------------------------------------------------------- */}
      <Col xs={24}>
        <Card className="background-gris">
          <Row gutter={[12, 12]} align="middle">
            <Col xs={24} md={6} style={{ textAlign: "center" }}>
              <Avatar
                src={client.logo}
                size={{ xs: 150, md: 120, lg: 100, xl: 100, xxl: 150 }}
              />
            </Col>
            <Col xs={24} md={18}>
              <Row gutter={[12, 12]}>
                <Col xs={24} md={10} lg={24} xl={11}>
                  <label className="gris-bold">Requisitor</label> <br />
                  <b>{client.name}</b>
                </Col>
                <Col xs={24} xl={13}>
                  <label className="gris-bold">Dirección</label> <br />
                  <b>{client.street + ', ' + client.postal_code + ', ' + client.city + ', ' + client.state}</b>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Col>

      {/* SERVICE INFO ------------------------------------------------------------------- */}
      <Col xs={24}>
        <Card headStyle={{ background: "#F4F6F6" }} className="actions-back"
          title={  <Row gutter={[12, 12]}> <Col> <h3>Servicios</h3> </Col> </Row> }
          //extra={ /*TODO: Sumatoria total de precio */}
        >
          <Row gutter={[12, 12]} align="middle" justify="center">
            <Col xs={24} >
              <Row gutter={[12, 12]}>
                <Col xs={12} > {/* Client due date */}
                  <b>Fecha de entrega esperada:</b>{" "} <p style={{ fontSize: 16, margin: 0 }}> { moment(order.cliet_due_date).format("DD/MM/YYYY")} </p>
                </Col>
                <Col xs={24}>
                {filteredServices.map((sub) => (
                  <Collapse bordered={false} className="background-gris">
                    <Panel
                    header={ 
                    <Row gutter={[6, 12]}> 
                      <Col xs={24} style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}> 
                        <b>{sub.serviceId} - {sub.title }</b> <ServiceStatus status={sub.status}/>
                      </Col> 
                    </Row>
                    }
                    key={sub.serviceId} >
                      <Row gutter={[12, 12]} justify="center"> {/* Datos del servicio*/}
                        <Col xs={24}>
                          <Card>
                            <Descriptions bordered size="small" title={<div style={{display: 'flex', justifyContent: 'space-between'}}>
                              <h4 style={{margin: 0, padding: 0}}>Datos del servicio</h4>
                                { // Vendedor
                                role === 5 ?
                                  sub.status === 1 ? // Aprovar orden de compra del cliente
                                  <div style={{display: 'flex', justifyContent: 'right'}}>
                                    <Popconfirm
                                      title="¿Seguro que desea enviar a cotizacion?"
                                      onConfirm={() => updateService(sub.serviceId, 2)}
                                      okText="Si"
                                      cancelText="No"
                                      >
                                      <Button type="primary" icon={<UploadOutlined />} loading={loadServiceChangeStep}> Verificar PO </Button>
                                    </Popconfirm>
                                    <div style={{height: '1px', width: '16px'}}></div>
                                    <Popconfirm
                                      title="¿Seguro que desea rechazar la orden de compra?"
                                      onConfirm={() => updateService(sub.serviceId, 8)}// TODO: Orden de compra regresada
                                      okText="Si"
                                      cancelText="No"
                                      >
                                      <Button variant="solid" danger icon={<WarningFilled />} loading={loadServiceChangeStep}> Rechazar PO </Button>
                                    </Popconfirm>
                                  </div>
                                  : sub.status === 5 ? // Paso la inspeccion de EP&0
                                  <Popconfirm
                                      title="¿Seguro que desea confirmar esta orden como lista?"
                                      onConfirm={() => updateService(sub.serviceId, 6)}
                                      okText="Si"
                                      cancelText="No"
                                    >
                                    <Button type="primary"  icon={<CheckCircleOutlined />} loading={loadServiceChangeStep}> Orden Lista </Button>
                                  </Popconfirm>
                                  : <></>
                                :
                                <></>
                              }   

                              { // Compras
                                role === 3 ?
                                  sub.status === 2 ? // La orden debe tener cotizaciones listas
                                    <Popconfirm
                                      title="¿Seguro que la orden esta lista para subir su cotizacion?"
                                      onConfirm={() => updateService(sub.serviceId, 3)}
                                      okText="Si"
                                      cancelText="No"
                                    >
                                    <Button type="primary" icon={<UploadOutlined />}   disabled={!proposals[sub.serviceId] || proposals[sub.serviceId].length === 0}
                                    > Subir COT </Button>
                                  </Popconfirm>
                                  : <></>
                                :<></>
                              }
                              { // Cliente
                                role === 4 && sub.status === 6 &&
                                <Popconfirm
                                title="¿Seguro que ha recibido su orden completa?"
                                onConfirm={() => updateService(sub.serviceId, 7)}
                                okText="Si"
                                cancelText="No"
                                >
                                  <Button type="primary" icon={<CheckCircleOutlined />} loading={loadServiceChangeStep}> Entregado </Button>
                                </Popconfirm>
                              }
                              { // Supplier
                                (role === 6 && sub.status === 4) || 
                                ((role === 1 || role === 3 || role === 5) && sub.status === 4 && sub.supplier_fcm == 'n/a') &&
                                <Popconfirm
                                title="¿Seguro que el servicio ha sido elaborado?"
                                onConfirm={() => updateService(sub.serviceId, 5)}
                                okText="Si"
                                cancelText="No"
                                >
                                  <Button type="primary" icon={<CheckCircleOutlined />} loading={loadServiceChangeStep}> Servicio elaborado </Button>
                                </Popconfirm>
                              }
                                </div>} column={{ xl: 3, lg: 3, md: 3, sm: 1, xs: 1, }}>
                                <Descriptions.Item label="Descripción" span={3} >
                                  {sub.description}
                                </Descriptions.Item>
                                <Descriptions.Item label="Cantidad" span={3} >
                                  {sub.quantity}
                                </Descriptions.Item>
                                <Descriptions.Item label="Categoria" span={3} >
                                  <CategoryTag sub={sub} vsCategories={vsCategories}/>
                                </Descriptions.Item>                     
                                <Descriptions.Item label="Fecha de entrega del proveedor" span={3} >
                                  {sub.due_date || "Por definir"}
                                </Descriptions.Item>
                                <Descriptions.Item label="Fecha de entrega final" span={3} > {/* Fecha de finalizacion */}
                                  {sub.finish_date || "Por definir"}
                                </Descriptions.Item>
                                <Descriptions.Item label="Archivo del servicio" span={3}> {/* Documento del servicio */}
                                  <Row gutter={[12, 12]} justify="center">
                                    <Col xs={24} md={12} style={{ textAlign: "center" }}>
                                      <a href={sub.service_file} target="_blank" rel="noopener noreferrer" download >
                                        <DownloadOutlined />
                                        Especificaciones
                                      </a>
                                    </Col>
                                  </Row>
                                </Descriptions.Item>
                                { role == 1}
                                <Descriptions.Item label="Cotizacion del proveedor" span={3}> {/* Cotizacion */}
                                  <Row gutter={[12, 12]} justify="center">
                                    <Col xs={24} md={12} style={{ textAlign: "center" }}>
                                    {sub.epno_file ?
                                    <a href={sub.epno_file} target="_blank" rel="noopener noreferrer" download >
                                      <DownloadOutlined />
                                      Especificaciones
                                    </a>
                                    : <p>Por definir</p>}
                                    </Col>
                                  </Row>
                                </Descriptions.Item>
                                {/* ORDENES DE COMPRA --------------------------------------------- */}
                                {role != 6 && sub.status >= 3 && 
                                <Descriptions.Item 
                                  label= {role == 4 ? "Orden de compra" : (role == 1 || role === 3 || role || 5) && "Orden de compra del cliente"} 
                                  span={3}> {/* Orden de compra del cliente */}
                                  <Row gutter={[12, 12]} justify="center">
                                    <Col xs={24} md={12} style={{ textAlign: "center" }}>
                                    {role == 4 && sub.client_po_file == "" ?
                                    <>
                                      <Upload {...propsPOIndustry}>
                                        <Button icon={<UploadOutlined />} >Selecciona tu Orden de compra</Button>
                                      </Upload>
                                      <Button type="primary" onClick={() => {uploadIndustryPO(sub.serviceId)}} disabled={industryPO == ""}>Subir orden de compra </Button>

                                    </>
                                    
                                    : sub.client_po_file ? 
                                    <a href={sub.client_po_file} target="_blank" rel="noopener noreferrer" download >
                                      <DownloadOutlined /> {role == 4 ? "Orden de compra" : (role == 1 || role === 3 || role || 5) && "Orden de compra del cliente"}
                                    </a>
                                    : <p>Por definir</p>
                                    }
                                    </Col>
                                  </Row>
                                </Descriptions.Item>
                                }
                                { role != 4 &&
                                <Descriptions.Item label={role === 6 ? 'Orden de compra' :  (role == 1 || role === 3 || role || 5) && "Orden de compra proveedor"} span={3}> {/* Orden de compra para para proveedor */}
                                  <Row gutter={[12, 12]} justify="center">
                                    <Col xs={24} md={12} style={{ textAlign: "center" }}>
                                    {(role == 3 && sub.client_po_file != "" && sub.status == 3) ?
                                    <>
                                      <Upload {...propsPOSupplier}>
                                        <Button icon={<UploadOutlined />}>Selecciona tu Orden de compra</Button>
                                      </Upload>
                                      <Button type="primary" disabled={supplierPO == ''} onClick={() => {uploadSupplierPO(sub.serviceId)}} >Subir orden de compra </Button>
                                    </>
                                    : sub.epno_po_file != '' ? 
                                    <a href={sub.epno_po_file} target="_blank" rel="noopener noreferrer" download >
                                      <DownloadOutlined /> {role === 6 ? 'Orden de compra' :  (role == 1 || role === 3 || role || 5) && "Orden de compra proveedor"}
                                    </a>
                                    : <p>Por definir</p>
                                    }
                                    </Col>
                                  </Row>
                                </Descriptions.Item>
                                }
                                
                                
                                
                                <Descriptions.Item label="Estatus" span={3} > {/* Estatus del servicio */}
                                  {
                                    role == 1 ? // Admin
                                    <p>{OrderLogs.adminStatusLogs.find(step => step.status === order.status)?.text || 'Error cargando la informacion'}</p>
                                    : role == 3 ? // Buyer
                                    <p>{OrderLogs.buyerStatusLogs.find(step => step.status === order.status)?.text || 'Error cargando la informacion'}</p>
                                    : role == 4 ? // Client
                                    <p>{OrderLogs.clientStatusLogs.find(step => step.status === order.status)?.text || 'Error cargando la informacion'}</p>
                                    : role == 5 ? // Vendor
                                    <p>{OrderLogs.vendorStatusLogs.find(step => step.status === order.status)?.text || 'Error cargando la informacion'}</p>
                                    : role == 6 ? // Supplier
                                    <p>{OrderLogs.supplierStatusLogs.find(step => step.status === order.status)?.text || 'Error cargando la informacion'}</p>
                                    : 'Error cargando la informacion' 
                                  }
                                  {
                                    order.status == 8 && <p>{order.rejectData}</p>
                                  }
                                </Descriptions.Item>

                                {/* REVIEW DEL SERVICIO */}
                                {sub.status === 7 && (
                                <Descriptions.Item label="Calificación y Comentarios" span={3}>
                                  <ServiceReviews
                                    serviceId={sub.serviceId}
                                    userRole={role}
                                    reviews={reviews}
                                    setReviews={setReviews}
                                    userData={userData}
                                  />
                                </Descriptions.Item>
                                )}
                                {/* QUEJAS DEL SERVICIO*/}
                                <Descriptions.Item label="Quejas del Servicio" span={3}>
                                  <ServiceComplaints
                                    serviceId={sub.serviceId}
                                    userRole={role}
                                    complaints={complaints}
                                    setComplaints={setComplaints}
                                    userData={userData}
                                    status={sub.status}
                                  />
                                </Descriptions.Item>
                              </Descriptions>
                            </Card>
                          </Col>
                          
                      </Row>
                      <Row gutter={[12, 12]} justify="center"> {/* Cotizaciones */}
                        <Col xs={24}>
                          <Card>
                            <h3 style={{margin: 0, padding: 0}}>Cotizaciones</h3>
                            {proposals[sub.serviceId] && proposals[sub.serviceId].length > 0 ? (
                            <Collapse bordered={false} className="background-gris">
                              {proposals[sub.serviceId].map((pro, index) => (
                                
                                <Panel 
                                  key={index} 
                                  header={
                                    <Row gutter={[6, 12]}>
                                      <Col xs={24} style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                        <b>{pro.supplier_bussiness}</b>
                                      </Col>
                                    </Row>
                                  }
                                >
                                  <Card>
                                    <h3 style={{ margin: 0, padding: 0 }}>Datos de la cotización</h3>
                                    <Descriptions bordered size="small">
                                      <Descriptions.Item label="Proveedor" span={3}>
                                        {pro.supplier_bussiness}
                                      </Descriptions.Item>
                                      <Descriptions.Item label="Título" span={3}>
                                        {pro.title}
                                      </Descriptions.Item>
                                      <Descriptions.Item label="Descripción" span={3}>
                                        {pro.description}
                                      </Descriptions.Item>
                                      <Descriptions.Item label={(role == 6 || role == 4 )?   'Precio' : 'Precio del proveedor'} span={3}>
                                        { (role == 4 ) ? `$${pro.epno_price} ${pro.change_type}` 
                                        : (role == 6) ? `$${pro.price} ${pro.change_type}` 
                                        : pro.price ? `$${pro.price} ${pro.change_type}` : "Por definir"}
                                      </Descriptions.Item>
                                      <Descriptions.Item label="Fecha de entrega" span={3}>
                                        {pro.due_date ? moment(pro.due_date).format("DD/MM/YYYY") : "Por definir"}
                                      </Descriptions.Item>
                                      {pro.proposal_file && (
                                        <Descriptions.Item label="Cotización" span={3}>
                                          <a href={(role == 4) ? pro.epno_file : pro.proposal_file } target="_blank" rel="noopener noreferrer" download>
                                            <DownloadOutlined /> Descargar Cotización
                                          </a>
                                        </Descriptions.Item>
                                      )}
                                      {(role === 1 || role === 3 || role === 5) && (
                                      <>
                                        <Descriptions.Item label="Precio de EP&0" span={3}>
                                          {role === 3 && sub.status == 2 ? (
                                            <Input
                                              type="number"
                                              name="epno_price"
                                              value={proposalPrice[pro.id] || pro.epno_price || 0}
                                              onChange={(e) => setProposalPrice({ ...proposalPrice, [pro.id]: e.target.value })}
                                            />
                                          ) : (
                                            <p>{ pro.epno_price ? '$'+pro.epno_price  : 'Por definir'}</p>
                                          )}
                                        </Descriptions.Item>
                                        
                                        <Descriptions.Item label="Posición" span={3}>
                                          {role === 3 && sub.status == 2 ? (
                                            <Input
                                              type="number"
                                              name="position"
                                              value={positions[pro.id] || pro.place || '0'}
                                              onChange={(e) => setPositions({ ...positions, [pro.id]: e.target.value })}
                                            />
                                          ) : (
                                            <p>{pro.place  || 'Por definir'}</p>
                                          )}
                                        </Descriptions.Item>

                                        <Descriptions.Item label="Cotización de EP&O" span={3}>
                                          {role === 3 && sub.status == 2 && !pro.epno_file? (
                                            <Upload beforeUpload={async (file) => {
                                              const base64 = await getBase64(file);
                                              setProposalFiles({ ...proposalFiles, [pro.id]: base64 });
                                              return false;
                                            }} maxCount={1}>
                                              <Button icon={<UploadOutlined />}>Subir archivo</Button>
                                            </Upload>
                                          ) : pro.epno_file ? (
                                            <>
                                            {role == 3 && sub.status == 2 &&
                                              <Upload beforeUpload={async (file) => {
                                                const base64 = await getBase64(file);
                                                setProposalFiles({ ...proposalFiles, [pro.id]: base64 });
                                                return false;
                                              }} maxCount={1}>
                                                <Button icon={<UploadOutlined />}>Subir archivo</Button>
                                              </Upload>
                                            }
                                            
                                            <a href={pro.epno_file} target="_blank" rel="noopener noreferrer" download>
                                              <DownloadOutlined /> Descargar Cotización de EP&O
                                            </a>
                                            </>
                                          ) : (
                                            <p>Por definir</p>
                                          )}
                                        </Descriptions.Item>

                                        { (role === 3 && sub.status == 2) && (
                                          <Descriptions.Item span={3}>
                                            <Button
                                              type="primary"
                                              disabled={!proposalPrice[pro.id] || !positions[pro.id] || !proposalFiles[pro.id]}
                                              loading={loadingQuote}
                                              onClick={() => updateServiceProposal(pro.id)}
                                            >
                                              {loadingQuote ? "Guardando..." : "Guardar cambios"}
                                            </Button>
                                          </Descriptions.Item>
                                        )}
                                        
                                      </>
                                    )}
                                    {(role === 4 && sub.status == 3 && !sub.supplier_id ) &&
                                        <Descriptions.Item span={3}>
                                          <Button
                                            type="primary"
                                            loading={loadingQuote}
                                            onClick={() => chooseServiceProposal(pro)}
                                          >
                                            {loadingQuote ? "Guardando..." : "Aceptar cotizacion"}
                                          </Button>
                                        </Descriptions.Item>
                                        }

                                    </Descriptions>
                                  </Card>
                                </Panel>
                              ))}
                            </Collapse>
                            ) : ( <p>No hay cotizaciones para este servicio.</p> )}
                            {(role == 3 && sub.status == 2) && /* Agregar cotizacion externa */
                            <Collapse bordered={false} className="background-gris" expandIcon={({ isActive }) => <PlusOutlined rotate={isActive ? 45 : 0} />}>
                            <Panel header={ 
                            <Row gutter={[6, 12]}> 
                              <Col xs={24} style={{display: 'flex', alignItems: 'center'}}> 
                                <b>Agregar una cotización externa</b> 
                              </Col> 
                            </Row>
                            }
                            key={sub.serviceId} >
                              <Row gutter={[12, 12]} justify="center">
                                  <Col xs={24}>
                                    <Card>
                                      <Descriptions bordered size="small" column={{ xl: 3, lg: 3, md: 3, sm: 1, xs: 1 }}>
                                        <Descriptions.Item label="Proveedor" span={3}>
                                          <Input name="supplier_name" value={newQuote.supplier} onChange={handleInputChange} />
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Título" span={3}>
                                          <Input name="title" value={newQuote.title} onChange={handleInputChange} />
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Descripción" span={3}>
                                          <Input.TextArea name="description" value={newQuote.description} onChange={handleInputChange} />
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Precio" span={3}>
                                          <Input type="number" name="price" value={newQuote.price} onChange={handleInputChange} />
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Precio de EP&0" span={3}>
                                          <Input type="number" name="epno_price" value={newQuote.epno_price} onChange={handleInputChange} />
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Fecha de entrega" span={3}>
                                          <DatePicker style={{ width: "100%" }} onChange={handleDateChange} />
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Cotización" span={3}>
                                          <Upload beforeUpload={handleFileUpload} maxCount={1}>
                                            <Button icon={<UploadOutlined />}>Subir archivo</Button>
                                          </Upload>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Cotización de EP&0" span={3}>
                                          <Upload beforeUpload={handleEpnoFileUpload} maxCount={1}>
                                            <Button icon={<UploadOutlined />}>Subir archivo</Button>
                                          </Upload>
                                        </Descriptions.Item>
                                      </Descriptions>
                                      <Row justify="center" style={{ marginTop: "12px" }}>
                                      <Button 
                                        type="primary" 
                                        disabled={loadingQuote || !newQuote.supplier_name || !newQuote.title || !newQuote.description || !newQuote.price || !newQuote.due_date || !newQuote.proposal_file || !newQuote.epno_file || !newQuote.epno_price}
                                        onClick={() => handleSubmit(sub.serviceId)}
                                        loading={loadingQuote} // ⏳ Muestra el loader mientras se envía
                                      >
                                        {loadingQuote ? "Enviando..." : "Guardar cotización"}
                                      </Button>
                                      </Row>
                                    </Card>
                                  </Col>
                                </Row>
                              </Panel>
                            </Collapse>
                            }
                            {role === 6 && (
                              <Collapse bordered={false} className="background-gris" expandIcon={({ isActive }) => <PlusOutlined rotate={isActive ? 45 : 0} />}>
                                {filteredServices.map((subs) => {
                                  // Comprobamos si el proveedor ya cotizó este servicio específico
                                  const isServiceQuoted = proposals[subs.serviceId] && proposals[subs.serviceId].some(quote => quote.supplier_id === userData.id);

                                  // Si no ha sido cotizado, mostramos el formulario
                                  if (!isServiceQuoted && sub.serviceId == subs.serviceId) {
                                    return (
                                      <Panel
                                        header={
                                          <Row gutter={[6, 12]}>
                                            <Col xs={24} style={{ display: 'flex', alignItems: 'center' }}>
                                              <b>Cotizar servicio {sub.serviceId}</b>
                                            </Col>
                                          </Row>
                                        }
                                        key={`add-quote-panel-${sub.serviceId}`}
                                      >
                                        <Row gutter={[12, 12]} justify="center">
                                          <Col xs={24}>
                                            <Card>
                                              <Descriptions bordered size="small" column={{ xl: 3, lg: 3, md: 3, sm: 1, xs: 1 }}>
                                                <Descriptions.Item label="Título" span={3}>
                                                  <Input name="title" value={newSupplierQuote.title} onChange={handleInputSupplierChange} />
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Descripción" span={3}>
                                                  <Input.TextArea name="description" value={newSupplierQuote.description} onChange={handleInputSupplierChange} />
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Precio" span={3}>
                                                  <Input type="number" name="price" value={newSupplierQuote.price} onChange={handleInputSupplierChange} />
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Fecha de entrega" span={3}>
                                                  <DatePicker style={{ width: "100%" }} onChange={handleDateSupplierChange} />
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Cotización" span={3}>
                                                  <Upload beforeUpload={handleSupplierFileUpload} maxCount={1}>
                                                    <Button icon={<UploadOutlined />}>Subir archivo</Button>
                                                  </Upload>
                                                </Descriptions.Item>
                                              </Descriptions>
                                              <Row justify="center" style={{ marginTop: "12px" }}>
                                                <Button
                                                  type="primary"
                                                  disabled={
                                                    loadingQuote ||
                                                    !newSupplierQuote.supplier_name ||
                                                    !newSupplierQuote.title ||
                                                    !newSupplierQuote.description ||
                                                    !newSupplierQuote.price ||
                                                    !newSupplierQuote.due_date ||
                                                    !newSupplierQuote.proposal_file
                                                  }
                                                  onClick={() => handleSupplierSubmit(sub.serviceId)}
                                                  loading={loadingQuote}
                                                >
                                                  {loadingQuote ? "Enviando..." : "Guardar cotización"}
                                                </Button>
                                              </Row>
                                            </Card>
                                          </Col>
                                        </Row>
                                      </Panel>
                                    );
                                  }
                                  return null; // Si ya fue cotizado, no mostramos nada
                                })}
                              </Collapse>
                            )}

                          </Card>
                        </Col>  
                      </Row>
                    </Panel>
                  </Collapse>
                  ))}
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Col>
      
      {/* MODAL ---------------------------------------------*/}
      <Modal
        title="Cancelar orden"
        open={showCancelModal}
        onOk={() => {
          if (!cancelReason.trim()) {
            message.warning("Por favor escribe un motivo.");
            return;
          }
          rejectOrder(order.orderId, { status: 8, rejectData: cancelReason });
          setShowCancelModal(false);
          setCancelReason("");
        }}
        onCancel={() => {
          setShowCancelModal(false);
          setCancelReason("");
        }}
        okText="Confirmar cancelación"
        cancelText="Cerrar"
      >
        <p>Por favor indica el motivo de la cancelación:</p>
        <Input.TextArea
          rows={4}
          value={cancelReason}
          onChange={(e) => setCancelReason(e.target.value)}
          placeholder="Motivo de la cancelación..."
        />
      </Modal>
    </Row>

    
  );
  
}
