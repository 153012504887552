import React, { useEffect, useState } from "react";
import { List, Button, Card, Tag, Row, Col, message, Input } from "antd";
import { AppstoreOutlined, BarsOutlined, SearchOutlined } from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import MarketPlaceService from "../../../utils/api/marketplace";
import '@ant-design/v5-patch-for-react-19';

const { Search } = Input;

const ProductList = ({ catalog, category }) => {
  const user = JSON.parse(localStorage.getItem('user'));
  let role = localStorage.getItem("role");


  let history = useNavigate();
  const [gridColumns, setGridColumns] = useState(1);
  const [showArchived, setShowArchived] = useState(false);
  const [loadProducts, setLoadProducts] = useState(true);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    setLoadProducts(true);
    MarketPlaceService.getProductsByCategory(category, catalog).then((resp) => {
      setProducts(resp.data.products);
      setFilteredProducts(resp.data.products.filter(product => !product.archive));
      setLoadProducts(false);
    }).catch(() => {
      message.error("Error al cargar productos");
      setLoadProducts(false);
    });
  }, [category, catalog]);

  const handleCardClick = (product) => {
    history(`/marketplace/${product.category}/${catalog}/${product.id}?name=${encodeURIComponent(product.name)}`);
  };

  const handleSearch = (value) => {
    const filtered = products.filter((sup) =>
      sup.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredProducts(filtered);
  };

  const toggleArchived = () => {
    if (showArchived) {
      setFilteredProducts(products.filter(product => !product.archive));
    } else {
      setFilteredProducts(products.filter(product => product.archive));
    }
    setShowArchived(!showArchived);
  };

  return (
    <>
      <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
        <div style={{ flex: 3 }}>
          <Row>
            <Col xs={12} md={12}>
              <Search
                placeholder="Buscar por nombre..."
                enterButton={<SearchOutlined />}
                onSearch={handleSearch}
                style={{ marginBottom: "20px" }}
              />
            </Col>
            <Col xs={12} md={12} style={{ display: "flex" }}>
              <Button
                onClick={() => setGridColumns(1)}
                icon={<BarsOutlined />}
                className="responsive-button"
                style={{ marginRight: "10px", marginLeft: "10px" }}
              >
                <span className="button-text">Lista</span>
              </Button>
              <Button
                onClick={() => setGridColumns(2)}
                icon={<AppstoreOutlined />}
                className="responsive-button"
              >
                <span className="button-text">Galería</span>
              </Button>
              {role != 4 ? (
                <Button
                  onClick={toggleArchived}
                  style={{ marginLeft: "10px", width: "400px" }}
                >
                  {showArchived ? "Mostrar No Archivados" : "Mostrar Archivados"}
                </Button>
              ) : (
                <></>
              )}
              <style jsx>{`
                @media (max-width: 768px) {
                  .responsive-button .button-text {
                    display: none;
                  }
                }
              `}</style>
            </Col>
          </Row>

          <List
            grid={{ gutter: 12, column: gridColumns }}
            dataSource={filteredProducts}
            loading={loadProducts}
            renderItem={(item) => (
              <List.Item style={{ width: "100%" }}>
                <Card
                  bordered={true}
                  style={{
                    display: "block",
                    width: "100%",
                    cursor: "pointer",
                    transition: "0.3s",
                    hover: { boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)" },
                  }}
                  onClick={() => handleCardClick(item)}
                >
                  <Row gutter={[24, 24]} style={{ width: "100%" }}>
                    <Col xs={24} md={6} style={{ textAlign: "left" }}>
                      <img
                        src={item.images[0]}
                        alt={item.name}
                        style={{ width: 100, height: 100 }}
                      />
                      {gridColumns === 1 ? null : (
                        <>
                          <Tag color="blue">{item.category}</Tag>
                          <Tag color="green">{item.sub_category}</Tag>
                        </>
                      )}
                    </Col>
                    <Col xs={24} md={12} style={{ textAlign: "left" }}>
                      <div>
                        <h3>{item.name}</h3>
                        <p>{item.description}</p>
                        {gridColumns === 2 ? null : (
                          <>
                            <Tag color="blue">{item.category}</Tag>
                            <Tag color="green">{item.sub_category}</Tag>
                          </>
                        )}
                      </div>
                    </Col>
                    <Col xs={24} md={6} style={{ textAlign: "right" }}>
                      <div>
                        <h2 style={{ marginBottom: 8 }}>
                          ${item.newPrice ?? item.price}
                        </h2>
                        <p style={{ color: "green" }}>Sin costo de envío</p>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </List.Item>
            )}
          />
        </div>
      </div>
    </>
  );
};

export default ProductList;
