import React, { useState, useEffect } from 'react';
import { Card, Col, Empty, Row, Tag, Timeline, Select, message } from 'antd';
import { CheckCircleOutlined, SyncOutlined, CloseCircleOutlined, CarOutlined, ClockCircleOutlined, MinusCircleOutlined, FileOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

const { Option } = Select;

export default function StepIdsLogs(props) {
  const { steps, order } = props;
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [selectedServiceLogs, setSelectedServiceLogs] = useState([]);

  useEffect(() => {
    if (selectedServiceId) {
      const service = order.services.find(s => s.serviceId === selectedServiceId);
      setSelectedServiceLogs(service ? service.serviceLogs : []);
    }
  }, [selectedServiceId, order]);

  const getStatusLabelAndIcon = (status) => {
    let statusLabel;
    let iconStatus;
    
    // Define status labels and icons based on the status value
    switch (status) {
      case 1:
        statusLabel = <Tag color='purple'>Revisión</Tag>;
        iconStatus = <FileOutlined style={{ color: "purple" }} />;
        break;
      case 2:
        statusLabel = <Tag color='gold'>Cotización</Tag>;
        iconStatus = <CheckCircleOutlined style={{ color: 'gold' }} />;
        break;
      case 3:
        statusLabel = <Tag color="default">Pendiente aprobar</Tag>;
        iconStatus = <ClockCircleOutlined style={{ color: "gray" }} />;
        break;
      case 4:
        statusLabel = <Tag color="processing">Construcción</Tag>;
        iconStatus = <SyncOutlined spin color='processing' />;
        break;
      case 5:
        statusLabel = <Tag color='cyan'>Inspección</Tag>;
        iconStatus = <MinusCircleOutlined style={{ color: 'cyan' }} />;
        break;
      case 6:
        statusLabel = <Tag color='lime'>En camino</Tag>;
        iconStatus = <CarOutlined style={{ color: 'geekblue' }} />;
        break;
      case 7:
        statusLabel = <Tag color='success'>Entregado</Tag>;
        iconStatus = <CheckCircleOutlined style={{ color: 'green' }} />;
        break;
      case 8:
        statusLabel = <Tag color='warning'>Rechazado</Tag>;
        iconStatus = <ExclamationCircleOutlined style={{ color: 'orange' }} />;
        break;
      case 9:
        statusLabel = <Tag color='#ff7875'>Cancelado</Tag>;
        iconStatus = <CloseCircleOutlined style={{ color: '#ff7875' }} />;
        break;
      case 11:
        statusLabel = <Tag color='#000'>Cancelación solicitada</Tag>;
        iconStatus = <ExclamationCircleOutlined style={{ color: '#000' }} />;
        break;
      case 12:
        statusLabel = <Tag color='#f5222d'>Queja</Tag>;
        iconStatus = <ExclamationCircleOutlined style={{ color: '#f5222d' }} />;
        break;
      default:
        statusLabel = <Tag color="gray">Desconocido</Tag>;
        iconStatus = <ExclamationCircleOutlined />;
        break;
    }

    return { statusLabel, iconStatus };
  };

  const handleServiceSelect = (value) => {
    setSelectedServiceId(value);
  };

  const sortedLogs = selectedServiceLogs.sort((a, b) => moment(a.updatedAt).isBefore(b.updatedAt) ? -1 : 1);

  return (
    <Card title="Seguimiento de la orden"
      headStyle={{
        textAlign: "center",
        background: "#e8ebef",
        borderTopLeftRadius: '5px', borderTopRightRadius: '5px'
      }}
    >
      <Row gutter={[12, 12]} style={{ marginBottom: '16px' }}>
        <Col xs={24}>
          {/* Dropdown to select a service */}
          <Select
            placeholder="Selecciona un servicio"
            style={{ width: '100%' }}
            onChange={handleServiceSelect}
            value={selectedServiceId}
          >
            {order.services.map(service => (
              <Option key={service.serviceId} value={service.serviceId}>
                {service.serviceId}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>

      {selectedServiceId && (
        <Row gutter={[12, 12]} style={{ marginBottom: '16px' }}>
          <Col xs={24}>
            
              {sortedLogs.length === 0 ? (
                <Empty description="No hay registros disponibles para este servicio" />
              ) : (
                <Timeline mode="alternate">
                {
                  sortedLogs.map((log, index) => {
                    const { statusLabel, iconStatus } = getStatusLabelAndIcon(log.status);
  
                    return (
                      
                      <Timeline.Item
                        dot={iconStatus}
                        label={moment(log.updatedAt).format("DD/MM/YYYY H:mm")}
                        key={index}
                      >
                        <Row gutter={[12, 2]}>
                          <Col xs={24}>
                            {statusLabel}
                          </Col>
                        </Row>
                      </Timeline.Item>
                      
                    );
                  })
                }
                </Timeline>
              )}
            
          </Col>
        </Row>
      )}

    </Card>
  );
}
