import React, { useState, useEffect } from "react";
import {Row, Col} from "antd";

import GeneralTable from "../components/Generals/OrdersTable/GeneralTable";
import AgentTable from "../components/Generals/OrdersTable/AgentTable";
import LayoutPage from "../layouts/LayoutPage";
import SupplierLayout from "../layouts/SupplierLayout";
import CPLayout from "../layouts/ControlPanelLayout";
import OrderService from "../utils/api/orders";

export default function SeguimientoOrdenes(props) {
  let role = localStorage.getItem("role");
  var Layout = "";
  const [openOrders, setOpenOrders] = useState([]);
  const [loading, setLoading] = useState(false);

	const user = JSON.parse(localStorage.getItem('user'))

  if (role == 4) {
    Layout = LayoutPage;
  } else if (role == 6) {
    Layout = SupplierLayout;
  } else if (
    role == 1 ||
    role == 3 ||
    role == 5 ||
    role == 2 ||
    role == 10
  ) {
    Layout = CPLayout;
  }


  useEffect(() => {
    setLoading(true)
    
    if (role === '1') { // Admin
      OrderService.GetAll().then(response => {
        console.log(response)
        setOpenOrders(response.data.orders);
        setLoading(false);
      }).catch((error) => { setLoading(false); });
    }

    if (role === '3') { // Compras
      OrderService.GetAll().then(response => {
        const filteredOrders = response.data.orders.filter(order => 
            order.services.some(service => service.cat === parseInt(user.vs) && service.status >= 2 )
        );

        setOpenOrders(filteredOrders);
        setLoading(false);
      }).catch((error) => { 
          console.error("Error obteniendo órdenes:", error);
          setLoading(false);
      });
    }

    if (role === '4') { // Client
      Promise.all([
          OrderService.GetOrdersByUser(user.id)
      ])
      .then(([ordersResponse]) => {
          // Combina las listas de ambas respuestas
          const combinedOrders = [...ordersResponse.data.orders];
          
          setOpenOrders(combinedOrders);
          setLoading(false);
      })
      .catch((error) => {
          setLoading(false);
      });
    }

    if (role === '5') { // Ventas
      OrderService.GetAll().then(response => {
          const filteredOrders = response.data.orders.filter(order => 
              order.services.some(service =>  service.cat === parseInt(user.vs) )
          );
  
          setOpenOrders(filteredOrders);
          setLoading(false);
      }).catch((error) => { 
          console.error("Error obteniendo órdenes:", error);
          setLoading(false);
      });
  }

    if (role === '6') { // Supplier
    OrderService.GetAll().then(response => {
        const filteredOrders = response.data.orders.filter(order => 
            order.services.some(service => 
                service.cat === parseInt(user.role_data.cat1) && 
                (service.status === 2 || (service.status >= 3 && service.supplier_id === user.id))
            )
        );

        setOpenOrders(filteredOrders);
        setLoading(false);
    }).catch((error) => { 
        console.error("Error obteniendo órdenes:", error);
        setLoading(false);
    });
  }
    
  }, []);

  return (
    <Layout>
      <Row gutter={24}>
        <Col xs={24}>
          {role == 6 || role == 4 ? (
            <GeneralTable openOrders={openOrders} loading={loading} />
          ) : (
            (role == 1 ||
              role == 5 ||
              role == 3 ||
              role == 2 ||
              role == 10) && (
              <AgentTable openOrders={openOrders} loading={loading} />
            )
          )}
        </Col>
      </Row>
    </Layout>
  );
}
