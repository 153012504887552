import request from "../services/HTTPClient";
import { requestMethods, API } from "../constants";

function requestPayment(data) {
  return request({
    url: API.CONEKTA.REQUEST_PAYMENT,
    method: requestMethods.POST,
    data: data,
  });
}

const ConektaService = {
  requestPayment,
};

export default ConektaService;
