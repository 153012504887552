import React, { useEffect, useState } from "react";
import {
  List,
  Button,
  Card,
  Tag,
  Row,
  Col,
  Space,
  Input,
  Modal,
  Select,
  Tooltip,
  message,
  Spin,
} from "antd";
import {
  SearchOutlined,
  DeleteOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import '@ant-design/v5-patch-for-react-19';
import { useNavigate } from "react-router-dom";
import MarketPlaceService from "../../../utils/api/marketplace";
import UserService from "../../../utils/api/users";

import { CategoriesData } from "../../../data/categories/categoriesData";

const { Search } = Input;
const { Option } = Select;

const SupplierList = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  let history = useNavigate();

  const handleCardClick = (sup) => {
    history(
      `/marketplace/suppliers/${sup.vendor.user_id}?name=${encodeURIComponent(
        sup.name
      )}`
    );
  };

  const [initialized, setInitialized] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [filteredVendors, setFilteredVendors] = useState(vendors);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [selectedSupplier, setSelectedSupplier] = useState(null);

  // Loaders
  const [loadSuppliers, setLoadSuppliers] = useState(false);
  const [loadingAssign, setLoadingAssign] = useState(false);
  const [loadingArchive, setLoadingArchive] = useState(false);
  const [loadingUnarchive, setLoadingUnarchive] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  // Filtrar proveedores activos e inactivos
  const [showInactive, setShowInactive] = useState(false);
  const activeVendors = vendors.filter((vendor) => vendor.vendor.active);
  const inactiveVendors = vendors.filter((vendor) => !vendor.vendor.active);


  // On init api calls ----------------------------------------
  useEffect(() => {
    
    setLoadSuppliers(true);
    if (!initialized) {
      UserService.GetAllSuppliers().then((resp) => {
        setSuppliers(resp.data);
      }).catch(e => {
        
      });

      MarketPlaceService.getAllVendors()
        .then((resp) => {
          setVendors(resp.data);
          setFilteredVendors(resp.data);
          setLoadSuppliers(false);
        })
        .catch((e) => {
          setVendors(vendors);
          setFilteredVendors(vendors);
          setLoadSuppliers(false);
        });
    } else {
      setVendors(vendors);
      setFilteredVendors(vendors);
      setLoadSuppliers(false);
    }
  }, [initialized]);

  // Modal functions -------------------------------
  /** Saves the selected category and shows only the available subcategories */
  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
    const category = CategoriesData.find((cat) => cat.category === value);
    if (category) {
      const usedSubcategories = activeVendors
        .filter((vendor) => vendor.vendor.category === value)
        .map((vendor) => vendor.vendor.sub_category);
      const availableSubcategories = category.subCategories.filter(
        (sub) => !usedSubcategories.includes(sub.name)
      );
      setSubcategories(availableSubcategories);
      setSelectedSubcategory(
        availableSubcategories.length > 0
          ? availableSubcategories[0].name
          : null
      );
    } else {
      setSubcategories([]);
      setSelectedSubcategory(null);
    }
  };

  /** Saves the selected sub category */
  const handleSubcategoryChange = (value) => {
    setSelectedSubcategory(value);
  };

  /** Saves the selected supplier */
  const handleSupplierChange = (value) => {
    const supplier = suppliers.find((sup) => sup.bussiness === value);
    setSelectedSupplier(supplier);
  };

  /** Adds new vendor to the firebase storage */
  const handleAssign = () => {
    setLoadingAssign(true);
    const newVendor = {
      admin_id: user.id,
      supplier_id: selectedSupplier?.id,
      category: selectedCategory,
      sub_category: selectedSubcategory,
    };
    MarketPlaceService.addVendor(newVendor)
      .then(() => {
        message.success("Proveedor asignado exitosamente.");
        window.location.reload();
      })
      .catch(() => message.error("Error al asignar proveedor."))
      .finally(() => setLoadingAssign(false));
    setIsModalOpen(false);
  };

  /** Opens assign new vendor modal */
  const showModal = () => {
    setIsModalOpen(true);
  };

  /** Closes, cancels and cleans the modals */
  const handleCancel = () => {
    setSelectedCategory(null);
    setSelectedSubcategory(null);
    setSelectedSupplier(null);
    setIsModalOpen(false);
  };

  // Page functions --------------------------------------------------

  /**  Función para obtener proveedores nuevamente después de cambios */
  const fetchVendors = () => {
    setLoadSuppliers(true);
    MarketPlaceService.getAllVendors()
      .then((resp) => {
        setVendors(resp.data);
        setFilteredVendors(resp.data);
        //message.success("Proveedores actualizados correctamente.");
      })
      .catch(() => {
        message.error("Error al obtener la lista de proveedores.");
      })
      .finally(() => setLoadSuppliers(false));
  };

  /** Handles search of the vendors */
  const handleSearch = (value) => {
    const filtered = vendors.filter((sup) =>
      sup.bussiness.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredVendors(filtered);
  };

  /** Desarchiva a un vendedor y sus productos */
  const handleUnarchiveVendor = (id) => {
    setLoadingUnarchive(true);
    MarketPlaceService.unarchiveVendor(id)
      .then(() => {
        message.success("Proveedor desarchivado correctamente.");
        fetchVendors();
      })
      .catch(() => message.error("Error al desarchivar proveedor."))
      .finally(() => setLoadingUnarchive(false));
  };

  /** Archiva a un vendedor y a sus productos */
  const handleArchiveVendor = (id) => {
    setLoadingArchive(true);
    MarketPlaceService.archiveVendor(id)
      .then(() => {
        message.success("Proveedor archivado correctamente.");
        fetchVendors();
      })
      .catch(() => message.error("Error al archivar proveedor."))
      .finally(() => setLoadingArchive(false));
  };

  /** Borra a un vendedor y a sus productos */
  const handleDeleteVendor = (id) => {
    setLoadingDelete(true);
    MarketPlaceService.deleteVendor(id)
      .then(() => {
        message.success("Proveedor eliminado correctamente.");
        fetchVendors();
      })
      .catch(() => message.error("Error al eliminar proveedor."))
      .finally(() => setLoadingDelete(false));
  };


  return (
    <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
      <div style={{ flex: 3 }}>
        <Row>
          <Col xs={24} md={6}>
            <Search
              placeholder="Buscar por nombre..."
              enterButton={<SearchOutlined />}
              onSearch={handleSearch}
              style={{ marginBottom: "20px" }}
            />
          </Col>
          <Button type="primary" onClick={showModal} loading={loadingAssign}>
            {loadingAssign ? <Spin size="small" /> : "Asignar nuevo vendedor"}
          </Button>
          <Button
            style={{ marginLeft: "10px", width: '210px' }}
            onClick={() => setShowInactive(!showInactive)}
          >
            {showInactive ? "Mostrar activos" : "Mostrar inactivos"}
          </Button>
        </Row>
        {loadSuppliers ? (
          <Spin size="large" />
        ) : (
          <List
            grid={{ gutter: 12, column: 1 }}
            dataSource={showInactive ? inactiveVendors : activeVendors}
            loading={loadSuppliers}
            renderItem={(sup) => (
              <List.Item style={{ width: "100%" }} key={sup.vendor.user_id}>
                <Card
                  bordered={true}
                  style={{
                    display: "block",
                    width: "100%",
                    cursor: "pointer",
                    transition: "0.3s",
                    hover: { boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)" },
                  }}
                >
                  <Row gutter={[24, 24]} style={{ width: "100%" }}>
                    <Col xs={24} md={6} style={{ textAlign: "left" }}>
                      <img
                        onClick={() => handleCardClick(sup)}
                        src={
                          sup.logo.startsWith("http") 
                            ? sup.logo
                            :sup.logo
                              ? sup.logo.startsWith("data:image/")
                                ? sup.logo
                                : `data:image/png;base64,${sup.logo}`
                              : `https://placehold.co/400x400?text=No+Logo`
                        }
                        alt={sup.logo ? "Supplier Logo" : "No Logo"}
                        style={{ width: 100, height: 100 }}
                      />
                    </Col>
                    <Col xs={24} md={12} style={{ textAlign: "left" }}>
                      <div>
                        <h3>{sup.bussiness}</h3>
                        <p>
                          {sup.city}, {sup.state}, {sup.country}
                        </p>
                        <p>
                          {sup.colony}, {sup.street}, {sup.ext_num}
                        </p>
                      </div>
                    </Col>
                    <Col xs={24} md={6} style={{ textAlign: "right" }}>
                      <Space direction="vertical" size="small">
                        <Tag color="blue">{sup.vendor.category}</Tag>
                        <Tag color="green">{sup.vendor.sub_category}</Tag>
                        <Space>
                          <Tooltip
                            title={showInactive ? "Desarchivar" : "Archivar"}
                          >
                            <Button
                              shape="circle"
                              style={{
                                backgroundColor: showInactive
                                  ? "#28a745"
                                  : "#d2b623",
                                border: "none",
                              }}
                              icon={
                                <InboxOutlined style={{ color: "white" }} />
                              }
                              onClick={() =>
                                showInactive
                                  ? handleUnarchiveVendor(sup.vendor_id)
                                  : handleArchiveVendor(sup.vendor_id)
                              }
                              loading={
                                showInactive ? loadingUnarchive : loadingArchive
                              }
                            />
                          </Tooltip>

                          <Tooltip title="Borrar">
                            <Button
                              shape="circle"
                              style={{
                                backgroundColor: "#dc4545",
                                border: "none",
                              }}
                              icon={
                                <DeleteOutlined style={{ color: "white" }} />
                              }
                              onClick={() => handleDeleteVendor(sup.vendor_id)}
                              loading={loadingDelete}
                            />
                          </Tooltip>
                        </Space>
                      </Space>
                    </Col>
                  </Row>
                </Card>
              </List.Item>
            )}
          />
        )}
      </div>

      <Modal
        title="Nuevo vendedor"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button
            key="assign"
            type="primary"
            style={{ backgroundColor: "green" }}
            onClick={handleAssign}
            disabled={
              !selectedCategory || !selectedSubcategory || !selectedSupplier
            }
          >
            Asignar
          </Button>,
        ]}
      >
        <Select
          placeholder="Selecciona una categoría"
          style={{ width: "100%", marginBottom: "20px" }}
          onChange={handleCategoryChange}
          value={selectedCategory}
        >
          {CategoriesData.map((cat) => (
            <Option key={cat.key} value={cat.category}>
              {cat.category}
            </Option>
          ))}
        </Select>

        <Select
          placeholder="Selecciona una subcategoría"
          style={{ width: "100%", marginBottom: "20px" }}
          onChange={handleSubcategoryChange}
          value={selectedSubcategory}
          disabled={!selectedCategory}
        >
          {subcategories.map((sub) => (
            <Option key={sub.name} value={sub.name}>
              {sub.name}
            </Option>
          ))}
        </Select>

        <Select
          placeholder="Selecciona un proveedor"
          style={{ width: "100%", marginBottom: "20px" }}
          onChange={handleSupplierChange}
        >
          {suppliers.map((sup) => (
            <Option key={sup.id} value={sup.bussiness}>
              {sup.bussiness}
            </Option>
          ))}
        </Select>
      </Modal>
    </div>
  );
};

export default SupplierList;
