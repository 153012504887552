import React from 'react';
import { Table } from 'antd';
import { Link } from 'react-router-dom';

export default function OrderByEmployee(props) {
  const { ordenesPro, type } = props;
  const user = JSON.parse(localStorage.getItem('user'))


  const columns = [
    {
      title: 'Número de orden',
      dataIndex: 'name',
      sorter: (a, b) => a.name.length - b.name.length,
      sortDirections: ['descend', 'ascend'],
      responsive: ['sm'],

    },
    {
      title: 'Cliente',
      dataIndex: 'client',
      responsive: ['md'],
    },
    {
      title: 'Precio',
      dataIndex: 'price',
      responsive: ['md'],
    },
    {
      title: 'Detalles',
      dataIndex: 'details',
      // render: () => <Link to={`/orders/${role}`}>Ver más</Link>,
    },
  ];

  const data = [];

  ordenesPro.map((ord) => {
    data.push(
      {
        key: ord.id,
        name: ord.orderId,
        client: ord.client_name,
        price: user.role === 6 ? 
          ord.supplier_price  ? ord.supplier_price : 'Sin definir'
        : ord.epno_price  ? ord.epno_price : 'Sin definir',
        details: (

          type == 2 ? (
            <Link to={`/orders/details/new/${ord.orderId}`}>Ver más</Link>
          ) : type == 1 && (<Link to={`/orders/details/new/${ord.orderId}`}>Ver más</Link>)

        )
      }
    )
  })


  function onChange(pagination, filters, sorter, extra) {
    console.log('params', pagination, filters, sorter, extra);
  }


  return (
    <Table columns={columns} dataSource={data} onChange={onChange} pagination={{pageSize: 5}} />
  );
}
