import React, { useEffect, useState } from 'react';
import { Row, Col, Collapse, Typography } from 'antd';
import { RightOutlined } from "@ant-design/icons";
import LayoutPage from "../layouts/LayoutPage";
import SupplierLayout from "../layouts/SupplierLayout";
import CPLayout from "../layouts/ControlPanelLayout";
import ProfileInfo from "../components/Generals/ProfileInfo";
import OrderByEmployee from "../components/Clients/OrderByEmployee";
import { useParams } from 'react-router-dom';
import UserService from '../utils/api/users';
import OrderService from '../utils/api/orders';

const { Title } = Typography;
const { Panel } = Collapse;

export default function Profile(props) {
  let {id} = useParams();
  const [locations, setLocations] = useState([]);
  const [openOrders, setOpenOrders] = useState([]);
  const [loading, setLoading] = useState(false)
	const user = JSON.parse(localStorage.getItem('user'))

  let role = localStorage.getItem('role');
  var Layout = '';
  const panelStyle = { backgroundColor: "#001529", color: "white" };
  const panelHeader = (title) => (<Row><Col span={24}><Title level={4} style={{ color: "white" }}>{title}</Title></Col></Row>);

  if (role == '4') {
    Layout = LayoutPage;
  } else if (role == '6') {
    Layout = SupplierLayout;
  } else {
    Layout = CPLayout;
  }

  useEffect(() => {
    UserService.GetUserById({id: id}).then(resp => {
      const userProfile = resp.data
      
      if (role === '1') { // Admin
        OrderService.GetAll().then(response => {
          console.log(response)
          setOpenOrders(response.data.orders);
          setLoading(false);
        }).catch((error) => { setLoading(false); });
      }
  
      if (role === '3') { // Compras
        OrderService.GetByCategory(user.vs).then(response => {
          const orders = response.data.orders.filter(ser => ser.status >= 2)
          setOpenOrders(orders);
          setLoading(false);
        }).catch((error) => { setLoading(false); });
      }
  
      if (role === '4') { // Client
        Promise.all([
            OrderService.GetOrdersByUser(user.id)
        ])
        .then(([ordersResponse]) => {
            // Combina las listas de ambas respuestas
            const combinedOrders = [...ordersResponse.data.orders];
            
            setOpenOrders(combinedOrders);
            setLoading(false);
        })
        .catch((error) => {
            setLoading(false);
        });
      }
  
      if (role === '5') { // Ventas
        console.log(user.vs)
        OrderService.GetByCategory(user.vs).then(response => {
          setOpenOrders(response.data.orders);
          setLoading(false);
        }).catch((error) => { setLoading(false); });
      }
  
      if (role === '6') { // Supplier
        console.log(parseInt(user.role_data.cat1))
        OrderService.GetByCategory(parseInt(user.role_data.cat1)).then(response => {
          console.log(response)
          const serviceUser1 = response.data.orders.filter(ser => ser.status == 2)
          const serviceUser2 = response.data.orders.filter(ser => ser.status >= 3 && ser.supplier_id == user.id)
          const combinedServiceUsers = [...serviceUser1, ...serviceUser2];
          setOpenOrders(combinedServiceUsers);
          setLoading(false);
        }).catch((error) => { setLoading(false);});
      }

    })
    
  }, []);

  return (
    <Layout>
      <Row gutter={[16, 24]}>
        <Col span={24}>
          <ProfileInfo locations={locations} id={id} />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Collapse accordion defaultActiveKey={1} expandIcon={({ isActive }) => <RightOutlined style={{ color: "white" }} rotate={isActive ? 90 : 0} />}>
          {/* <Panel header={panelHeader("Grafica de Ahorros")} key={1} style={{ ...panelStyle }}>
                <Tabs defaultActiveKey={1} tabPosition='top'>
                    <TabPane tab="Ahorro" key={1}>
                        <SavingsChart 
                          ahorroMes = {gastosMes}
                        />
                    </TabPane>
                    <TabPane tab="Gastos" key={2}>
                        <SpentChart
                          gastosMes = {gastosMes}
                        />
                    </TabPane>
                    <TabPane tab="Contraste" key={3}>
                        <ComparisonCharts 
                          contrasteMes = {gastosMes}
                        />
                    </TabPane>
                    <TabPane tab="Tipo" key={4}>
                    </TabPane>
                </Tabs>
            </Panel> */}
            {/* Holis */}
            <Panel header={panelHeader("Ordenes")} style={{ ...panelStyle }} key={2}>
              <OrderByEmployee ordenesPro={openOrders} role={role} type={2} />
            </Panel>
            {/* 
            <Panel header={panelHeader("Reviews")} style={{ ...panelStyle }} key={3}>
              <Card bordered={false}
                bodyStyle={{
                  maxHeight: 350,
                  overflow: "auto"
                }}
              >
                {
                  !isEmpty(reviewsMro) ? (
                    reviewsMro.slice(0, 5).map((rev, index) => (
                      // review.map((rev,index) => (
                      <Link to={`/orders/details/${role}/${rev.id}/${rev.request_type_id}`}>
                        <Review title={rev.purchase_order} valor={rev.rate} description={rev.comment} key={index} />
                      </Link>
                      // ))
                    ))

                  ) : (
                    <Empty />
                  )
                }
              </Card>
            </Panel>
            */}
          </Collapse>
        </Col>
      </Row>
      {/* <Divider orientation='left'>Partnos mas comprados</Divider> */}
    </Layout>

  );
}

